export const picRoleData = [
  { key: "0", value: "代表取締役社長" },
  { key: "1", value: "副社長" },
  { key: "2", value: "専務取締役" },
  { key: "3", value: "常務取締役" },
  { key: "4", value: "取締役" },
  { key: "5", value: "本部長（事業部長）" },
  { key: "6", value: "部長" },
  { key: "7", value: "次長" },
  { key: "8", value: "課長" },
  { key: "9", value: "係長" },
  { key: "10", value: "主任" },
  { key: "11", value: "一般社員" },
];

export const prefectureData = [
  { key: "0", value: "北海道" },
  { key: "1", value: "青森県" },
  { key: "2", value: "岩手県" },
  { key: "3", value: "宮城県" },
  { key: "4", value: "秋田県" },
  { key: "5", value: "山形県" },
  { key: "6", value: "福島県" },
  { key: "7", value: "茨城県" },
  { key: "8", value: "栃木県" },
  { key: "9", value: "群馬県" },
  { key: "10", value: "埼玉県" },
  { key: "11", value: "千葉県" },
  { key: "12", value: "東京都" },
  { key: "13", value: "神奈川県" },
  { key: "14", value: "新潟県" },
  { key: "15", value: "富山県" },
  { key: "16", value: "石川県" },
  { key: "17", value: "福井県" },
  { key: "18", value: "山梨県" },
  { key: "19", value: "長野県" },
  { key: "20", value: "岐阜県" },
  { key: "21", value: "静岡県" },
  { key: "22", value: "愛知県" },
  { key: "23", value: "三重県" },
  { key: "24", value: "滋賀県" },
  { key: "25", value: "京都府" },
  { key: "26", value: "大阪府" },
  { key: "27", value: "兵庫県" },
  { key: "28", value: "奈良県" },
  { key: "29", value: "和歌山県" },
  { key: "30", value: "鳥取県" },
  { key: "31", value: "島根県" },
  { key: "32", value: "岡山県" },
  { key: "33", value: "広島県" },
  { key: "34", value: "山口県" },
  { key: "35", value: "徳島県" },
  { key: "36", value: "香川県" },
  { key: "37", value: "愛媛県" },
  { key: "38", value: "高知県" },
  { key: "39", value: "福岡県" },
  { key: "40", value: "佐賀県" },
  { key: "41", value: "長崎県" },
  { key: "42", value: "熊本県" },
  { key: "43", value: "大分県" },
  { key: "44", value: "宮崎県" },
  { key: "45", value: "鹿児島県" },
  { key: "46", value: "沖縄県" },
];

export const listingDivisionData = [
  { key: "0", value: "プライム" },
  { key: "1", value: "スタンダード" },
  { key: "2", value: "グロース" },
  { key: "3", value: "未上場" },
];
export const businessData = [
  { key: "1", value: "IT・通信" },
  { key: "2", value: "ソフトウェア・情報処理" },
  { key: "3", value: "ディベロッパー" },
  { key: "4", value: "機械・電気" },
  { key: "5", value: "コンピュータ・通信機器" },
  { key: "6", value: "電子・半導体" },
  { key: "7", value: "自動車" },
  { key: "8", value: "化学・医薬" },
  { key: "9", value: "オフィス用品・雑貨" },
  { key: "10", value: "小売" },
  { key: "11", value: "建材" },
  { key: "12", value: "運輸" },
  { key: "13", value: "建設・ハウスメーカー" },
  { key: "14", value: "建築・インテリア" },
  { key: "15", value: "石油・ガス" },
  { key: "16", value: "電気・水道" },
  { key: "17", value: "テレビ・ラジオ" },
  { key: "18", value: "出版" },
  { key: "19", value: "広告" },
  { key: "20", value: "商社" },
  { key: "21", value: "銀行" },
  { key: "22", value: "証券" },
  { key: "23", value: "保険" },
  { key: "24", value: "デザイン" },
  { key: "25", value: "宿泊" },
  { key: "26", value: "飲食" },
  { key: "27", value: "美容" },
  { key: "28", value: "観光・旅行" },
  { key: "29", value: "教育" },
  { key: "30", value: "医療・福祉" },
  { key: "31", value: "人材" },
  { key: "32", value: "その他" },
  { key: "33", value: "未選択" },
];

export const workStyleData = [
  { key: "1", value: "給与水準" },
  { key: "2", value: "業界規模" },
  { key: "3", value: "会社規模" },
  { key: "4", value: "会社の安定性" },
  { key: "5", value: "業界シェア" },
  { key: "6", value: "育成環境" },
  { key: "7", value: "市場の成長性" },
  { key: "8", value: "裁量権" },
  { key: "9", value: "ベンチャー" },
  { key: "10", value: "服装自由" },
  { key: "11", value: "専門性" },
  { key: "12", value: "フレックス制度" },
  { key: "13", value: "転勤の少なさ" },
  { key: "14", value: "海外駐在の機会" },
  { key: "15", value: "その他" },
  { key: "16", value: "未選択" },
];

export const jobData = [
  { key: "1", value: "営業" },
  { key: "2", value: "事務" },
  { key: "3", value: "企画" },
  { key: "4", value: "法務" },
  { key: "5", value: "記者" },
  { key: "6", value: "編集・制作" },
  { key: "7", value: "接客" },
  { key: "8", value: "マーケティング" },
  { key: "9", value: "広報" },
  { key: "10", value: "コンサルタント" },
  { key: "11", value: "ディーラー" },
  { key: "12", value: "証券アナリスト" },
  { key: "13", value: "ファンドマネジメント" },
  { key: "14", value: "トレーダー" },
  { key: "15", value: "プログラマー" },
  { key: "16", value: "webエンジニア" },
  { key: "17", value: "ネットワークエンジニア" },
  { key: "18", value: "セキュリティエンジニア" },
  { key: "19", value: "データサイエンティスト" },
  { key: "20", value: "セールスエンジニア" },
  { key: "21", value: "設計・開発" },
  { key: "22", value: "生産・製造" },
  { key: "23", value: "物流" },
  { key: "24", value: "講師" },
  { key: "25", value: "ゲームクリエイター" },
  { key: "26", value: "ゲームプログラマー" },
  { key: "27", value: "農業" },
  { key: "28", value: "林業" },
  { key: "29", value: "漁業" },
  { key: "30", value: "その他" },
  { key: "31", value: "未選択" },
];
export const companySizeData = [
  { key: "1", value: "大手企業" },
  { key: "2", value: "メガベンチャー" },
  { key: "3", value: "中小企業" },
  { key: "4", value: "ベンチャー" },
  { key: "5", value: "スタートアップ" },
  { key: "6", value: "特になし" },
];
