"use client";

import React, { useEffect, useState } from "react";
import { getShopUsageCount } from "../hooks/firebase/context/wasedafes2024";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import StorefrontIcon from "@mui/icons-material/Storefront";

type ShopUsage = {
  shopId: string;
  shopName: string;
  usageCount: number;
};

export default function ShopUsageList() {
  const [shopUsages, setShopUsages] = useState<ShopUsage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchShopUsages = async () => {
      try {
        const data = await getShopUsageCount();
        const sortedData = data.sort((a, b) => b.usageCount - a.usageCount);
        setShopUsages(sortedData);
      } catch (err) {
        setError("データの取得中にエラーが発生しました。");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchShopUsages();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const maxUsage = Math.max(...shopUsages.map((shop) => shop.usageCount));

  return (
    <Box maxWidth="lg" mx="auto" p={4}>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        gutterBottom
        color="primary"
      >
        店舗別クーポン使用回数
      </Typography>
      <Paper elevation={3}>
        {shopUsages.map((shop, index) => (
          <Fade
            in={true}
            key={shop.shopId}
            style={{ transitionDelay: `${index * 50}ms` }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              borderBottom={index !== shopUsages.length - 1 ? 1 : 0}
              borderColor="divider"
            >
              <Box display="flex" alignItems="center">
                <StorefrontIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{shop.shopName}</Typography>
              </Box>
              <Box display="flex" alignItems="center" width="50%">
                <Box width="100%" mr={2}>
                  <LinearProgress
                    variant="determinate"
                    value={(shop.usageCount / maxUsage) * 100}
                    sx={{ height: 10, borderRadius: 5 }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  component="span"
                  fontWeight="bold"
                  minWidth={40}
                >
                  {shop.usageCount}
                </Typography>
                <Typography
                  variant="body2"
                  component="span"
                  color="text.secondary"
                >
                  回
                </Typography>
              </Box>
            </Box>
          </Fade>
        ))}
      </Paper>
      <Fade in={true} style={{ transitionDelay: "500ms" }}>
        <Typography variant="body2" align="right" mt={2} color="text.secondary">
          総店舗数: {shopUsages.length}
        </Typography>
      </Fade>
    </Box>
  );
}
