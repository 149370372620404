import {
  CollectionReference,
  DocumentSnapshot,
  QuerySnapshot,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../main";
import { stdCompMessage } from "types/firebase/chat";

// 使用しない
// //応募してきた学生一覧を表示する関数
// export async function getApplicationStudentsList(uid: string) {
//   const companyRef = doc(db, "compUsersPublic", uid);
//   const applicationRef = collection(db, "applications");
//   const q = query(
//     applicationRef,
//     where("status", "in", ["unread", "read"]),
//     where("company.ref", "==", companyRef),
//     orderBy("createdAt", "desc"),
//     limit(10)
//   );
//   const applicationSnapshot = await getDocs(q);
//   const response: any = [];

//   // 以下取得したデータを整形して返す。
//   const addData = async (data: any) => {
//     const compUser: any = await getDoc(companyRef);
//     const compData: any = compUser.data();
//     compData.senderId = compUser.id;
//     const docData = {
//       companyPhtUrl: data.company.iconPhtUrl,
//       companyName: data.company.name,
//       studentPhtUrl: data.student.iconPhtUrl,
//       studentName: data.student.lastName + data.student.firstName,
//       createdAt: data.createdAt.toDate(),
//       updatedAt: data.updatedAt.toDate(),
//       offerType: data.offerType,
//       jobName: data.jobName,
//       recruitCatchCopy: data.recruitCatchCopy,
//       employmentStatus: data.employmentStatus,
//       jobCategory: data.jobCategory, //職種カテゴリー
//       recruitGraduatedYear: data.recruitGraduatedYear,
//       minSalary: data.minSalary,
//       maxSalary: data.maxSalary,
//       salaryForm: data.salaryForm,
//       salaryDetail: data.salaryDetail,
//       trialPeriod: data.trialPeriod,
//       recruitedNumber: data.recruitedNumber,
//       daysToRecruit: data.daysToRecruit, //採用までの希望日数
//       jobDetail: data.jobDetail,
//       appealPoint: data.appealPoint,
//       neededPersonal: data.neededPersonal,
//       workDay: data.workDay,
//       workPlace: data.workPlace,
//       access: data.access,
//       treatment: data.treatment, //待遇や福利厚生
//       others: data.others,
//       status: data.status,
//     };
//     response.push(docData);
//   };

//   const dataDivisor: any = async (snap: QuerySnapshot) => {
//     for (const doc of snap.docs) {
//       await addData(doc.data());
//     }
//     return [response, snap.docs[9]];
//   };
//   return await dataDivisor(applicationSnapshot);
// }

// // 追加で応募中の学生を取得する関数（現在は使われていない）
// export async function getExtraApplicationStudentsList(
//   uid: string,
//   lastSnap: DocumentSnapshot
// ) {
//   const companyRef = doc(db, "compUsersPublic", uid);
//   const dbRef: CollectionReference = collection(db, "applications");
//   const q = query(
//     dbRef,
//     where("status", "in", ["unread", "read"]),
//     where("company.ref", "==", companyRef),
//     orderBy("createdAt", "desc"),
//     orderBy("updatedAt", "desc"),
//     startAfter(lastSnap.data()?.updatedAt),
//     limit(10)
//   );
//   const querySnapshot = await getDocs(q);
//   const response: any = [];
//   const addData = async (data: any) => {
//     const docData = {
//       companyIconPhtUrl: data.company.iconPhtUrl,
//       companyCoverPhtUrl: data.company.coverPhtUrl,
//       companyName: data.company.name,
//       studentPhtUrl: data.student.iconPhtUrl,
//       studentName: data.student.lastName + data.student.firstName,
//       createdAt: data.createdAt.toDate(),
//       updatedAt: data.updatedAt.toDate(),
//       offerType: data.offerType,
//       jobName: data.jobName,
//       recruitCatchCopy: data.recruitCatchCopy,
//       employmentStatus: data.employmentStatus,
//       jobCategory: data.jobCategory, //職種カテゴリー
//       recruitGraduatedYear: data.recruitGraduatedYear,
//       minSalary: data.minSalary,
//       maxSalary: data.maxSalary,
//       salaryForm: data.salaryForm,
//       salaryDetail: data.salaryDetail,
//       trialPeriod: data.trialPeriod,
//       recruitedNumber: data.recruitedNumber,
//       daysToRecruit: data.daysToRecruit, //採用までの希望日数
//       jobDetail: data.jobDetail,
//       appealPoint: data.appealPoint,
//       neededPersonal: data.neededPersonal,
//       workDay: data.workDay,
//       workPlace: data.workPlace,
//       access: data.access,
//       treatment: data.treatment, //待遇や福利厚生
//       others: data.others,
//       status: data.status,
//     };
//     response.push(docData);
//   };
//   const dataDivisor: any = async (snap: QuerySnapshot) => {
//     for (const doc of snap.docs) {
//       await addData(doc.data());
//     }
//     return [response, snap.docs[9]];
//   };
//   return await dataDivisor(querySnapshot);
// }

// 現在進行中の選考(学生から来た求人)の一覧を取得する関数
export async function getProgressApplicationList(uid: string) {
  // ユーザーのoffersコレクションを検索
  const myProgressApplicationCol = collection(
    db,
    "compUsersPrivate",
    uid,
    "selection"
  );
  const q = query(
    myProgressApplicationCol,
    where("status", "==", "progress"),
    where("selectionType", "==", "offer"),
    orderBy("updatedAt", "desc")
  );
  const myProgressApplSnapshot = await getDocs(q);
  const progressApplcationLists = myProgressApplSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(), // すべてのフィールドを取得
  }));
  return progressApplcationLists;
}

// 企業が採用を送信後に辞退したことを登録する (確認済み)
export async function registerWithdrawAfterHired(applId: string) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const applicationDocRef = doc(db, "applications", applId);
      const applSnapshot = await transaction.get(applicationDocRef);

      if (!applSnapshot.exists()) {
        throw new Error("このオファーは無効です。");
      }

      const applData = applSnapshot.data();
      if (applData.status != "hired") {
        throw new Error("このオファーは無効です。");
      }

      transaction.update(applicationDocRef, {
        updatedAt: serverTimestamp(),
        status: "withdrawAfterHired",
      });

      const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
      transaction.update(chatDocRef, {
        selectionStatus: "withdrawAfterHired",
        updatedAt: serverTimestamp(),
        lastMesssage: "選考結果が採用から辞退に変更されました。",
      });

      const compPrivateDocRef = doc(
        db,
        "compUsersPrivate",
        applData.company.ref.id,
        "chatWithStd",
        applData.student.ref.id
      );
      transaction.update(compPrivateDocRef, {
        selectionStatus: "withdrawAfterHired",
      });

      const studentPrivateDocRef = doc(
        db,
        "jobHuntingStudentUsersPrivate",
        applData.student.ref.id,
        "chatWithComp",
        applData.company.ref.id
      );
      transaction.update(studentPrivateDocRef, {
        selectionStatus: "withdrawAfterHired",
      });

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        applData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      const msgData: stdCompMessage = {
        messageType: 2,
        senderID: applData.company.ref.id,
        readerID: applData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "withdrawAfterHired",
        isSenderStudent: false,
        message: "選考結果が採用から辞退に変更されました。",
        selectionRef: applicationDocRef,
      };
      transaction.set(msgDocRef, msgData);
    });
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}

// 企業が選考中に辞退したことを登録する
export async function registerWithdrawWhileSelection(applId: string) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const applicationDocRef = doc(db, "applications", applId);
      const applSnapshot = await transaction.get(applicationDocRef);

      if (!applSnapshot.exists()) {
        throw new Error("このオファーは無効です。");
      }

      const applData = applSnapshot.data();
      if (applData.status != "progress") {
        throw new Error("このオファーは無効です。");
      }

      transaction.update(applicationDocRef, {
        updatedAt: serverTimestamp(),
        status: "withdrawWhileSelection",
      });

      const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
      transaction.update(chatDocRef, {
        selectionStatus: "withdrawWhileSelection",
        updatedAt: serverTimestamp(),
        lastMessage: "選考中に学生が辞退しました。",
      });

      const compPrivateDocRef = doc(
        db,
        "compUsersPrivate",
        applData.company.ref.id,
        "chatWithStd",
        applData.student.ref.id
      );
      transaction.update(compPrivateDocRef, {
        selectionStatus: "withdrawWhileSelection",
      });

      const studentPrivateDocRef = doc(
        db,
        "jobHuntingStudentUsersPrivate",
        applData.student.ref.id,
        "chatWithComp",
        applData.company.ref.id
      );
      transaction.update(studentPrivateDocRef, {
        selectionStatus: "withdrawWhileSelection",
      });

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        applData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      const msgData: stdCompMessage = {
        messageType: 2,
        senderID: applData.company.ref.id,
        readerID: applData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "withdrawWhileSelection",
        isSenderStudent: false,
        message: "選考中に学生が辞退しました。",
        selectionRef: applicationDocRef,
      };
      transaction.set(msgDocRef, msgData);
    });
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}

// 企業が採用を登録する
export async function registerHired(applId: string, resultMessage: string) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const applicationDocRef = doc(db, "applications", applId);
      const applSnapshot = await transaction.get(applicationDocRef);

      if (!applSnapshot.exists()) {
        throw new Error("このオファーは無効です。");
      }

      const applData = applSnapshot.data();
      if (applData.status != "progress") {
        throw new Error("このオファーは無効です。");
      }

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        applData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      const logMsgData: stdCompMessage = {
        messageType: 2,
        senderID: applData.company.ref.id,
        readerID: applData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "hired",
        isSenderStudent: false,
        message: "選考結果：採用 が送信されました。",
        selectionRef: applicationDocRef,
      };
      transaction.set(msgDocRef, logMsgData);

      transaction.update(applicationDocRef, {
        updatedAt: serverTimestamp(),
        status: "hired",
        resultMessage: resultMessage,
      });

      if (resultMessage != "") {
        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          applData.company.ref.id,
          "chatWithStd",
          applData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "hired",
          lastMessage: resultMessage,
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          applData.student.ref.id,
          "chatWithComp",
          applData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "hired",
          lastMessage: resultMessage,
          unread: increment(1),
        });

        const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "hired",
          updatedAt: serverTimestamp(),
          lastMessage: resultMessage,
        });

        const chatMsgCol = collection(
          db,
          "chatBetCompStd",
          applData.chatRoomId,
          "messages"
        );
        const chatMsgDoc = doc(chatMsgCol);
        const msgData: stdCompMessage = {
          messageType: 1,
          senderID: applData.company.ref.id,
          readerID: applData.student.ref.id,
          sendedFileUrl: "",
          sendedAt: serverTimestamp(),
          isRead: false,
          registerdStatus: "",
          isSenderStudent: false,
          message: resultMessage,
          selectionRef: null,
        };
        transaction.set(chatMsgDoc, msgData);
      } else {
        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          applData.company.ref.id,
          "chatWithStd",
          applData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          selectionStatus: "hired",
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          applData.student.ref.id,
          "chatWithComp",
          applData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          selectionStatus: "hired",
        });

        const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "hired",
          updatedAt: serverTimestamp(),
          lastMessage: "選考結果：採用 が送信されました。",
        });
      }
    });
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}

// 企業が採用を登録する
export async function registerNotHired(applId: string, resultMessage: string) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const applicationDocRef = doc(db, "applications", applId);
      const applSnapshot = await transaction.get(applicationDocRef);

      if (!applSnapshot.exists()) {
        throw new Error("このオファーは無効です。");
      }

      const applData = applSnapshot.data();
      if (applData.status != "progress") {
        throw new Error("このオファーは無効です。");
      }

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        applData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      const logMsgData: stdCompMessage = {
        messageType: 2,
        senderID: applData.company.ref.id,
        readerID: applData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "notHired",
        isSenderStudent: false,
        message: "選考結果：不採用 が送信されました。",
        selectionRef: applicationDocRef,
      };
      transaction.set(msgDocRef, logMsgData);

      transaction.update(applicationDocRef, {
        updatedAt: serverTimestamp(),
        status: "notHired",
        resultMessage: resultMessage,
      });

      if (resultMessage != "") {
        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          applData.company.ref.id,
          "chatWithStd",
          applData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "notHired",
          lastMessage: resultMessage,
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          applData.student.ref.id,
          "chatWithComp",
          applData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "notHired",
          lastMessage: resultMessage,
          unread: increment(1),
        });

        const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "notHired",
          updatedAt: serverTimestamp(),
          lastMessage: resultMessage,
        });

        const chatMsgCol = collection(
          db,
          "chatBetCompStd",
          applData.chatRoomId,
          "messages"
        );
        const chatMsgDoc = doc(chatMsgCol);
        const msgData: stdCompMessage = {
          messageType: 1,
          senderID: applData.company.ref.id,
          readerID: applData.student.ref.id,
          sendedFileUrl: "",
          sendedAt: serverTimestamp(),
          isRead: false,
          registerdStatus: "",
          isSenderStudent: false,
          message: resultMessage,
          selectionRef: null,
        };
        transaction.set(chatMsgDoc, msgData);
      } else {
        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          applData.company.ref.id,
          "chatWithStd",
          applData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          selectionStatus: "notHired",
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          applData.student.ref.id,
          "chatWithComp",
          applData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          selectionStatus: "notHired",
        });

        const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "notHired",
          updatedAt: serverTimestamp(),
          lastMessage: "選考結果：不採用 が送信されました。",
        });
      }
    });
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}

// 企業が面接完了を登録する(当面は使用しない)
// export async function registerSelectionCompleted(offerId: string) {
//   let errMsg = "";
//   try{
//     const applicationDoc = doc(db, "applications", offerId);
//     const applSnapshot = await getDoc(applicationDoc);
//     if (applSnapshot.exists() && applSnapshot.data().status == "progress") {
//       const batch = writeBatch(db);
//       //オファーが存在し、選考完了のみ有効
//       batch.update(applicationDoc, {
//         updatedAt: serverTimestamp(),
//         status: "selectionCompleted",
//       });
//       // 辞退のため選考中の求人は無しにする
//       batch.update(doc(db,"chatBetCompStd",applSnapshot.data().chatRoomId),{selectionStatus:"applicationSelectionCompleted",lastMessage:"全ての選考が終了しました。",updatedAt:serverTimestamp()})
//       batch.commit()
//     } else {
//       errMsg = "このオファーは無効です。";
//     }
//   }catch{
//     errMsg = "エラーが発生しました。"
//   }
//   return errMsg;
// }

// ES落ちを登録する関数
export async function registerDocumentRejected(
  applId: string,
  resultMessage: string
) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const applicationDocRef = doc(db, "applications", applId);
      const applSnapshot = await transaction.get(applicationDocRef);

      if (!applSnapshot.exists()) {
        throw new Error("このオファーは無効です。");
      }

      const applData = applSnapshot.data();
      if (!["read", "unread"].includes(applData.status)) {
        throw new Error("このオファーは無効です。");
      }

      transaction.update(applicationDocRef, {
        updatedAt: serverTimestamp(),
        status: "documentRejected",
        resultMessage: resultMessage,
      });

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        applData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      const logMsgData: stdCompMessage = {
        messageType: 2,
        senderID: applData.company.ref.id,
        readerID: applData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "documentRejected",
        isSenderStudent: false,
        message: "書類選考：落選が登録されました。",
        selectionRef: applicationDocRef,
      };
      transaction.set(msgDocRef, logMsgData);

      if (resultMessage != "") {
        const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "documentRejected",
          lastMessage: resultMessage,
          updatedAt: serverTimestamp(),
        });

        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          applData.company.ref.id,
          "chatWithStd",
          applData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          updatedAt: serverTimestamp(),
          lastMessage: resultMessage,
          selectionStatus: "documentRejected",
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          applData.student.ref.id,
          "chatWithComp",
          applData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          updatedAt: serverTimestamp(),
          lastMessage: resultMessage,
          selectionStatus: "documentRejected",
        });

        const msgColRef = collection(
          db,
          "chatBetCompStd",
          applData.chatRoomId,
          "messages"
        );
        const msgDocRef = doc(msgColRef);
        transaction.set(msgDocRef, {
          messageType: 1,
          senderID: applData.company.ref.id,
          readerID: applData.student.ref.id,
          isRead: false,
          sendedFileUrl: "",
          sendedAt: serverTimestamp(),
          registerdStatus: "documentRejected",
          isSenderStudent: false,
          message: resultMessage,
          selectionRef: null,
        });
      } else {
        const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "documentRejected",
          updatedAt: serverTimestamp(),
          lastMessage: "ES落ちが記録されました。",
        });

        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          applData.company.ref.id,
          "chatWithStd",
          applData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          selectionStatus: "documentRejected",
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          applData.student.ref.id,
          "chatWithComp",
          applData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          selectionStatus: "documentRejected",
        });
      }
    });
  } catch (error) {
    console.error("Transaction failed: ", error);
    return "エラーが発生しました。";
  }
  return errMsg;
}

// ES通過を登録する関数
export async function registerDocumentAccepted(offerId: string) {
  let errMsg = "";
  try {
    const applicationDocRef = doc(db, "applications", offerId);
    const applSnapshot = await getDoc(applicationDocRef);
    if (!applSnapshot.exists()) {
      throw new Error("指定したオファーは存在しません。");
    }
    const applData = applSnapshot.data();
    if (!["read", "unread"].includes(applData.status)) {
      throw new Error("このオファーは既に処理されています。");
    }

    await runTransaction(db, async (transaction) => {
      transaction.update(applicationDocRef, {
        updatedAt: serverTimestamp(),
        status: "progress",
      });

      const chatDocRef = doc(db, "chatBetCompStd", applData.chatRoomId);
      transaction.update(chatDocRef, {
        selectionStatus: "progress",
        lastMessage: "書類選考が承認されました。",
        updatedAt: serverTimestamp(),
      });

      const compPrivateDocRef = doc(
        db,
        "compUsersPrivate",
        applData.company.ref.id,
        "chatWithStd",
        applData.student.ref.id
      );
      transaction.update(compPrivateDocRef, {
        selectionStatus: "progress",
      });

      const studentPrivateDocRef = doc(
        db,
        "jobHuntingStudentUsersPrivate",
        applData.student.ref.id,
        "chatWithComp",
        applData.company.ref.id
      );
      transaction.update(studentPrivateDocRef, {
        selectionStatus: "progress",
      });

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        applData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      transaction.set(msgDocRef, {
        messageType: 2,
        senderID: applData.company.ref.id,
        readerID: applData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "progress",
        isSenderStudent: false,
        message: "書類選考が承認されました。",
        selectionRef: applicationDocRef,
      });
    });
  } catch (error) {
    console.error("Transaction failed: ", error);
    errMsg = "エラーが発生しました。";
  }
  return errMsg;
}
