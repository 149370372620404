import ShopUsageList from "components/ShopUsageList";

const ShopUsageView: React.FC = () => {
  return (
    <div>
      <ShopUsageList />
    </div>
  );
};

export default ShopUsageView;
