import { doc, getDoc } from "firebase/firestore";
import { db } from "../main";

export async function getCpaAdCount() {
  // appVersionドキュメントの参照を取得
  const cpaAdCountRef = doc(db, "adConfig", "cpaAd");
  const docSnap = await getDoc(cpaAdCountRef);

  if (docSnap.exists()) {
    return docSnap.data().cpaAdCount;
  } else {
    console.log("No such document!");
    return null;
  }
}
export async function getCpaAdCount2() {
  // appVersionドキュメントの参照を取得
  const cpaAdCountRef = doc(db, "adConfig", "cpaAd2");
  const docSnap = await getDoc(cpaAdCountRef);

  if (docSnap.exists()) {
    return docSnap.data().cpaAdCount;
  } else {
    console.log("No such document!");
    return null;
  }
}
export async function getCpaAdCount3() {
  // appVersionドキュメントの参照を取得
  const cpaAdCountRef = doc(db, "adConfig", "cpaAd3");
  const docSnap = await getDoc(cpaAdCountRef);

  if (docSnap.exists()) {
    return docSnap.data().cpaAdCount;
  } else {
    console.log("No such document!");
    return null;
  }
}
export async function getCpaAdCount4() {
  // appVersionドキュメントの参照を取得
  const cpaAdCountRef = doc(db, "adConfig", "cpaAd4");
  const docSnap = await getDoc(cpaAdCountRef);

  if (docSnap.exists()) {
    return docSnap.data().cpaAdCount;
  } else {
    console.log("No such document!");
    return null;
  }
}
export async function getKulifeAdCount() {
  // appVersionドキュメントの参照を取得
  const kulifeAdCountRef = doc(db, "adConfig", "kulifePopup");
  const docSnap = await getDoc(kulifeAdCountRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    return {
      sumitomoClickedBannerApplyCount: data.sumitomoClickedBannerApplyCount,
      sumitomoClickedBannerCount: data.sumitomoClickedBannerCount,
      sumitomoClickedCount: data.sumitomoClickedCount,
      sumitomoClickedCountWeek2: data.sumitomoClickedCountWeek2,
      sumitomoDisplayCount: data.sumitomoDisplayCount,
      sumitomoDisplayCountWeek2: data.sumitomoDisplayCountWeek2,
    };
  } else {
    console.log("No such document!");
    return {
      sumitomoClickedBannerApplyCount: 0,
      sumitomoClickedBannerCount: 0,
      sumitomoClickedCount: 0,
      sumitomoClickedCountWeek2: 0,
      sumitomoDisplayCount: 0,
      sumitomoDisplayCountWeek2: 0,
    };
  }
}
export async function getHandyAdCount1() {
  // appVersionドキュメントの参照を取得
  const handyAdCountRef = doc(db, "adConfig", "handyPopup");
  const docSnap = await getDoc(handyAdCountRef);

  if (docSnap.exists()) {
    return docSnap.data().count;
  } else {
    console.log("No such document!");
    return null;
  }
}
