import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  runTransaction,
  serverTimestamp,
  setDoc,
  Transaction,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../main";
import {
  wasedaFes2024PurchaseEvent,
  wasedaFes2024ShopPrivate,
  wasedaFes2024ShopPublic,
} from "../../../types/coupon";
import {
  getDownloadURL,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";

// クーポンを利用
export async function useCoupon(uid: string, shopId: string) {
  let errMsg = "";
  try {
    const newPurchase = await runTransaction(db, async (transaction) => {
      const purchaseUserDoc = doc(db, "studentUsersPublic", uid);
      const purchaseUserSnapshot = await transaction.get(purchaseUserDoc);
      if (!purchaseUserSnapshot.exists()) {
        throw new Error("学生ユーザーが存在しません。");
      }
      const shopDoc = doc(
        db,
        "oemCoupons",
        "wasedaFes2024",
        "shopsPublic",
        shopId
      );
      const shopSnapshot = await transaction.get(shopDoc);
      if (!shopSnapshot.exists()) {
        throw new Error("指定したお店が見つかりませんでした。");
      }
      // 存在する場合はクーポンの使用ができない
      const purchaserEventDoc = doc(
        db,
        "oemCoupons",
        "wasedaFes2024",
        "purchaseEvents",
        uid
      );
      const isPurchaseEventExist = (
        await transaction.get(purchaserEventDoc)
      ).exists();
      if (isPurchaseEventExist) {
        throw new Error("すでにクーポンが使用されています。");
      }
      const purchaseEventData: wasedaFes2024PurchaseEvent = {
        purchaserId: uid,
        purchaserName: purchaseUserSnapshot.data().name,
        shopId: shopId,
        shopName: shopSnapshot.data().shopName,
        purchasedAt: serverTimestamp(),
      };
      console.log("isoke?");

      await transaction.set(purchaserEventDoc, purchaseEventData);
      console.log("isoke2");
    });
  } catch (error) {
    console.log(error);

    errMsg =
      error instanceof Error ? error.message : "予期せぬエラーが発生しました。";
  }

  return errMsg;
}

// 店を追加する関数
export async function addShop(
  shopName: string,
  shopContactAddress: string,
  pht: Blob,
  circleName: string,
  generalManagerName: string,
  mapPhoto: Blob
) {
  let errMsg = "";
  try {
    const publicShopRef = collection(
      db,
      "oemCoupons",
      "wasedaFes2024",
      "shopsPublic"
    );

    const publicData: wasedaFes2024ShopPublic = {
      shopName: shopName,
      createdAt: serverTimestamp(),
      phtUrl: "",
      circleName: circleName,
      mapPhotoUrl: "",
    };
    const privateData: wasedaFes2024ShopPrivate = {
      shopName: shopName,
      shopCount: 0,
      contactAddress: shopContactAddress,
      createdAt: serverTimestamp(),
      phtUrl: "",
      circleName: circleName,
      generalManagerName: generalManagerName,
      mapPhotoUrl: "",
    };
    const newPublicShopDoc = await addDoc(publicShopRef, publicData);
    const shopId = newPublicShopDoc.id;
    const privateShopDoc = doc(
      db,
      "oemCoupons",
      "wasedaFes2024",
      "shopsPrivate",
      shopId
    );
    await setDoc(privateShopDoc, privateData);

    // PHT画像のアップロード
    const phtRef: StorageReference = ref(
      storage,
      `oemCoupons/wasedaFes2024/shops/${shopId}/pht`
    );
    await uploadBytes(phtRef, pht);
    const phtUrl = await getDownloadURL(phtRef);

    // マップ画像のアップロード（存在する場合）
    const mapPhotoRef: StorageReference = ref(
      storage,
      `oemCoupons/wasedaFes2024/shops/${shopId}/map`
    );
    await uploadBytes(mapPhotoRef, mapPhoto);
    const mapPhotoUrl = await getDownloadURL(mapPhotoRef);

    // Update the shop documents with the image URLs
    await updateDoc(newPublicShopDoc, {
      phtUrl: phtUrl,
      mapPhotoUrl: mapPhotoUrl,
    });
    await updateDoc(privateShopDoc, {
      phtUrl: phtUrl,
      mapPhotoUrl: mapPhotoUrl,
    });
  } catch (error) {
    console.log(error);
    errMsg =
      error instanceof Error ? error.message : "予期せぬエラーが発生しました。";
  }
  return errMsg;
}

//クーポンを使用済みかの確認
export async function checkCouponUsed(uid: string): Promise<boolean> {
  try {
    const purchaserEventDoc = doc(
      db,
      "oemCoupons",
      "wasedaFes2024",
      "purchaseEvents",
      uid
    );
    const purchaseEventSnapshot = await getDoc(purchaserEventDoc);
    return purchaseEventSnapshot.exists();
  } catch (error) {
    console.error("クーポン使用確認中にエラーが発生しました:", error);
    throw error;
  }
}

// 全てのショップを取得する関数
export async function displayShops() {
  try {
    const shopsRef = collection(
      db,
      "oemCoupons",
      "wasedaFes2024",
      "shopsPublic"
    );
    const querySnapshot = await getDocs(shopsRef);
    const shops: wasedaFes2024ShopPublic[] = [];

    querySnapshot.forEach((doc) => {
      const shopData = doc.data() as wasedaFes2024ShopPublic;
      shops.push({
        shopId: doc.id,
        ...shopData,
      });
    });

    return shops;
  } catch (error) {
    console.error("店舗情報の取得中にエラーが発生しました:", error);
    throw error;
  }
}

export async function getShopUsageCount() {
  try {
    // 一度だけデータを取得する
    const purchaseEventsRef = collection(
      db,
      "oemCoupons",
      "wasedaFes2024",
      "purchaseEvents"
    );
    const purchaseSnapshot = await getDocs(purchaseEventsRef);
    console.log(purchaseSnapshot);

    const shopCounts: { [key: string]: number } = {};
    purchaseSnapshot.forEach((doc) => {
      const data = doc.data() as wasedaFes2024PurchaseEvent;
      shopCounts[data.shopId] = (shopCounts[data.shopId] || 0) + 1;
    });

    // 店舗情報も一度だけ取得
    const shopsRef = collection(
      db,
      "oemCoupons",
      "wasedaFes2024",
      "shopsPublic"
    );
    const shopsSnapshot = await getDocs(shopsRef);

    const shopUsage = shopsSnapshot.docs.map((doc) => ({
      shopId: doc.id,
      shopName: doc.data().shopName,
      usageCount: shopCounts[doc.id] || 0,
    }));

    return shopUsage;
  } catch (error) {
    console.error("クーポン使用回数の取得中にエラーが発生しました:", error);
    throw error;
  }
}
