import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Backdrop, CircularProgress, Modal, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { offerType } from "types/page/offerType";
import { useEffect } from "react";
import {
  daysToRecruitData,
  EmploymentStatusData,
  graduatedYearData,
  jobCategoryData,
  offerTypeData,
  recruitedNumberData,
} from "data/StudentProfileData";
import { prefectureData } from "data/CompanyUserData";
import { useUser } from "hooks/firebase/context/auth";
import { deleteOfferTemplate } from "hooks/firebase/context/offer";

const mdTheme = createTheme();
const StyledMuiButton = styled(Button)`
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
const StyledButton = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #4d4d4d;
  }
`;

const StyledProfileButton = styled(Button)`
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
const StyledHeadline = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 24px !important;
  color: dimgray;
`;
const StyledRecruitCatchcopyHeadline = styled(Typography)`
  font-size: 26px !important;
  font-weight: bold !important;
  margin-top: 24px !important;
  margin-bottom: 12px !important;
  padding: 0 !important;
  color: black !important;
`;

const StyledSubHeadline = styled(Typography)`
  font-size: 20px !important;
  margin-left: 16px !important;
  margin-top: 8px !important;
`;
const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 40px 60px 60px 60px;
  text-align: center;
`;
export default function CompanyAppUiRecruitDetail() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();
  const user = useUser();

  const location = useLocation();
  const [offerTemplate, setOfferTemplate] = React.useState<offerType>();
  const [defaultIconImage, setDefaultIconImage] = React.useState("");
  const [defaultProfileImage, setDefaultProfileImage] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [jobCategory, setJobCategory] = React.useState<number[]>();
  const [prefecture, setPrefecture] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [companyInfoRecruitCatchCopy, setCompanyInfoRecruitCatchCopy] =
    React.useState("");
  const handleClose = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (user) {
      setOfferTemplate(location.state);
      setDefaultIconImage(location.state.iconImage);
      setDefaultProfileImage(location.state.profileImage);
      setCompanyName(location.state.name);
      setJobCategory(location.state.jobCategory);
      setPrefecture(location.state.workPlace);
      setCompanyInfoRecruitCatchCopy(
        location.state.companyInfoRecruitCatchCopy
      );
      window.scrollTo(0, 0);
    }
  }, [location, user]);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  useEffect(() => {
    setIsLoading(true);
    if (!user) {
      const timer = setTimeout(() => {
        if (!user) {
          navigate("/companylogin");
        }
      }, 3500);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [user, navigate]);

  const deleteOffer = async () => {
    if (offerTemplate?.id && user) {
      const errMsg = await deleteOfferTemplate(offerTemplate?.id, user);
      if (errMsg === "") {
        navigate("/companyprofiledetail");
      } else {
        alert(errMsg);
      }
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box mt={8} sx={{ display: "flex", justifyContent: "center" }}>
            {defaultProfileImage ? (
              <img
                src={defaultProfileImage}
                alt={"companyProfile"}
                loading="lazy"
                style={{
                  width: open
                    ? "calc((100vw - 240px)*0.6)"
                    : "calc((100vw - 72px)*0.6)",
                  height: open
                    ? "calc((100vw - 240px)*0.3)"
                    : "calc((100vw - 72px)*0.3)",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  backgroundColor: "lightgray",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: -2,
              ml: "20%",
              px: 3,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {defaultIconImage ? (
                <Box
                  sx={{
                    width: { xs: "80px", lg: "100px" },
                    height: { xs: "80px", lg: "100px" },
                  }}
                >
                  <img
                    src={defaultIconImage}
                    alt={"companyProfile"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "10px",
                    border: "1px solid white",
                    backgroundColor: "lightgray",
                  }}
                />
              )}

              <Box sx={{ maxWidth: "60%", ml: 4 }}>
                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    {companyName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: 20, color: "gray", mr: 3, minWidth: 42 }}
                  >
                    職種
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {jobCategory &&
                      jobCategory
                        .map((category) => jobCategoryData[category].value)
                        .join(", ")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 20, color: "gray", mr: 3 }}>
                    所在地
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {prefecture !== undefined &&
                      prefectureData[prefecture].value}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ width: "80%", display: "flex", justifyContent: "flex-end" }}
          >
            <StyledButton
              style={{ marginTop: 20 }}
              onClick={() => navigate("/companyprofiledetail")}
            >
              プロフィール画面へ戻る
            </StyledButton>
          </Box>
          <Box sx={{ width: "60%", mx: "auto" }}>
            <StyledRecruitCatchcopyHeadline>
              {companyInfoRecruitCatchCopy.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledRecruitCatchcopyHeadline>
            <Box sx={{ display: "flex" }}>
              <StyledMuiButton
                onClick={() => {
                  navigate("/companyappuiprofiledetail");
                }}
                startIcon={
                  <FontAwesomeIcon icon={faBuilding} size="lg" color="black" />
                }
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  企業概要
                </Typography>
              </StyledMuiButton>
              <StyledMuiButton
                startIcon={
                  <FontAwesomeIcon icon={faUser} size="lg" color="black" />
                }
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  求人情報
                </Typography>
              </StyledMuiButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "lightgray",
                }}
              />
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "black",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 5,
                mb: 3,
              }}
            >
              <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                {offerTemplate?.recruitGraduatedYear !== undefined &&
                  graduatedYearData[offerTemplate?.recruitGraduatedYear].value}
                卒向け ＜
                {offerTemplate?.offerType !== undefined &&
                  offerTypeData[offerTemplate?.offerType].value}
                ＞
              </Typography>
            </Box>
            <Modal open={isModalOpen} onClose={handleClose}>
              <ModalBox>
                <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                  本当に削除しますか？
                </Typography>
                <Typography sx={{ fontSize: 16 }}>
                  この操作は取り消せません
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                  <StyledOfferButton onClick={handleClose}>
                    キャンセル
                  </StyledOfferButton>
                  <StyledOfferButton onClick={deleteOffer}>
                    削除する
                  </StyledOfferButton>
                </Box>
              </ModalBox>
            </Modal>
            <StyledHeadline>職種名</StyledHeadline>
            <StyledSubHeadline>{offerTemplate?.jobName}</StyledSubHeadline>
            <StyledHeadline>求人カテゴリー</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.offerType !== undefined &&
                offerTypeData[offerTemplate?.offerType].value}
            </StyledSubHeadline>
            <StyledHeadline>採用卒業年度</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.recruitGraduatedYear !== undefined &&
                graduatedYearData[offerTemplate?.recruitGraduatedYear].value}
              年卒
            </StyledSubHeadline>
            <StyledHeadline>雇用形態</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.employmentStatus !== undefined &&
                EmploymentStatusData[offerTemplate?.employmentStatus].value}
            </StyledSubHeadline>
            <StyledHeadline>給与</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.minSalary}円 ~ {offerTemplate?.maxSalary}円
            </StyledSubHeadline>
            {offerTemplate?.salaryDetail !== "" && (
              <Box>
                <StyledHeadline>給与詳細</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.salaryDetail
                    .split("\n")
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </StyledSubHeadline>
              </Box>
            )}

            <StyledHeadline>試用期間</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.trialPeriod.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>採用予定人数</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.recruitedNumber !== undefined &&
                recruitedNumberData[offerTemplate?.recruitedNumber].value}
            </StyledSubHeadline>
            <StyledHeadline>採用までの希望日数</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.daysToRecruit !== undefined &&
                daysToRecruitData[offerTemplate?.daysToRecruit].value}
            </StyledSubHeadline>
            <StyledHeadline>仕事内容</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.jobDetail.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>アピールポイント</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.appealPoint.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>求める人材</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.neededPersonal.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>勤務時間・曜日</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.workDay.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            {/* <StyledHeadline>勤務地</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.workPlace !== undefined &&
                prefectureData[offerTemplate?.workPlace].value}
            </StyledSubHeadline> */}
            <StyledHeadline>アクセス</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.access.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>待遇・福利厚生</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.treatment.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            {offerTemplate?.others !== "" && (
              <Box>
                <StyledHeadline>その他</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.others.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </StyledSubHeadline>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
