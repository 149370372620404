import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import UniversitySelectInput from "components/UniversitySelectInput";
import BunriSelectInput from "components/BunriSelectInput";
import { Backdrop, CircularProgress, Modal, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GraduateSelectInput from "components/GraduateSelectInput";
import { CompanyHeader } from "components/CompanyHeader";
import StudentProfileCard from "components/StudentProfileCard";
import { useEffect, useState } from "react";
import { useUser } from "hooks/firebase/context/auth";
import styled from "styled-components";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { searchJobHuntingStudentFromUpdatedAt } from "hooks/firebase/context/offer";
import { Timestamp } from "firebase/firestore";

const mdTheme = createTheme();

export default function CompanySearchStudent() {
  const location = useLocation();

  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [studentInfos, setStudentInfos] = useState<studentInfoTypes[]>();
  const [isEndData, setIsEndData] = useState(true);
  const [universitySelect, setUniversitySelect] = useState("1");
  const [humanitiesOrScience, setHumanitiesOrScience] = useState("1");
  const [graduateSelect, setGraduateSelect] = useState("1");
  const [lastCreatedAtDataState, setLastUpdatedAtDataState] =
    useState<Timestamp>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [updateScrollPosition, setUpdateScrollPosition] = useState<number>(0);

  // useEffect(() => {
  //   localStorage.setItem("universitySelect", universitySelect);
  // }, [universitySelect]);
  // useEffect(() => {
  //   localStorage.setItem("humanitiesOrScience", humanitiesOrScience);
  // }, [humanitiesOrScience]);
  // useEffect(() => {
  //   localStorage.setItem("graduate", graduateSelect);
  // }, [graduateSelect]);

  // useLayoutEffect(() => {
  //   const university = localStorage.getItem("universitySelect");
  //   if (university) {
  //     setUniversitySelect(university);
  //     setUniversityLocalData(university);
  //   }
  //   const humanitiesOrScience = localStorage.getItem("humanitiesOrScience");
  //   if (humanitiesOrScience) {
  //     setHumanitiesOrScience(humanitiesOrScience);
  //     setHumanitiesOrScienceLocalData(humanitiesOrScience);
  //   }
  //   const graduate = localStorage.getItem("graduate");
  //   if (graduate) {
  //     setGraduateSelect(graduate);
  //     setGraduateLocalData(graduate);
  //   }
  // }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const user = useUser();

  const fetchStudentInfos = async (
    university: number,
    humanitiesOrScience: number,
    graduatedYear: number
  ) => {
    setIsLoading(true);
    try {
      setStudentInfos([]);
      setUpdateScrollPosition(0);
      const { students, lastCreatedAtData } =
        await searchJobHuntingStudentFromUpdatedAt(
          university,
          humanitiesOrScience,
          graduatedYear
        );
      if (students.length < 10) {
        setIsEndData(true);
      } else {
        setIsEndData(false);
        setLastUpdatedAtDataState(lastCreatedAtData);
        // setLastVisibleData(lastVisible);
      }
      setStudentInfos(students as studentInfoTypes[]);
    } catch (error) {
      console.error("Failed to fetch student infos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExtraStudent = async () => {
    if (lastCreatedAtDataState) {
      setIsLoading(true);
      try {
        const { students, lastCreatedAtData } =
          await searchJobHuntingStudentFromUpdatedAt(
            Number(universitySelect),
            Number(humanitiesOrScience),
            Number(graduateSelect),
            new Timestamp(
              lastCreatedAtDataState.seconds,
              lastCreatedAtDataState.nanoseconds
            )
          );
        if (students.length < 10) {
          setIsEndData(true);
        } else {
          setIsEndData(false);
          setLastUpdatedAtDataState(lastCreatedAtData);
        }
        setStudentInfos((prev = []) => [
          ...prev,
          ...(students as studentInfoTypes[]),
        ]);
      } catch (error) {
        console.error("Failed to fetch student infos:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user != null && !location.state?.searchedData) {
        await fetchStudentInfos(1, 1, 1);
      } else if (
        user != null &&
        location.state?.searchedData &&
        location.state?.searchedData
      ) {
        if (location.state?.searchedData)
          setStudentInfos(location.state?.searchedData);
        setIsEndData(location.state?.isEndData);
        if (location.state?.lastCreatedAtDataState)
          setLastUpdatedAtDataState(location.state?.lastCreatedAtDataState);
        if (location.state?.scrollPositionState)
          setUpdateScrollPosition(location.state?.scrollPositionState);
        if (location.state?.universitySelectState)
          setUniversitySelect(location.state?.universitySelectState);
        if (location.state?.humanitiesOrScienceState)
          setHumanitiesOrScience(location.state?.humanitiesOrScienceState);
        if (location.state?.graduateSelectState)
          setGraduateSelect(location.state?.graduateSelectState);
      }
    };
    fetchData();
  }, [
    location.state?.searchedData,
    location.state?.isEndData,
    user,
    location.state?.lastCreatedAtDataState,
    location.state,
  ]);

  const scrollToPosition = (position: number) => {
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.state?.searchedData) {
      scrollToPosition(updateScrollPosition);
    }
  }, [updateScrollPosition, location.state?.searchedData]);

  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showofferModalProps={true}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        {/* <Box
          sx={{
            width: { xs: 242, sm: 302 },
            height: 42,
            borderRadius: 20,
            marginTop: { xs: 10, sm: 12 },
            border: "1px solid #4b4b4b",
          }}
          mx="auto"
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              height: 40,
              fontSize: "1.2rem",
              fontWeight: "bold",
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            学生
          </Button>
          <Button
            onClick={() => navigate("/companysearchclub")}
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              color: "#4b4b4b",
              fontWeight: "bold",
              fontSize: "1.2rem",
              height: 40,
              backgroundColor: "rgba(0,0,0,0)",
              ":hover": { backgroundColor: "rgba(0,0,0,0)" },
            }}
          >
            サークル
          </Button>
        </Box> */}

        {/* <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 500,
            mx: "auto",
            mt: 5,
            borderRadius: 20,
            boxShadow: 0,
            border: "1px solid black",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="キーワード検索  例)TOEIC、早稲田大学"
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper> */}
        <Box
          sx={{
            display: "flex",
            width: "80%",
            height: "160px",
            flexWrap: "wrap",
            mx: "auto",
            justifyContent: "space-around",
            alignItems: "center",
            mt: 10,
          }}
        >
          <UniversitySelectInput
            setUniversitySelecProps={setUniversitySelect}
            defaultValue={universitySelect}
          />
          <BunriSelectInput
            setHumanitiesOrScienceProps={setHumanitiesOrScience}
            defaultValue={humanitiesOrScience}
          />
          <GraduateSelectInput
            setGraduateSelectProps={setGraduateSelect}
            defaultValue={graduateSelect}
          />
          <Button
            sx={{
              display: { xs: "flex", lg: "flex" },
              backgroundColor: "#4b4b4b",
              "&:hover": { backgroundColor: "#4b4b4b" },
              color: "white",
              width: 120,
              height: 40,
              borderRadius: 1,
              alignItems: "center",
            }}
            aria-label="search"
            onClick={() => {
              if (universitySelect && humanitiesOrScience && graduateSelect)
                fetchStudentInfos(
                  Number(universitySelect),
                  Number(humanitiesOrScience),
                  Number(graduateSelect)
                );
            }}
          >
            <SearchIcon sx={{ color: "white", marginRight: 1 }} />
            <Typography sx={{ color: "white" }}>検索</Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            flexWrap: "wrap",
            mx: "auto",
            mt: 5,
          }}
        >
          {studentInfos &&
            studentInfos.map((studentInfo, i) => {
              return (
                <StudentProfileCard
                  key={i}
                  studentProfileInfos={studentInfo}
                  searchingStudentData={studentInfos}
                  isEndData={isEndData}
                  lastCreatedAtDataState={lastCreatedAtDataState}
                  scrollPositionState={scrollPosition}
                  universitySelectState={universitySelect}
                  humanitiesOrScienceState={humanitiesOrScience}
                  graduateSelectState={graduateSelect}
                />
              );
            })}
        </Box>
        <Box sx={{ mb: 5 }}>
          {!isEndData && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={getExtraStudent}
                disableElevation
                style={{
                  width: 250,
                  fontWeight: "bold",
                  borderRadius: 5,
                  marginTop: 30,
                  marginBottom: 8,
                  height: 40,
                  backgroundColor: "#434343",
                }}
              >
                次の10件
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
