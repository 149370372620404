import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "styled-components";
import { getOfferTemplatesInfos } from "hooks/firebase/context/offer";
import { useUser } from "hooks/firebase/context/auth";
import { offerType } from "types/page/offerType";
import { graduatedYearData, offerTypeData } from "data/StudentProfileData";
import AddIcon from "@mui/icons-material/Add";

const mdTheme = createTheme();

const StyledRecruitButton = styled(Button)`
  display: flex;
  textalign: left;
  width: 300px;
  box-shadow: 0px 5px 10px lightgray;
  padding: 15px !important;
  background-color: white !important;
  margin: 20px !important;
`;

export default function PublicCreatedRecruit() {
  const navigate = useNavigate();
  const user = useUser();
  const [open, setOpen] = useState(true);
  const [offerTemplates, setOfferTemplates] = useState<offerType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  useEffect(() => {
    if (user) {
      const fetchOfferTemplates = async () => {
        const [response, lastSnap] = await getOfferTemplatesInfos(user);
        setOfferTemplates(response);
        setIsLoading(false);
      };
      fetchOfferTemplates();
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(true);
    if (!user) {
      const timer = setTimeout(() => {
        if (!user) {
          navigate("/companylogin");
        }
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [user, navigate]);
  // useEffect(() => {
  //   if (user) {
  //     const fetchOfferTemplates = async () => {
  //       const isPublic = await isOfferTemplateOpen(offerTemplates[0].id, user);
  //       console.log(isPublic);
  //     };
  //     fetchOfferTemplates();
  //   }
  // }, [offerTemplates]);

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showrecruitModalProps={true}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box mt={14}>
          <Typography
            fontSize={"1.8rem"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            作成した求人
          </Typography>
        </Box>
        {/* TODO */}
        {/* <Box
          sx={{
            width: { xs: 340, sm: 500 },
            height: 40,
            borderRadius: 20,
            marginTop: { xs: 3, sm: 5 },
            backgroundColor: "#D9D9D9",
          }}
          mx="auto"
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              height: 40,
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              fontWeight: "bold",
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            掲載中の求人
          </Button>
          <Button
            onClick={() => navigate("/non-public-created-recruit")}
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              fontWeight: "bold",
              color: "#4b4b4b",
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              height: 40,
              backgroundColor: "#D9D9D9",
              ":hover": { backgroundColor: "#D9D9D9" },
            }}
          >
            未掲載の求人
          </Button>
        </Box> */}
        <Box
          sx={{
            width: "80%",
            marginLeft: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: 3,
          }}
        >
          {offerTemplates.map((offerTemplate) => {
            return (
              <StyledRecruitButton
                key={offerTemplate.id}
                sx={{
                  borderRadius: 5,
                  mt: 3,
                }}
                onClick={() =>
                  navigate("/recruit-detail-content", {
                    state: {
                      trialPeriod: offerTemplate.trialPeriod,
                      access: offerTemplate.access,
                      appealPoint: offerTemplate.appealPoint,
                      daysToRecruit: offerTemplate.daysToRecruit,
                      employmentStatus: offerTemplate.employmentStatus,
                      jobDetail: offerTemplate.jobDetail,
                      jobName: offerTemplate.jobName,
                      maxSalary: offerTemplate.maxSalary,
                      minSalary: offerTemplate.minSalary,
                      neededPersonal: offerTemplate.neededPersonal,
                      offerType: offerTemplate.offerType,
                      others: offerTemplate.others,
                      recruitCatchCopy: offerTemplate.recruitCatchCopy,
                      recruitGraduatedYear: offerTemplate.recruitGraduatedYear,
                      recruitedNumber: offerTemplate.recruitedNumber,
                      salaryDetail: offerTemplate.salaryDetail,
                      salaryForm: offerTemplate.salaryForm,
                      treatment: offerTemplate.treatment,
                      workDay: offerTemplate.workDay,
                      workPlace: offerTemplate.workPlace,
                      id: offerTemplate.id,
                    },
                  })
                }
              >
                <Box
                  sx={{
                    width: "3px",
                    height: "50px",
                    position: "absolute",
                    top: 20,
                    left: 20,
                    backgroundColor:
                      offerTemplate.offerType === 0 ? "blue" : "red",
                    mr: 2,
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    color: "black",
                    ml: 3,
                  }}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    {offerTemplate.recruitGraduatedYear !== undefined &&
                      graduatedYearData[offerTemplate.recruitGraduatedYear]
                        .value}
                    年卒向け
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    ＜
                    {offerTemplate.offerType !== undefined &&
                      offerTypeData[offerTemplate.offerType].value}
                    ＞
                  </Typography>
                  <Typography sx={{ my: 1, wordBreak: "break-word" }}>
                    {offerTemplate.appealPoint}
                  </Typography>
                  <Box sx={{ textAlign: "right" }}>
                    <Typography sx={{ fontWeight: "bold" }}>開く＞</Typography>
                  </Box>
                </Box>
              </StyledRecruitButton>
            );
          })}
        </Box>
        {/* <Box
          sx={{
            width: "80%",
            marginLeft: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: 3,
          }}
        >
          <StyledRecruitButton
            sx={{
              borderRadius: 5,
              mt: 3,
            }}
            onClick={() => navigate("/sample/edit-recruit-detail")}
          >
            <Box
              sx={{
                width: "3px",
                height: "50px",
                backgroundColor: "red",
                mr: 2,
              }}
            />
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                2024年卒向け
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>＜新卒採用＞</Typography>
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontWeight: "bold" }}>開く＞</Typography>
              </Box>
            </Box>
          </StyledRecruitButton>
          <StyledRecruitButton
            sx={{
              borderRadius: 5,
              mt: 3,
            }}
            onClick={() => navigate("/sample/edit-recruit-detail-intern")}
          >
            <Box
              sx={{
                width: "3px",
                height: "50px",
                backgroundColor: "#00A2C6",
                mr: 2,
              }}
            />
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                2025年卒向け
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                ＜長期インターン＞
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontWeight: "bold" }}>開く＞</Typography>
              </Box>
            </Box>
          </StyledRecruitButton>
          <StyledRecruitButton
            sx={{
              borderRadius: 5,
              mt: 3,
            }}
            onClick={() => navigate("/sample/edit-recruit-detail")}
          >
            <Box
              sx={{
                width: "3px",
                height: "50px",
                backgroundColor: "red",
                mr: 2,
              }}
            />
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                2023年卒向け
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>＜新卒採用＞</Typography>
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontWeight: "bold" }}>開く＞</Typography>
              </Box>
            </Box>
          </StyledRecruitButton>
          <StyledRecruitButton
            sx={{
              borderRadius: 5,
              mt: 3,
            }}
            onClick={() => navigate("/sample/edit-recruit-detail-intern")}
          >
            <Box
              sx={{
                width: "3px",
                height: "50px",
                backgroundColor: "#00A2C6",
                mr: 2,
              }}
            />
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                2024年卒向け
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                ＜長期インターン＞
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontWeight: "bold" }}>開く＞</Typography>
              </Box>
            </Box>
          </StyledRecruitButton>
        </Box>

        <Stack direction="column" alignItems="center" spacing={1}>
          <IconButton
            onClick={() => navigate("/companycreaterecruit")}
            sx={{
              backgroundColor: "black",
              p: 0,
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          >
            <AddIcon
              sx={{ color: "white", fontSize: 32, fontWeight: "bold" }}
            />
          </IconButton>
          <Typography>求人を追加する</Typography>
        </Stack>
      </Box> */}
        <Stack direction="column" alignItems="center" spacing={1}>
          <IconButton
            onClick={() => navigate("/companycreaterecruit")}
            sx={{
              backgroundColor: "black",
              p: 0,
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          >
            <AddIcon
              sx={{ color: "white", fontSize: 32, fontWeight: "bold" }}
            />
          </IconButton>
          <Typography>求人を追加する</Typography>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
