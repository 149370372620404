import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography, Grid, CardMedia, Chip } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  graduatedMonthData,
  graduatedYearData,
  studentDepartmentData,
  studentFacultyData,
} from "data/StudentProfileData";
import {
  businessData,
  companySizeData,
  jobData,
  workStyleData,
} from "data/CompanyUserData";
import { jobStudentInfoType } from "types/page/jobStudentInfoType";
import { oemUniversityData } from "data/OEMData";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { setFavoriteStudent } from "hooks/firebase/context/studentInfo";
import { useUser } from "hooks/firebase/context/auth";

const mdTheme = createTheme();

const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 4px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const TableLeftText = styled(Typography)`
  font-size: 20px !important;
  color: dimgray !important;
  margin: 10px 0 !important;
  font-weight: bold !important;
  text-align: center !important;
`;
const TableText = styled(Typography)`
  font-size: 20px !important;
  font-weight: bold !important;
  margin: 10px 0 !important;
`;
export default function StudentProfileDesiredOccupation() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [studentInfos, setStudentInfos] = useState<studentInfoTypes>();

  useEffect(() => {
    setStudentInfos(location.state);
    window.scrollTo(0, 0);
  }, [location]);

  const blockBrowserBack = React.useCallback(() => {
    console.log("1");
    window.history.go(1);
  }, []);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    const handlePopState = (event: any) => {
      event.preventDefault();
      if (
        window.confirm(
          "ブラウザバックするとデータが破棄されます。続行しますか？\nページの「戻る」ボタンでデータを保持したまま戻ることができます。"
        )
      ) {
        // ユーザーがOKを選択した場合、ブラウザバックを許可
        window.history.back();
      } else {
        // キャンセルを選択した場合、再度現在のURLを履歴に追加
        window.history.pushState(null, "", window.location.href);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const setFavorite = async () => {
    if (user && studentInfos?.senderId) {
      console.log(studentInfos?.senderId, user.auth.uid);
      await setFavoriteStudent(studentInfos?.senderId, user.auth.uid);
    }
  };

  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box
            sx={{
              mt: 8,
              px: 5,
              backgroundColor: "#C6D6E2",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, borderRadius: 100, mt: 5 }}
                image={studentInfos?.iconPhtUrl}
              />
              <Box sx={{ ml: 10, mt: 5 }}>
                {studentInfos?.lastName ? (
                  <Box sx={{ my: 1, display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: 38, fontWeight: "bold" }}>
                      {studentInfos?.lastName + " " + studentInfos?.firstName}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 26, fontWeight: "bold", ml: 3 }}
                    >
                      {"(" +
                        studentInfos?.lastNameKana +
                        " " +
                        studentInfos?.firstNameKana +
                        ")"}
                    </Typography>
                  </Box>
                ) : (
                  <Box />
                )}

                <Box sx={{ my: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.university ? (
                      <Typography
                        sx={{ mb: 3, fontSize: 22, fontWeight: "bold" }}
                      >
                        {oemUniversityData[studentInfos?.university - 1].value +
                          " "}
                        {studentInfos?.faculty &&
                          studentFacultyData[studentInfos?.faculty - 1].value +
                            " "}
                        {studentInfos?.department
                          ? studentDepartmentData[studentInfos?.department - 1]
                              .value
                          : ""}
                      </Typography>
                    ) : (
                      <Box />
                    )}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.humanitiesOrScience &&
                    studentInfos.humanitiesOrScience === 1 ? (
                      <Chip label={"文系"} color="error" />
                    ) : (
                      <Chip label={"理系"} color="primary" />
                    )}
                    <Typography
                      sx={{ ml: 2, fontSize: 20, fontWeight: "bold" }}
                    >
                      {studentInfos?.graduatedYear &&
                        studentInfos?.graduatedMonth &&
                        graduatedYearData[studentInfos?.graduatedYear - 1]
                          .value +
                          "年" +
                          graduatedMonthData[studentInfos?.graduatedMonth - 1]
                            .value +
                          "月 卒業予定"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 5 }}>
              {/* <Button onClick={setFavorite} sx={{ width: 200, height: 40 }}>
                保存する
              </Button> */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  height: 40,
                }}
              >
                {studentInfos?.senderId && (
                  <Button
                    onClick={() => {
                      navigate("/companysearchstudent", {
                        state: {
                          searchedData: studentInfos?.searchingStudentData,
                          isEndData: studentInfos?.isEndData,
                          lastCreatedAtDataState:
                            studentInfos?.lastCreatedAtDataState,
                          scrollPositionState:
                            studentInfos?.scrollPositionState,
                          universitySelectState:
                            studentInfos?.universitySelectState,
                          humanitiesOrScienceState:
                            studentInfos?.humanitiesOrScienceState,
                          graduateSelectState:
                            studentInfos?.graduateSelectState,
                        },
                      });
                    }}
                    sx={{
                      width: 90,
                      height: 40,
                      p: 0,
                      backgroundColor: "rgb(75,75,75)",
                      "&:hover": {
                        backgroundColor: "rgb(75,75,75)",
                      },
                      color: "white",
                    }}
                  >
                    <Typography>戻る</Typography>
                  </Button>
                )}
                {studentInfos?.senderId && (
                  <StyledOfferButton
                    onClick={() => {
                      navigate("/company-offer-select-detail", {
                        state: {
                          studentSenderId: studentInfos?.senderId,
                        },
                      });
                    }}
                  >
                    オファーする
                  </StyledOfferButton>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                onClick={() => {
                  navigate("/companystudentprofiledetail", {
                    state: {
                      iconPhtUrl: studentInfos?.iconPhtUrl,
                      senderId: studentInfos?.senderId,
                      grade: studentInfos?.grade,
                      university: studentInfos?.university,
                      faculty: studentInfos?.faculty,
                      department: studentInfos?.department,
                      firstName: studentInfos?.firstName,
                      lastName: studentInfos?.lastName,
                      firstNameKana: studentInfos?.firstNameKana,
                      lastNameKana: studentInfos?.lastNameKana,
                      sex: studentInfos?.sex,
                      livingArea: studentInfos?.livingArea,
                      birthPlace: studentInfos?.birthPlace,
                      graduatedHighSchool: studentInfos?.graduatedHighSchool,
                      certification: studentInfos?.certification,
                      programmingExperience:
                        studentInfos?.programmingExperience,
                      programmingExperienceDetail:
                        studentInfos?.programmingExperienceDetail,
                      circle: studentInfos?.circle,
                      researchGroup: studentInfos?.researchGroup,
                      desiredFirstBusiness: studentInfos?.desiredFirstBusiness,
                      desiredSecondBusiness:
                        studentInfos?.desiredSecondBusiness,
                      desiredThirdBusiness: studentInfos?.desiredThirdBusiness,
                      desiredFirstJob: studentInfos?.desiredFirstJob,
                      desiredSecondJob: studentInfos?.desiredSecondJob,
                      desiredThirdJob: studentInfos?.desiredThirdJob,
                      choiceFirstStandard: studentInfos?.choiceFirstStandard,
                      choiceSecondStandard: studentInfos?.choiceSecondStandard,
                      choiceThirdStandard: studentInfos?.choiceThirdStandard,
                      graduatedYear: studentInfos?.graduatedYear,
                      graduatedMonth: studentInfos?.graduatedMonth,
                      humanitiesOrScience: studentInfos?.humanitiesOrScience,
                      internCompName: studentInfos?.internCompName,
                      internJobType: studentInfos?.internJobType,
                      internPeriod: studentInfos?.internPeriod,
                      selfPRMain: studentInfos?.selfPRMain,
                      updatedAt: studentInfos?.updatedAt,
                      searchingStudentData: studentInfos?.searchingStudentData,
                      lastCreatedAtDataState:
                        studentInfos?.lastCreatedAtDataState,
                      scrollPositionState: studentInfos?.scrollPositionState,
                      universitySelectState:
                        studentInfos?.universitySelectState,
                      humanitiesOrScienceState:
                        studentInfos?.humanitiesOrScienceState,
                      graduateSelectState: studentInfos?.graduateSelectState,
                    },
                  });
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  プロフィール
                </Typography>
              </Button>
              <Button>
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  就活における価値観
                </Typography>
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "240px",
                  height: "5px",
                  backgroundColor: "black",
                  ml: "160px",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ mx: 5, my: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <TableLeftText>志望業界</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>
                  {studentInfos?.desiredFirstBusiness
                    ? businessData[studentInfos?.desiredFirstBusiness - 1].value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.desiredSecondBusiness
                    ? businessData[studentInfos?.desiredSecondBusiness - 1]
                        .value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.desiredThirdBusiness
                    ? businessData[studentInfos?.desiredThirdBusiness - 1].value
                    : "未選択"}
                </TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>志望職種</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>
                  {studentInfos?.desiredFirstJob
                    ? jobData[studentInfos.desiredFirstJob - 1].value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.desiredSecondJob
                    ? jobData[studentInfos.desiredSecondJob - 1].value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.desiredThirdJob
                    ? jobData[studentInfos.desiredThirdJob - 1].value
                    : "未選択"}
                </TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>働き方の軸</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>
                  {studentInfos?.choiceFirstStandard
                    ? workStyleData[studentInfos.choiceFirstStandard - 1].value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.choiceSecondStandard
                    ? workStyleData[studentInfos.choiceSecondStandard - 1].value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.choiceThirdStandard
                    ? workStyleData[studentInfos.choiceThirdStandard - 1].value
                    : "未選択"}
                </TableText>
              </Grid>
              {studentInfos?.companySize &&
                studentInfos?.companySize.length > 0 && (
                  <Grid item xs={2}>
                    <TableLeftText>企業規模</TableLeftText>
                  </Grid>
                )}

              {studentInfos?.companySize && (
                <Grid item xs={10}>
                  <TableText>
                    {studentInfos.companySize
                      .map((size) => companySizeData[size - 1].value)
                      .join(", ")}
                  </TableText>
                </Grid>
              )}
              {studentInfos?.careerGoal && (
                <>
                  <Grid item xs={2}>
                    <TableLeftText>求めるキャリア</TableLeftText>
                  </Grid>
                  <Grid item xs={10}>
                    <TableText>
                      裁量権があり、若いうちから成長できる環境にあること
                    </TableText>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
