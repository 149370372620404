import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { graduatedYearData } from "data/StudentProfileData";
import { useEffect } from "react";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

type StudentProfileProps = {
  studentProfileInfos: studentInfoTypes;
  searchingStudentData: studentInfoTypes[];
  isEndData: boolean;
  lastCreatedAtDataState: Timestamp | undefined;
  scrollPositionState: number;
  universitySelectState: string;
  humanitiesOrScienceState: string;
  graduateSelectState: string;
};

export default function StudentProfileCard({
  studentProfileInfos,
  searchingStudentData,
  isEndData,
  lastCreatedAtDataState,
  scrollPositionState,
  universitySelectState,
  humanitiesOrScienceState,
  graduateSelectState,
}: StudentProfileProps) {
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [iconValue, setIconValue] = React.useState("");

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/companystudentprofiledetail", {
      state: {
        iconPhtUrl: iconValue,
        senderId: studentProfileInfos.senderId,
        grade: studentProfileInfos.grade,
        university: studentProfileInfos.university,
        faculty: studentProfileInfos.faculty,
        department: studentProfileInfos.department,
        firstName: studentProfileInfos.firstName,
        lastName: studentProfileInfos.lastName,
        firstNameKana: studentProfileInfos.firstNameKana,
        lastNameKana: studentProfileInfos.lastNameKana,
        sex: studentProfileInfos.sex,
        livingArea: studentProfileInfos.livingArea,
        birthPlace: studentProfileInfos.birthPlace,
        graduatedHighSchool: studentProfileInfos.graduatedHighSchool,
        certification: studentProfileInfos.certification,
        programmingExperience: studentProfileInfos.programmingExperience,
        programmingExperienceDetail:
          studentProfileInfos.programmingExperienceDetail,
        circle: studentProfileInfos.circle,
        researchGroup: studentProfileInfos.researchGroup,
        desiredFirstBusiness: studentProfileInfos.desiredFirstBusiness,
        desiredSecondBusiness: studentProfileInfos.desiredSecondBusiness,
        desiredThirdBusiness: studentProfileInfos.desiredThirdBusiness,
        desiredFirstJob: studentProfileInfos.desiredFirstJob,
        desiredSecondJob: studentProfileInfos.desiredSecondJob,
        desiredThirdJob: studentProfileInfos.desiredThirdJob,
        choiceFirstStandard: studentProfileInfos.choiceFirstStandard,
        choiceSecondStandard: studentProfileInfos.choiceSecondStandard,
        choiceThirdStandard: studentProfileInfos.choiceThirdStandard,
        graduatedYear: studentProfileInfos.graduatedYear,
        graduatedMonth: studentProfileInfos.graduatedMonth,
        humanitiesOrScience: studentProfileInfos.humanitiesOrScience,
        internCompName: studentProfileInfos.internCompName,
        internJobType: studentProfileInfos.internJobType,
        internPeriod: studentProfileInfos.internPeriod,
        selfPRMain: studentProfileInfos.selfPRMain,
        updatedAt: studentProfileInfos.updatedAt,
        searchingStudentData: searchingStudentData,
        isEndData: isEndData,
        lastCreatedAtDataState: lastCreatedAtDataState,
        scrollPositionState: scrollPositionState,
        universitySelectState: universitySelectState,
        humanitiesOrScienceState: humanitiesOrScienceState,
        graduateSelectState: graduateSelectState,
      },
    });
  };

  useEffect(() => {
    const fetchIconUrl = async () => {
      const iconImg = await getDownLoadUrlFromFullPath(
        studentProfileInfos.iconPhtUrl
      );
      if (iconImg) setIconValue(iconImg);
    };
    fetchIconUrl();
  }, [studentProfileInfos.iconPhtUrl]);

  return (
    <Card sx={{ width: 340, borderRadius: 5, my: 5, mx: 2 }}>
      <CardActions sx={{ pb: 0 }}>
        {/* <IconButton
          aria-label="add to favorites"
          sx={{ ml: "auto" }}
          onClick={toggleFavorite}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            color={isFavorite ? "green" : "lightgray"}
            size="lg"
          />
        </IconButton> */}
      </CardActions>
      <CardActionArea onClick={handleClick}>
        <Box sx={{ px: 3, pb: 3, pt: 1 }}>
          <Box sx={{ display: "flex" }}>
            <CardMedia
              component="img"
              sx={{
                width: 140,
                height: 140,
                objectFit: "cover",
                borderRadius: 70,
              }}
              image={iconValue}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* <IconButton sx={{ ml: "auto" }} onClick={toggleFavorite}>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={isFavorite ? "green" : "lightgray"}
                  size="lg"
                />
              </IconButton> */}
              <CardContent sx={{ flex: "1 0 auto", width: "100%", pt: 0 }}>
                <Typography component="div" variant="h5" fontWeight={"bold"}>
                  {studentProfileInfos.lastName +
                    " " +
                    studentProfileInfos.firstName}
                </Typography>
                <Typography component="div">
                  {studentProfileInfos.lastNameKana +
                    studentProfileInfos.firstNameKana}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  早稲田大学
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  <Chip
                    label={
                      studentProfileInfos.humanitiesOrScience === 1
                        ? "文系"
                        : "理系"
                    }
                    color={
                      studentProfileInfos.humanitiesOrScience === 1
                        ? "error"
                        : "primary"
                    }
                  />
                  <Chip
                    label={
                      graduatedYearData[studentProfileInfos.graduatedYear - 1]
                        .value
                    }
                    color="success"
                    variant="outlined"
                  />
                </Stack>
              </CardContent>
            </Box>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            mt={1}
            px={2}
            maxWidth={400}
            overflow={"auto"}
          >
            {studentProfileInfos.programmingExperience === 1 ? (
              <Chip label={"プログラミング経験あり"} />
            ) : (
              <Box />
            )}
            {studentProfileInfos.internCompName === "" ? (
              <Box />
            ) : (
              <Chip label={"インターン経験あり"} />
            )}
          </Stack>
          <Box my={1}>
            <Divider />
          </Box>
          <Box sx={{ maxHeight: 195, overflow: "hidden" }}>
            <Typography>{studentProfileInfos.selfPRMain}</Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}
