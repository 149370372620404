import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "hooks/firebase/context/auth";
import { getCompanyInfo } from "hooks/firebase/context/companyInfo";
import { companyPrivateType } from "types/page/companyAccountType";
import { jobCategoryData } from "data/StudentProfileData";
import { listingDivisionData, prefectureData } from "data/CompanyUserData";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { useEffect, useState } from "react";
import Slideshow from "components/SlideShow";

const mdTheme = createTheme();
const StyledMuiButton = styled(Button)`
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
const StyledHeadline = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 24px !important;
  color: dimgray;
`;
const StyledSubHeadline = styled(Typography)`
  font-size: 20px !important;
  margin-left: 16px !important;
  margin-top: 8px !important;
`;
const StyledSubBoldHeadline = styled(Typography)`
  font-size: 20px !important;
  margin-left: 16px !important;
  margin-top: 8px !important;
  font-weight: bold !important;
  color: dimgray;
`;
const StyledButtonLarge = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: 20px;
  &:hover {
    background-color: rgb(75, 75, 75);
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyledButtonSmall = styled.button`
  width: 50px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: auto;
  &:hover {
    background-color: rgb(75, 75, 75);
  }
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export default function CompanyProfileDetail() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();
  const user = useUser();
  const [open, setOpen] = useState(true);
  const [companyInfo, setCompanyInfo] = useState<companyPrivateType>();
  const [defaultIconImage, setDefaultIconImage] = useState<string>();
  const [defaultProfileImage, setDefaultProfileImage] = useState<string>();
  const [defaultPr1Image, setDefaultPr1Image] = useState<string>();
  const [defaultPr2Image, setDefaultPr2Image] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (user) {
      getCompanyInfo(user).then((data) => {
        setCompanyInfo(data as companyPrivateType);
        Promise.all([
          getDownLoadUrlFromFullPath(data.iconPhtUrl),
          getDownLoadUrlFromFullPath(data.coverPhtUrl!),
          getDownLoadUrlFromFullPath(data.PRPhtUrl1!),
          getDownLoadUrlFromFullPath(data.PRPhtUrl2!),
        ]).then(async (values) => {
          const icon = values[0];
          const profile = values[1];
          const pr1 = values[2];
          const pr2 = values[3];
          if (icon) setDefaultIconImage(icon);
          if (profile) setDefaultProfileImage(profile);
          if (pr1) setDefaultPr1Image(pr1);
          if (pr2) setDefaultPr2Image(pr2);
          setIsLoading(false);
        });
      });
    }
  }, [user]);

  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  useEffect(() => {
    setIsLoading(true);
    if (!user) {
      const timer = setTimeout(() => {
        if (!user) {
          navigate("/companylogin");
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [user, navigate]);

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/companylogin");
  //   }
  // }, [user, navigate]);
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box mt={8} sx={{ backgroundColor: "white" }}>
            {defaultProfileImage ? (
              <img
                src={defaultProfileImage}
                alt={"companyProfile"}
                loading="lazy"
                style={{
                  width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
                  height: open
                    ? "calc((100vw - 240px) / 4)"
                    : "calc((100vw - 72px) / 4)",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  backgroundColor: "lightgray",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: -6,
              px: 3,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {defaultIconImage ? (
                <img
                  src={defaultIconImage}
                  alt={"companyProfile"}
                  loading="lazy"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "10px",
                    backgroundColor: "lightgray",
                  }}
                />
              )}

              <Box sx={{ maxWidth: "60%", ml: 4 }}>
                <Box sx={{ mt: 8 }}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    {companyInfo?.name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: 20, color: "gray", mr: 3, minWidth: 42 }}
                  >
                    職種
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {companyInfo?.jobCategory
                      .map((category) => jobCategoryData[category].value)
                      .join(", ")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 20, color: "gray", mr: 3 }}>
                    所在地
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {companyInfo?.prefecture !== undefined &&
                      prefectureData[companyInfo?.prefecture].value}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <StyledButtonLarge
                onClick={() => navigate("/companyeditprofiledetail")}
              >
                プロフィールを編集する
              </StyledButtonLarge>
              <StyledButtonLarge
                style={{ marginTop: 10 }}
                onClick={() => navigate("/companyappuiprofiledetail")}
              >
                学生側の見え方を確認
              </StyledButtonLarge>
              <StyledButtonSmall
                style={{ marginTop: 50 }}
                onClick={() => navigate("/companyeditprofiledetail")}
              >
                編集
              </StyledButtonSmall>
              <StyledButtonSmall
                style={{ marginTop: 10, width: "100px" }}
                onClick={() => navigate("/companyappuiprofiledetail")}
              >
                学生側画面
              </StyledButtonSmall>
            </Box>
          </Box>
          <Box sx={{ mx: 5, my: 5 }}>
            <Box sx={{ display: "flex" }}>
              <StyledMuiButton
                startIcon={
                  <FontAwesomeIcon icon={faBuilding} size="lg" color="black" />
                }
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  企業概要
                </Typography>
              </StyledMuiButton>
              <StyledMuiButton
                startIcon={
                  <FontAwesomeIcon icon={faUser} size="lg" color="black" />
                }
                onClick={() => {
                  navigate("/companyrecruittop", {
                    state: {
                      jobCategory: companyInfo?.jobCategory,
                      iconImage: defaultIconImage,
                      profileImage: defaultProfileImage,
                      name: companyInfo?.name,
                      prefecture: companyInfo?.prefecture,
                    },
                  });
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  求人情報
                </Typography>
              </StyledMuiButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{ width: "140px", height: "5px", backgroundColor: "black" }}
              />
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "lightgray",
                }}
              />
            </Box>
            {defaultPr1Image && defaultPr2Image ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <Slideshow pr1={defaultPr1Image} pr2={defaultPr2Image} />
              </Box>
            ) : defaultPr1Image && !defaultPr2Image ? (
              <Box
                sx={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 5,
                }}
              >
                <img
                  src={defaultPr1Image}
                  alt={"companyProfile"}
                  loading="lazy"
                  style={{
                    width: "calc(140vw  /2)",
                    height: "calc(140vw / 5)",
                    borderRadius: "10px",
                    border: "1px solid white",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ) : (
              !defaultPr1Image &&
              defaultPr2Image && (
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: 5,
                  }}
                >
                  <img
                    src={defaultPr2Image}
                    alt={"companyProfile"}
                    loading="lazy"
                    style={{
                      width: "calc(140vw  /2)",
                      height: "calc(140vw / 5)",
                      borderRadius: "10px",
                      border: "1px solid white",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )
            )}
            <StyledHeadline>企業アピールポイント</StyledHeadline>
            <StyledSubHeadline>
              {companyInfo?.recruitCatchcopy.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>事業内容</StyledHeadline>
            <StyledSubHeadline>
              {companyInfo?.businessContent.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>社風・風土</StyledHeadline>
            <StyledSubHeadline>
              {companyInfo?.companyCulture.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>職種カテゴリー</StyledHeadline>
            <StyledSubHeadline>
              {companyInfo?.jobCategory
                .map((category) => jobCategoryData[category].value)
                .join(", ")}
            </StyledSubHeadline>
            <StyledHeadline>基本情報</StyledHeadline>
            <Box sx={{ display: "flex" }}>
              <StyledSubBoldHeadline>本社所在地</StyledSubBoldHeadline>
              <StyledSubHeadline>
                {companyInfo?.prefecture !== undefined &&
                  prefectureData[companyInfo?.prefecture].value +
                    " " +
                    companyInfo?.addressDetail}
              </StyledSubHeadline>
            </Box>
            <Box sx={{ display: "flex", my: 1 }}>
              <StyledSubBoldHeadline>上場区分</StyledSubBoldHeadline>
              <StyledSubHeadline>
                {companyInfo?.listingDivision !== undefined &&
                  listingDivisionData[companyInfo?.listingDivision].value}
              </StyledSubHeadline>
            </Box>
            {/* <Box sx={{ display: "flex", my: 1 }}>
              <StyledSubBoldHeadline>企業ページ</StyledSubBoldHeadline>
              <StyledSubHeadline>{companyInfo?.companyURL}</StyledSubHeadline>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
