import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Top from "pages/General/Top";

import CompanyLogin from "pages/Company/Login";
import SignupCompany from "pages/General/SignupCompany";
import CompleteVerified from "pages/General/CompleteVerified";
import ResetPassword from "pages/General/ResetPassword";
import { AuthProvider } from "./hooks/firebase/context/auth";
import ConfirmResetPassword from "pages/General/ConfirmResetPassword";
import ResetEmail from "pages/General/ResetEmail";
import ConfirmResetEmail from "pages/General/ConfirmResetEmail";
import CompanyRegisterDetail from "pages/Company/RegisterDetail";
import CompleteCompanyRegister from "pages/Company/CompleteRegister";
import CompanyConfirmEmail from "pages/Company/ConfirmEmail";
import CompleteRegisterDetail from "pages/Company/CompleteRegisterDetail";
import SampleCompanyTop from "pages/Sample/Top";
import SampleCompanySearchStudent from "pages/Sample/SearchStudent";
import CompanyTop from "pages/Company/Top";
import CompanySearchStudent from "pages/Company/SearchStudent";
import CompanyStudentProfileDetail from "pages/Company/StudentProfileDetail";
import ApplicateStudentProfileDetail from "pages/Company/ApplicateStudentProfileDetail";
import CompanyProfileDetail from "pages/Company/ProfileDetail";
import CompanySearchClub from "pages/Company/SearchClub";
import StudentProfileDesiredOccupation from "pages/Company/StudentProfileDesiredOccupation";
import CompanyCompleteOffer from "pages/Company/CompleteOffer";
import CompanyRecruitDetail from "pages/Company/Recruit/Detail";
import CompanyRecruitTop from "pages/Company/Recruit/Top";
import CompanyCreateRecruit from "pages/Company/Recruit/Create";
import SampleCompanyStudentProfileDetail from "pages/Sample/StudentProfileDetail";
import SampleStudentProfileDesiredOccupation from "pages/Sample/StudentProfileDesiredOccupation";
import SampleCompanyCompleteOffer from "pages/Sample/CompleteOffer";
import CompanyChat from "pages/Chat/Company";
import StudentsSignup from "pages/Student/Signup";
import SampleCompanySearchClub from "pages/Sample/SearchClub";
import ReverseOfferProfileSetup from "pages/Sample/ReverseOfferProfileSetup";
import ReverseOfferProfileConfirm from "pages/Sample/ReverseOfferProfileConfirm";
import CompleteReverseOfferProfile from "pages/Sample/ReverseOfferProfileComplete";
import SampleCompanyOfferSelectDetail from "pages/Sample/CompanyOfferSelectDetail";
import CompanyOfferSelectDetail from "pages/Company/CompanyOfferSelectDetail";
import SampleCompanyOfferChatDetail from "pages/Sample/CompanyOfferChatDetail";
import CompanyOfferChatDetail from "pages/Company/CompanyOfferChatDetail";
import CompanyOfferConfirm from "pages/Sample/CompanyOfferConfirm";
import SampleCompanyOfferComplete from "pages/Sample/CompanyOfferComplete";
import CompanyOfferComplete from "pages/Company/CompanyOfferComplete";
import SampleAcceptedStudentOffer from "pages/Sample/AcceptedStudentOffer";
import AcceptedStudentOffer from "pages/Company/AcceptedStudentOffer";
import SampleNonAcceptedStudentOffer from "pages/Sample/NonAcceptedStudentOffer";
import NonAcceptedStudentOffer from "pages/Company/NonAcceptedStudentOffer";
import SampleApplicantRecruitStudentSample from "pages/Sample/ApplicantRecruitStudentSample";
import ApplicantRecruitStudent from "pages/Company/ApplicantRecruitStudent";
import PublicCreatedRecruit from "pages/Company/PublicCreatedRecruit";
import NonPublicCreatedRecruit from "pages/Company/NonPublicCreatedRecruit";
import EditRecruitDetail from "pages/Sample/EditRecruitDetail";
import EditRecruitDetailIntern from "pages/Sample/EditRecruitDetailIntern";
import OemTopScreen from "pages/OEM/TopScreen";
import CompanyEditProfileDetail from "pages/Company/EditProfileDetail";
import PublicCreatedRecruitSample from "pages/Sample/PublicCreatedRecruit";
import NonPublicCreatedRecruitSample from "pages/Sample/NonPublicCreatedRecruit";
import RecruitDetailContent from "pages/Company/RecruitDetailContent";
import CompanyEditRecruit from "pages/Company/Recruit/Edit";
import CompleteCompanyMainRegister from "pages/Company/CompleteMainRegister";
import OEMLogin from "pages/OEM/Login";
import ManageCoupon from "pages/OEM/ManageCoupon";
import CompanyAppUiProfileDetail from "pages/Company/CompanyAppUiProfileDetail";
import CompanyAppUiRecruitTop from "pages/Company/Recruit/AppUiTop";
import CompanyAppUiRecruitDetail from "pages/Company/Recruit/AppUiDetail";
import ApplicateStudentProfileDesiredOccupation from "pages/Company/ApplicateStudentProfileDesiredOccupation";
import ManageEvent from "pages/OEM/ManageEvent";
import OemNotification from "pages/OEM/Notification";
import { ShopRegistrationPage } from "pages/Company/ShopRegistration";
import ShopUsageView from "pages/Company/ShopUsageView";

const theme = {};

const App = () => {
  return (
    <HashRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/studentsignup" element={<StudentsSignup />} />
            <Route path="/companylogin" element={<CompanyLogin />} />
            <Route path="/signupcompany" element={<SignupCompany />} />
            <Route path="/completeverified" element={<CompleteVerified />} />
            {/* <Route
              path="/shopregistration"
              element={<ShopRegistrationPage />}
            /> */}
            <Route path="/shopusageview" element={<ShopUsageView />} />
            <Route
              path="/companyconfirmemail"
              element={<CompanyConfirmEmail />}
            />
            <Route
              path="/confirmresetpassword"
              element={<ConfirmResetPassword />}
            />
            <Route path="/confirmresetemail" element={<ConfirmResetEmail />} />
            <Route path="/resetemail" element={<ResetEmail />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route
              path="/completecompanyregister"
              element={<CompleteCompanyRegister />}
            />
            <Route
              path="/completecompanymainregister"
              element={<CompleteCompanyMainRegister />}
            />
            <Route
              path="/companyregisterdetail"
              element={<CompanyRegisterDetail />}
            />
            <Route
              path="/companyeditprofiledetail"
              element={<CompanyEditProfileDetail />}
            />
            <Route
              path="/completeregisterdetail"
              element={<CompleteRegisterDetail />}
            />
            <Route path="/sample/companytop" element={<SampleCompanyTop />} />
            <Route path="/companytop" element={<CompanyTop />} />
            <Route
              path="/companycreaterecruit"
              element={<CompanyCreateRecruit />}
            />
            <Route
              path="/company-edit-recruit"
              element={<CompanyEditRecruit />}
            />
            <Route
              path="/company-edit-recruit"
              element={<CompanyEditRecruit />}
            />
            <Route
              path="/companysearchstudent"
              element={<CompanySearchStudent />}
            />
            <Route
              path="/sample/companysearchstudent"
              element={<SampleCompanySearchStudent />}
            />
            <Route
              path="/sample/companysearchstudent"
              element={<SampleCompanySearchStudent />}
            />
            <Route
              path="/sample/companystudentprofiledetail"
              element={<SampleCompanyStudentProfileDetail />}
            />
            <Route
              path="/companystudentprofiledetail"
              element={<CompanyStudentProfileDetail />}
            />
            <Route
              path="/companyapplicatestudentprofiledetail"
              element={<ApplicateStudentProfileDetail />}
            />
            <Route
              path="/sample/studentprofiledesiredoccupation"
              element={<SampleStudentProfileDesiredOccupation />}
            />
            <Route
              path="/sample/complete-reverse-offer-profile"
              element={<CompleteReverseOfferProfile />}
            />
            <Route
              path="/studentprofiledesiredoccupation"
              element={<StudentProfileDesiredOccupation />}
            />
            <Route
              path="/applicatestudentprofiledesiredoccupation"
              element={<ApplicateStudentProfileDesiredOccupation />}
            />
            <Route path="/companysearchclub" element={<CompanySearchClub />} />
            <Route
              path="/sample/companysearchclub"
              element={<SampleCompanySearchClub />}
            />
            <Route
              path="/companyprofiledetail"
              element={<CompanyProfileDetail />}
            />
            <Route
              path="/companyappuiprofiledetail"
              element={<CompanyAppUiProfileDetail />}
            />
            <Route
              path="/companyappuirecruitdetail"
              element={<CompanyAppUiRecruitDetail />}
            />
            <Route
              path="/companyappuirecruittop"
              element={<CompanyAppUiRecruitTop />}
            />
            <Route path="/managecoupon" element={<ManageCoupon />} />
            <Route path="/manageevent" element={<ManageEvent />} />
            <Route
              path="/companyrecruitdetail"
              element={<CompanyRecruitDetail />}
            />
            <Route
              path="/sample/reverseofferprofilesetup"
              element={<ReverseOfferProfileSetup />}
            />
            <Route
              path="/sample/companycompleteoffer"
              element={<SampleCompanyCompleteOffer />}
            />
            <Route
              path="/sample/reverse-offer-profile-confirm"
              element={<ReverseOfferProfileConfirm />}
            />
            <Route
              path="/sample/company-offer-select-detail"
              element={<SampleCompanyOfferSelectDetail />}
            />
            <Route
              path="/company-offer-select-detail"
              element={<CompanyOfferSelectDetail />}
            />
            <Route
              path="/sample/company-offer-chat-detail"
              element={<SampleCompanyOfferChatDetail />}
            />
            <Route
              path="/company-offer-chat-detail"
              element={<CompanyOfferChatDetail />}
            />
            <Route
              path="/sample/company-offer-confirm"
              element={<CompanyOfferConfirm />}
            />
            <Route
              path="/sample/accepted-student-offer"
              element={<SampleAcceptedStudentOffer />}
            />
            <Route
              path="/accepted-student-offer"
              element={<AcceptedStudentOffer />}
            />
            <Route
              path="/sample/non-accepted-student-offer"
              element={<SampleNonAcceptedStudentOffer />}
            />
            <Route
              path="/non-accepted-student-offer"
              element={<NonAcceptedStudentOffer />}
            />
            <Route
              path="/sample/applicant-offer-student"
              element={<SampleApplicantRecruitStudentSample />}
            />
            <Route
              path="/applicant-offer-student"
              element={<ApplicantRecruitStudent />}
            />
            <Route
              path="/public-created-recruit"
              element={<PublicCreatedRecruit />}
            />
            <Route
              path="/sample/public-created-recruit"
              element={<PublicCreatedRecruitSample />}
            />
            <Route
              path="/edit-recruit-detail"
              element={<EditRecruitDetail />}
            />
            <Route
              path="/recruit-detail-content"
              element={<RecruitDetailContent />}
            />
            <Route
              path="/sample/edit-recruit-detail-intern"
              element={<EditRecruitDetailIntern />}
            />
            <Route
              path="/non-public-created-recruit"
              element={<NonPublicCreatedRecruit />}
            />
            <Route
              path="/sample/non-public-created-recruit"
              element={<NonPublicCreatedRecruitSample />}
            />
            <Route
              path="/sample/company-offer-complete"
              element={<SampleCompanyOfferComplete />}
            />
            <Route
              path="/company-offer-complete"
              element={<CompanyOfferComplete />}
            />
            <Route
              path="/sample/company-offer-confirm"
              element={<CompanyOfferConfirm />}
            />
            <Route
              path="/companycompleteoffer"
              element={<CompanyCompleteOffer />}
            />
            <Route path="/companyrecruittop" element={<CompanyRecruitTop />} />
            <Route path="/oemlogin" element={<OEMLogin />} />
            <Route path="/sample/oemtop" element={<OemTopScreen />} />
            <Route path="/oem/notification" element={<OemNotification />} />
            <Route path="/companychat" element={<CompanyChat />} />
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </HashRouter>
  );
};

export default App;
