import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { useUser } from "hooks/firebase/context/auth";
import {
  sendNotification,
  sendNotificationForPublic,
} from "hooks/firebase/context/oemInfo";

interface CreateNotificationModalProps {
  open: boolean;
  onClose: () => void;
  setCompleteMsg: (msg: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  universityNumber: number;
}

const CreateNotificationModal: React.FC<CreateNotificationModalProps> = ({
  open,
  onClose,
  setCompleteMsg,
  setIsLoading,
  universityNumber,
}) => {
  const [mainContent, setMainContent] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const [notificationTitle, setNotificationTitle] = useState<string>("");
  const user = useUser();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (user) {
      const uid = user.auth.uid;
      setIsLoading(true);
      const errMsg = await sendNotification(
        uid,
        universityNumber,
        mainContent,
        notificationTitle
      );
      console.log(errMsg);
      setErrMsg(errMsg);
      if (!errMsg) {
        setCompleteMsg("complete");
        setMainContent("");
        setNotificationTitle("");
        onClose();
      } else {
        setCompleteMsg(errMsg);
        onClose();
      }
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="file-input-modal-title"
      aria-describedby="file-input-modal-description"
      sx={{ zIndex: 1 }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="file-input-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
        >
          通知作成
        </Typography>
        <Typography>タイトル</Typography>
        <TextField
          margin="normal"
          fullWidth
          label="休講情報のお知らせ"
          type="text"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
          variant="outlined"
        />
        <Typography>本文</Typography>
        <TextField
          margin="normal"
          fullWidth
          label="大雪のため、明日の授業は休講です"
          type="text"
          value={mainContent}
          multiline
          rows={2}
          onChange={(e) => setMainContent(e.target.value)}
          variant="outlined"
        />
        {/* <Typography>時刻</Typography>
        <TextField
          margin="normal"
          fullWidth
          label="GoogleマップURL"
          type="text"
          value={googleUrl}
          onChange={(e) => setGoogleUrl(e.target.value)}
          variant="outlined"
        /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: "black",
            "&:hover": { backgroundColor: "dimgray" },
          }}
        >
          送信
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateNotificationModal;
