import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Backdrop, CircularProgress, Modal, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { offerType } from "types/page/offerType";
import { useEffect } from "react";
import {
  daysToRecruitData,
  EmploymentStatusData,
  graduatedYearData,
  jobCategoryData,
  offerTypeData,
  recruitedNumberData,
  SalaryFormData,
} from "data/StudentProfileData";
import { prefectureData } from "data/CompanyUserData";
import { useUser } from "hooks/firebase/context/auth";
import {
  closeOfferTemplate,
  deleteOfferTemplate,
  getIsUseForApplicationsByTemplateId,
  openOfferTemplate,
} from "hooks/firebase/context/offer";

const mdTheme = createTheme();
const StyledButton = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #4d4d4d;
  }
`;

const StyledProfileButton = styled(Button)`
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
const StyledHeadline = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 24px !important;
  color: dimgray;
`;
const StyledSubHeadline = styled(Typography)`
  font-size: 20px !important;
  margin-left: 16px !important;
  margin-top: 8px !important;
`;
const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 40px 60px 60px 60px;
  text-align: center;
`;
const StyledButtonLarge = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: 20px;
  &:hover {
    background-color: #4d4d4d;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyledButtonSmall = styled.button`
  width: 70px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: auto;
  &:hover {
    background-color: #4d4d4d;
  }
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;
export default function CompanyRecruitDetail() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();
  const user = useUser();

  const location = useLocation();
  const [offerTemplate, setOfferTemplate] = React.useState<offerType>();
  const [defaultIconImage, setDefaultIconImage] = React.useState("");
  const [defaultProfileImage, setDefaultProfileImage] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [jobCategory, setJobCategory] = React.useState<number[]>();
  const [prefecture, setPrefecture] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [publicRecruit, setPublicRecruit] = React.useState(true);
  const handleClose = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (user) {
      setOfferTemplate(location.state);
      setDefaultIconImage(location.state.iconImage);
      setDefaultProfileImage(location.state.profileImage);
      setCompanyName(location.state.name);
      setJobCategory(location.state.jobCategory);
      setPrefecture(location.state.workPlace);
      window.scrollTo(0, 0);
    }
  }, [location, user]);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  useEffect(() => {
    setIsLoading(true);
    if (!user) {
      const timer = setTimeout(() => {
        if (!user) {
          navigate("/companylogin");
        }
      }, 3500);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [user, navigate]);

  const deleteOffer = async () => {
    if (offerTemplate?.id && user) {
      const errMsg = await deleteOfferTemplate(offerTemplate?.id, user);
      if (errMsg === "") {
        navigate("/companyprofiledetail");
      } else {
        alert(errMsg);
      }
    }
  };

  useEffect(() => {
    const fetchIsUseForApplications = async () => {
      if (offerTemplate && offerTemplate.id) {
        const isOfferOpen = await getIsUseForApplicationsByTemplateId(
          offerTemplate.id
        );
        if (!isOfferOpen.errMsg) {
          setPublicRecruit(isOfferOpen.isUseForApplications);
        } else {
          console.log(isOfferOpen.errMsg);
        }
      }
    };
    fetchIsUseForApplications();
  }, [offerTemplate]);

  // useEffect(() => {
  //   const toggleOfferStatus = async () => {
  //     if (offerTemplate?.id && user) {
  //       setIsLoading(true);
  //       if (publicRecruit) {
  //         console.log("setopen");
  //         await openOfferTemplate(offerTemplate.id, user);
  //       } else {
  //         console.log("setclose");
  //         await closeOfferTemplate(offerTemplate.id, user);
  //       }
  //       setIsLoading(false);
  //     }
  //   };

  //   toggleOfferStatus();
  // }, [publicRecruit, user, offerTemplate]);

  const toggleOfferStatus = async (isPublic: boolean) => {
    if (offerTemplate?.id && user) {
      setIsLoading(true);
      setPublicRecruit(isPublic);
      if (isPublic) {
        await openOfferTemplate(offerTemplate.id, user);
      } else {
        await closeOfferTemplate(offerTemplate.id, user);
      }
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box component="main" sx={{ ...mainStyles }}>
          <Box mt={8}>
            <img
              src={defaultProfileImage}
              alt={"companyProfile"}
              loading="lazy"
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: -6,
              px: 3,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <img
                src={defaultIconImage}
                alt={"companyProfile"}
                loading="lazy"
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "10px",
                  border: "1px solid white",
                  objectFit: "cover",
                }}
              />
              <Box sx={{ ml: 4 }}>
                <Box sx={{ mt: 8 }}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    {companyName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 20, color: "gray", mr: 3 }}>
                    職種
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {jobCategory !== undefined &&
                      jobCategory
                        .map((category) => jobCategoryData[category].value)
                        .join(", ")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 20, color: "gray", mr: 3 }}>
                    所在地
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {prefecture !== undefined &&
                      prefectureData[prefecture].value}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 3 }}>
              <StyledButtonLarge
                onClick={() => navigate("/companyeditprofiledetail")}
              >
                プロフィールを編集する
              </StyledButtonLarge>
              <StyledButtonSmall
                style={{ marginTop: 50 }}
                onClick={() => navigate("/companyeditprofiledetail")}
              >
                編集
              </StyledButtonSmall>
            </Box>
          </Box>
          <Box sx={{ mx: 5, my: 5 }}>
            <Box sx={{ display: "flex" }}>
              <StyledProfileButton
                startIcon={
                  <FontAwesomeIcon icon={faBuilding} size="lg" color="black" />
                }
                onClick={() => {
                  navigate("/companyprofiledetail");
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  企業概要
                </Typography>
              </StyledProfileButton>
              <StyledProfileButton
                startIcon={
                  <FontAwesomeIcon icon={faUser} size="lg" color="black" />
                }
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  求人情報
                </Typography>
              </StyledProfileButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "lightgray",
                }}
              />
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "black",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 5,
                mb: 3,
              }}
            >
              <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                {offerTemplate?.recruitGraduatedYear !== undefined &&
                  graduatedYearData[offerTemplate?.recruitGraduatedYear].value}
                卒向け ＜
                {offerTemplate?.offerType !== undefined &&
                  offerTypeData[offerTemplate?.offerType].value}
                ＞
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ textAlign: "right", mr: 3 }}>
                  <StyledButtonLarge
                    onClick={() =>
                      navigate("/company-edit-recruit", {
                        state: {
                          trialPeriod: offerTemplate?.trialPeriod,
                          access: offerTemplate?.access,
                          appealPoint: offerTemplate?.appealPoint,
                          daysToRecruit: offerTemplate?.daysToRecruit,
                          employmentStatus: offerTemplate?.employmentStatus,
                          jobDetail: offerTemplate?.jobDetail,
                          jobName: offerTemplate?.jobName,
                          maxSalary: offerTemplate?.maxSalary,
                          minSalary: offerTemplate?.minSalary,
                          neededPersonal: offerTemplate?.neededPersonal,
                          offerType: offerTemplate?.offerType,
                          others: offerTemplate?.others,
                          recruitCatchCopy: offerTemplate?.recruitCatchCopy,
                          recruitGraduatedYear:
                            offerTemplate?.recruitGraduatedYear,
                          recruitedNumber: offerTemplate?.recruitedNumber,
                          salaryDetail: offerTemplate?.salaryDetail,
                          salaryForm: offerTemplate?.salaryForm,
                          treatment: offerTemplate?.treatment,
                          workDay: offerTemplate?.workDay,
                          workPlace: offerTemplate?.workPlace,
                          id: offerTemplate?.id,
                        },
                      })
                    }
                  >
                    求人内容を編集する
                  </StyledButtonLarge>
                  <StyledButtonSmall
                    onClick={() =>
                      navigate("/company-edit-recruit", {
                        state: {
                          trialPeriod: offerTemplate?.trialPeriod,
                          access: offerTemplate?.access,
                          appealPoint: offerTemplate?.appealPoint,
                          daysToRecruit: offerTemplate?.daysToRecruit,
                          employmentStatus: offerTemplate?.employmentStatus,
                          jobDetail: offerTemplate?.jobDetail,
                          jobName: offerTemplate?.jobName,
                          maxSalary: offerTemplate?.maxSalary,
                          minSalary: offerTemplate?.minSalary,
                          neededPersonal: offerTemplate?.neededPersonal,
                          offerType: offerTemplate?.offerType,
                          others: offerTemplate?.others,
                          recruitCatchCopy: offerTemplate?.recruitCatchCopy,
                          recruitGraduatedYear:
                            offerTemplate?.recruitGraduatedYear,
                          recruitedNumber: offerTemplate?.recruitedNumber,
                          salaryDetail: offerTemplate?.salaryDetail,
                          salaryForm: offerTemplate?.salaryForm,
                          treatment: offerTemplate?.treatment,
                          workDay: offerTemplate?.workDay,
                          workPlace: offerTemplate?.workPlace,
                          id: offerTemplate?.id,
                        },
                      })
                    }
                  >
                    編集
                  </StyledButtonSmall>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <StyledButtonLarge onClick={() => setIsModalOpen(true)}>
                    この求人を削除する
                  </StyledButtonLarge>
                  <StyledButtonSmall onClick={() => setIsModalOpen(true)}>
                    削除
                  </StyledButtonSmall>
                </Box>
              </Box>
            </Box>
            <Modal open={isModalOpen} onClose={handleClose}>
              <ModalBox>
                <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                  本当に削除しますか？
                </Typography>
                <Typography sx={{ fontSize: 16 }}>
                  この操作は取り消せません
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                  <StyledOfferButton onClick={handleClose}>
                    キャンセル
                  </StyledOfferButton>
                  <StyledOfferButton onClick={deleteOffer}>
                    削除する
                  </StyledOfferButton>
                </Box>
              </ModalBox>
            </Modal>
            <Box
              sx={{
                width: { xs: 340, sm: 322 },
                height: 40,
                borderRadius: 20,
                marginTop: 5,
                marginBottom: 5,
                backgroundColor: "#D9D9D9",
              }}
            >
              <Button
                onClick={() => toggleOfferStatus(true)}
                variant="contained"
                disableElevation
                sx={{
                  width: 160,
                  borderRadius: 20,
                  height: 40,
                  fontSize: { xs: "0.95rem", sm: "1.2rem" },
                  fontWeight: "bold",
                  color: publicRecruit ? "white" : "#4b4b4b",
                  backgroundColor: publicRecruit ? "#4b4b4b" : "#D9D9D9",
                  ":hover": {
                    backgroundColor: publicRecruit ? "#4b4b4b" : "#D9D9D9",
                  },
                }}
              >
                掲載中
              </Button>
              <Button
                onClick={() => toggleOfferStatus(false)}
                variant="contained"
                disableElevation
                sx={{
                  width: 160,
                  borderRadius: 20,
                  fontWeight: "bold",
                  color: publicRecruit ? "#4b4b4b" : "white",
                  fontSize: { xs: "0.95rem", sm: "1.2rem" },
                  height: 40,
                  backgroundColor: publicRecruit ? "#D9D9D9" : "#4b4b4b",
                  ":hover": {
                    backgroundColor: publicRecruit ? "#D9D9D9" : "#4b4b4b",
                  },
                }}
              >
                未掲載
              </Button>
            </Box>
            <StyledHeadline>職種名</StyledHeadline>
            <StyledSubHeadline>{offerTemplate?.jobName}</StyledSubHeadline>
            <StyledHeadline>求人カテゴリー</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.offerType !== undefined &&
                offerTypeData[offerTemplate?.offerType].value}
            </StyledSubHeadline>
            <StyledHeadline>採用卒業年度</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.recruitGraduatedYear !== undefined &&
                graduatedYearData[offerTemplate?.recruitGraduatedYear].value}
              年卒
            </StyledSubHeadline>
            <StyledHeadline>雇用形態</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.employmentStatus !== undefined &&
                EmploymentStatusData[offerTemplate?.employmentStatus].value}
            </StyledSubHeadline>
            <StyledHeadline>給与</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.salaryForm !== undefined &&
                SalaryFormData[offerTemplate?.salaryForm].value}
              {offerTemplate?.minSalary}円 ~ {offerTemplate?.maxSalary}円
            </StyledSubHeadline>
            {offerTemplate?.salaryDetail !== "" && (
              <Box>
                <StyledHeadline>給与詳細</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.salaryDetail
                    .split("\n")
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </StyledSubHeadline>
              </Box>
            )}

            {offerTemplate?.trialPeriod && (
              <>
                <StyledHeadline>試用期間</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.trialPeriod.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </StyledSubHeadline>
              </>
            )}
            <StyledHeadline>採用予定人数</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.recruitedNumber !== undefined &&
                recruitedNumberData[offerTemplate?.recruitedNumber].value}
            </StyledSubHeadline>
            <StyledHeadline>採用までの希望日数</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.daysToRecruit !== undefined &&
                daysToRecruitData[offerTemplate?.daysToRecruit].value}
            </StyledSubHeadline>
            <StyledHeadline>仕事内容</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.jobDetail.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            <StyledHeadline>アピールポイント</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.appealPoint.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledSubHeadline>
            {offerTemplate?.neededPersonal && (
              <>
                <StyledHeadline>求める人材</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.neededPersonal
                    .split("\n")
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </StyledSubHeadline>
              </>
            )}
            {offerTemplate?.workDay && (
              <>
                <StyledHeadline>勤務時間・曜日</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.workDay.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </StyledSubHeadline>
              </>
            )}

            <StyledHeadline>勤務地</StyledHeadline>
            <StyledSubHeadline>
              {offerTemplate?.workPlace !== undefined &&
                prefectureData[offerTemplate?.workPlace].value}
            </StyledSubHeadline>
            {offerTemplate?.access && (
              <>
                <StyledHeadline>アクセス</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.access.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </StyledSubHeadline>
              </>
            )}
            {offerTemplate?.treatment && (
              <>
                <StyledHeadline>待遇・福利厚生</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.treatment.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </StyledSubHeadline>
              </>
            )}
            {offerTemplate?.others !== "" && (
              <Box>
                <StyledHeadline>その他</StyledHeadline>
                <StyledSubHeadline>
                  {offerTemplate?.others.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </StyledSubHeadline>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
