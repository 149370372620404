import * as React from "react";
import Box from "@mui/material/Box";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { useEffect, useLayoutEffect, useState } from "react";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  closeCoupon,
  deleteCoupon,
  getCouponUsageCount,
} from "hooks/firebase/context/oemInfo";
import { useUser } from "hooks/firebase/context/auth";

type OemOpenCouponCardProps = {
  imagePath: string;
  couponid: string;
  oemUniversityNumber: string;
  setDeleteMsg: (msg: string) => void;
  setCloseCouponMsg: (msg: string) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export default function OemOpenCouponCard({
  imagePath,
  couponid,
  oemUniversityNumber,
  setDeleteMsg,
  setCloseCouponMsg,
  setIsLoading,
}: OemOpenCouponCardProps) {
  const [couponValue, setCouponValue] = useState("");
  const [usageCount, setUsageCount] = useState(0);
  const user = useUser();

  useEffect(() => {
    if (imagePath === undefined) return;
    Promise.all([getDownLoadUrlFromFullPath(imagePath)]).then(
      async (values) => {
        if (values[0] === null) return;
        setCouponValue(values[0]);
      }
    );
    fetchUsedCount();
  }, []);

  const deleteCouponFile = async () => {
    if (user && oemUniversityNumber) {
      setIsLoading(true);
      const errMsg = await deleteCoupon(
        user.auth.uid,
        Number(oemUniversityNumber),
        couponid
      );
      if (errMsg === "") {
        setDeleteMsg("complete");
        setIsLoading(false);
      } else {
        setDeleteMsg(errMsg);
        setIsLoading(false);
      }
    }
  };
  const closeCouponFile = async () => {
    setIsLoading(true);
    if (user && oemUniversityNumber) {
      const errMsg = await closeCoupon(
        user.auth.uid,
        Number(oemUniversityNumber),
        couponid
      );
      if (errMsg === "") {
        setCloseCouponMsg("complete");
        setIsLoading(false);
      } else {
        setCloseCouponMsg(errMsg);
        setIsLoading(false);
      }
    }
  };

  const fetchUsedCount = async () => {
    if (user && oemUniversityNumber) {
      const { usageCount } = await getCouponUsageCount(
        user.auth.uid,
        Number(oemUniversityNumber),
        couponid
      );
      setUsageCount(usageCount);
    }
  };

  return (
    <Box sx={{ mx: 4 }}>
      <img
        src={couponValue}
        style={{ width: "270px", height: "480px", objectFit: "cover" }}
      />
      <Box
        sx={{
          width: "270px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 6,
          alignItems: "center",
        }}
      >
        <Box>
          {usageCount !== 0 && (
            <Typography style={{ color: "gray", fontSize: 16, marginRight: 3 }}>
              使用回数: {usageCount}回
            </Typography>
          )}
        </Box>
        <Box>
          <IconButton onClick={closeCouponFile}>
            <FontAwesomeIcon
              icon={faBan}
              size="sm"
              color="gray"
              style={{ marginRight: 3 }}
            />
          </IconButton>
          <IconButton onClick={deleteCouponFile}>
            <FontAwesomeIcon icon={faTrash} size="sm" color="gray" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
