import { OemHeader } from "components/OemHeader";
import { useUser } from "hooks/firebase/context/auth";
import {
  fetchUniversitiesForUser,
  sendNotification,
} from "hooks/firebase/context/oemInfo";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from "@mui/material";
import CreateNotificationModal from "components/CreateNotificationModal";
import { oemUniversityData } from "data/OEMData";

export default function OemNotification() {
  const user = useUser();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUniversityNumber, setSelectedUniversityNumber] = useState("");
  const [completeMsg, setCompleteMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [universityNumber, setUniversityNumber] = useState<number[]>([]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  const showAlert = () => {
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 1500);
  };
  useEffect(() => {
    if (completeMsg == "complete") {
      showAlert();
      setCompleteMsg("");
    }
  }, [completeMsg]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setIsLoading(true);
        const universityData = await fetchUniversitiesForUser(user.auth.uid);
        setUniversityNumber(universityData.map(Number));
        setSelectedUniversityNumber(universityData[0]);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [user]);

  return (
    <Box>
      <OemHeader open={open} toggleDrawer={toggleDrawer} />

      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={alertOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            通知を送信しました
          </Alert>
        </Snackbar>
        <Box mt={12} ml={5}>
          <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
            通知設定
          </Typography>
          <Box
            sx={{
              width: "90%",
              height: 100,
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Button
              onClick={() => setModalOpen(true)}
              sx={{
                color: "white",
                backgroundColor: "black",
                fontWeight: "bold",
                px: 3,
                mt: 1,
                "&:hover": { backgroundColor: "dimgray" },
              }}
            >
              新しい通知の作成
            </Button>
            <Select
              value={selectedUniversityNumber}
              onChange={(event: SelectChangeEvent<string>) => {
                setSelectedUniversityNumber(String(event.target.value));
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {oemUniversityData
                .filter((university) =>
                  universityNumber.includes(Number(university.key))
                )
                .map((filteredUniversity) => (
                  <MenuItem
                    key={filteredUniversity.key}
                    value={filteredUniversity.key}
                  >
                    {filteredUniversity.value}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <CreateNotificationModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        setCompleteMsg={setCompleteMsg}
        setIsLoading={setIsLoading}
        universityNumber={Number(selectedUniversityNumber)}
      />
    </Box>
  );
}
