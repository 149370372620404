import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress,
} from "@mui/material";
import { CloudUploadOutlined, AddCircleOutline } from "@mui/icons-material";
import {
  fetchUniversitiesForUser,
  registerOemEventInfo,
} from "../../hooks/firebase/context/oemInfo";
import { useUser } from "hooks/firebase/context/auth";
import { oemUniversityData, oemUniversityNumber } from "context/OEMinfo";

type ImageAndLink = {
  image1: File | null;
  image2: File | null;
  title: string;
  link: string;
};

export default function ManageEvent() {
  const [data, setData] = useState<ImageAndLink>({
    image1: null,
    image2: null,
    title: "",
    link: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUniversityNumber, setSelectedUniversityNumber] =
    useState<string>("");
  const [universityNumber, setUniversityNumber] = useState<number[]>([]);

  const user = useUser();

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const universityData = await fetchUniversitiesForUser(user.auth.uid);
        setUniversityNumber(universityData.map(Number));
      }
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    if (universityNumber.length > 0 && selectedUniversityNumber === "") {
      setSelectedUniversityNumber(String(universityNumber[0]));
    }
  }, [selectedUniversityNumber, universityNumber]);

  const uploadData = async () => {
    if (!data.image1 || !data.image2 || !data.link) {
      alert("All fields are required.");
      return;
    }
    if (!user) return;

    setIsLoading(true);

    const uid = user?.auth.uid; // ユーザーIDを適切に設定

    // Blobに変換
    const image1Blob = await data.image1.arrayBuffer();
    const image2Blob = await data.image2.arrayBuffer();

    const errMsg = await registerOemEventInfo(
      uid,
      Number(selectedUniversityNumber),
      new Blob([image1Blob], { type: data.image1.type }),
      new Blob([image2Blob], { type: data.image2.type }),
      data.title,
      data.link,
      true
    );

    setIsLoading(false);

    if (errMsg) {
      alert(`Error: ${errMsg}`);
    } else {
      alert("Data uploaded successfully.");
      setData({ image1: null, image2: null, title: "", link: "" });
    }
  };

  return (
    <Box sx={{ margin: "20px" }}>
      <Box
        sx={{
          width: "95%",
          justifyContent: "flex-end",
          display: "flex",
          mb: 2, // マージンボトムを追加してスペーシングを改善
        }}
      >
        <Select
          value={selectedUniversityNumber}
          onChange={(event: SelectChangeEvent<string>) => {
            setSelectedUniversityNumber(String(event.target.value));
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ width: 200 }} // セレクトボックスの幅を指定
        >
          {oemUniversityData
            .filter((university) =>
              universityNumber.includes(Number(university.key))
            )
            .map((filteredUniversity) => (
              <MenuItem
                key={filteredUniversity.key}
                value={filteredUniversity.key}
              >
                {filteredUniversity.value}
              </MenuItem>
            ))}
        </Select>
      </Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        イベント掲載フォーム
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Button
          variant="outlined"
          component="label"
          startIcon={<AddCircleOutline />}
        >
          サムネイル画像
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => {
              if (e.target.files?.[0]) {
                setData({ ...data, image1: e.target.files[0] });
              }
            }}
          />
        </Button>
        {data.image1 && (
          <Box>
            <img
              src={URL.createObjectURL(data.image1)}
              alt={data.image1.name}
              style={{ width: "450px", height: "300px", borderRadius: 10 }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Button
          variant="outlined"
          component="label"
          startIcon={<AddCircleOutline />}
        >
          コンテンツ画像
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => {
              if (e.target.files?.[0]) {
                setData({ ...data, image2: e.target.files[0] });
              }
            }}
          />
        </Button>
        {data.image2 && (
          <Box>
            <img
              src={URL.createObjectURL(data.image2)}
              alt={data.image2.name}
              style={{ maxWidth: "300px", maxHeight: "600px" }}
            />
          </Box>
        )}
      </Box>
      <TextField
        label="タイトル"
        variant="outlined"
        fullWidth
        value={data.title}
        onChange={(e) => setData({ ...data, title: e.target.value })}
        sx={{ my: 2 }}
      />
      <TextField
        label="リンク"
        variant="outlined"
        fullWidth
        value={data.link}
        onChange={(e) => setData({ ...data, link: e.target.value })}
        sx={{ my: 2 }}
      />
      <Box sx={{ justifyContent: "center", display: "flex" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUploadOutlined />}
          onClick={uploadData}
          disabled={isLoading}
          sx={{
            width: 200,
            backgroundColor: "black",
            "&:hover": { backgroundColor: "black" },
          }} // ボタンをフル幅にする
        >
          {isLoading ? "アップロードしています..." : "アップロード"}
        </Button>
      </Box>
      {isLoading && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
}
