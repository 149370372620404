import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {
  Typography,
  Grid,
  CardMedia,
  Chip,
  Modal,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  graduatedMonthData,
  graduatedYearData,
  studentDepartmentData,
  studentFacultyData,
} from "data/StudentProfileData";
import {
  businessData,
  companySizeData,
  jobData,
  workStyleData,
} from "data/CompanyUserData";
import { jobStudentInfoType } from "types/page/jobStudentInfoType";
import { oemUniversityData } from "data/OEMData";
import {
  registerDocumentAccepted,
  registerDocumentRejected,
} from "hooks/firebase/context/recruit";

const mdTheme = createTheme();

const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const TableLeftText = styled(Typography)`
  font-size: 20px !important;
  color: dimgray !important;
  margin: 10px 0 !important;
  font-weight: bold !important;
  text-align: center !important;
`;
const TableText = styled(Typography)`
  font-size: 20px !important;
  font-weight: bold !important;
  margin: 10px 0 !important;
`;
export default function ApplicateStudentProfileDesiredOccupation() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [studentInfos, setStudentInfos] = useState<jobStudentInfoType>();
  const [modalOpen, setModalOpen] = useState(false);
  const [checked, setChecked] = React.useState("passed");
  const [comment, setComment] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.name);
  };
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  useEffect(() => {
    setStudentInfos(location.state);
    window.scrollTo(0, 0);
  }, [location]);

  const formRecruitResult = async () => {
    if (studentInfos?.recruitId) {
      if (checked === "passed") {
        const errMsg = await registerDocumentAccepted(studentInfos?.recruitId);
        console.log(errMsg);
        if (errMsg === "") {
          handleModalClose();
          navigate("/companychat");
        }
      } else if (checked === "rejected") {
        const errMsg = await registerDocumentRejected(
          studentInfos?.recruitId,
          comment
        );
        console.log(errMsg);
        if (errMsg === "") {
          handleModalClose();
          navigate("/applicant-offer-student");
        }
      }
    }
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box
            sx={{
              mt: 8,
              px: 5,
              backgroundColor: "#C6D6E2",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, borderRadius: 100, mt: 5 }}
                image={studentInfos?.iconPhtUrl}
              />
              <Box sx={{ ml: 10, mt: 5 }}>
                {studentInfos?.lastName ? (
                  <Box sx={{ my: 1 }}>
                    <Typography sx={{ fontSize: 38, fontWeight: "bold" }}>
                      {studentInfos?.lastName + " " + studentInfos?.firstName}
                    </Typography>
                  </Box>
                ) : (
                  <Box />
                )}

                <Box sx={{ my: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.university ? (
                      <Typography
                        sx={{ ml: 3, fontSize: 26, fontWeight: "bold" }}
                      >
                        {oemUniversityData[studentInfos?.university - 1].value}
                      </Typography>
                    ) : (
                      <Box />
                    )}

                    <Typography
                      sx={{ fontSize: 20, ml: 5, fontWeight: "bold" }}
                    >
                      {/* {
                        graduatedYearData[studentInfos?.graduatedYear]
                          .value
                      } */}
                      {studentInfos?.graduatedYear &&
                        studentInfos?.graduatedMonth &&
                        graduatedYearData[studentInfos?.graduatedYear - 1]
                          .value +
                          "年" +
                          graduatedMonthData[studentInfos?.graduatedMonth - 1]
                            .value +
                          "月"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.humanitiesOrScience &&
                    studentInfos.humanitiesOrScience === 1 ? (
                      <Chip label={"文系"} color="error" />
                    ) : (
                      <Chip label={"理系"} color="primary" />
                    )}
                    <Typography
                      sx={{ ml: 2, fontSize: 16, fontWeight: "bold" }}
                    >
                      {studentInfos?.faculty &&
                        studentFacultyData[studentInfos?.faculty - 1].value +
                          " "}
                      {studentInfos?.department
                        ? studentDepartmentData[studentInfos?.department - 1]
                            .value
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 5 }}>
              {/* <StyledSaveButton>保存する</StyledSaveButton> */}
              <StyledOfferButton onClick={handleModalOpen}>
                選考結果の入力
              </StyledOfferButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                onClick={() => {
                  navigate("/companyapplicatestudentprofiledetail", {
                    state: {
                      iconPhtUrl: studentInfos?.iconPhtUrl,
                      senderId: studentInfos?.senderId,
                      grade: studentInfos?.grade,
                      university: studentInfos?.university,
                      faculty: studentInfos?.faculty,
                      department: studentInfos?.department,
                      firstName: studentInfos?.firstName,
                      lastName: studentInfos?.lastName,
                      firstNameKana: studentInfos?.firstNameKana,
                      lastNameKana: studentInfos?.lastNameKana,
                      sex: studentInfos?.sex,
                      livingArea: studentInfos?.livingArea,
                      birthPlace: studentInfos?.birthPlace,
                      graduatedHighSchool: studentInfos?.graduatedHighSchool,
                      certification: studentInfos?.certification,
                      programmingExperience:
                        studentInfos?.programmingExperience,
                      programmingExperienceDetail:
                        studentInfos?.programmingExperienceDetail,
                      circle: studentInfos?.circle,
                      researchGroup: studentInfos?.researchGroup,
                      desiredFirstBusiness: studentInfos?.desiredFirstBusiness,
                      desiredSecondBusiness:
                        studentInfos?.desiredSecondBusiness,
                      desiredThirdBusiness: studentInfos?.desiredThirdBusiness,
                      desiredFirstJob: studentInfos?.desiredFirstJob,
                      desiredSecondJob: studentInfos?.desiredSecondJob,
                      desiredThirdJob: studentInfos?.desiredThirdJob,
                      choiceFirstStandard: studentInfos?.choiceFirstStandard,
                      choiceSecondStandard: studentInfos?.choiceSecondStandard,
                      choiceThirdStandard: studentInfos?.choiceThirdStandard,
                      graduatedYear: studentInfos?.graduatedYear,
                      graduatedMonth: studentInfos?.graduatedMonth,
                      humanitiesOrScience: studentInfos?.humanitiesOrScience,
                      internCompName: studentInfos?.internCompName,
                      internJobType: studentInfos?.internJobType,
                      internPeriod: studentInfos?.internPeriod,
                      selfPRMain: studentInfos?.selfPRMain,
                      updatedAt: studentInfos?.updatedAt,
                      recruitId: studentInfos?.recruitId,
                    },
                  });
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  プロフィール
                </Typography>
              </Button>
              <Button>
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  就活における価値観
                </Typography>
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "240px",
                  height: "5px",
                  backgroundColor: "black",
                  ml: "160px",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ mx: 5, my: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <TableLeftText>志望業界</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>
                  {studentInfos?.desiredFirstBusiness
                    ? businessData[studentInfos?.desiredFirstBusiness - 1].value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.desiredSecondBusiness
                    ? businessData[studentInfos?.desiredSecondBusiness - 1]
                        .value
                    : "未選択"}
                </TableText>
                <TableText>
                  {studentInfos?.desiredThirdBusiness
                    ? businessData[studentInfos?.desiredThirdBusiness - 1].value
                    : "未選択"}
                </TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>志望職種</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>
                  {studentInfos?.desiredFirstJob
                    ? jobData[studentInfos.desiredFirstJob - 1].value
                    : "未選択"}
                </TableText>
                <TableText>
                  {" "}
                  {studentInfos?.desiredSecondJob
                    ? jobData[studentInfos.desiredSecondJob - 1].value
                    : "未選択"}
                  aa
                </TableText>
                <TableText>
                  {" "}
                  {studentInfos?.desiredThirdJob
                    ? jobData[studentInfos.desiredThirdJob - 1].value
                    : "未選択"}
                </TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>働き方の軸</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>{workStyleData[14].value}</TableText>
              </Grid>
              {studentInfos?.companySize &&
                studentInfos?.companySize.length > 0 && (
                  <Grid item xs={2}>
                    <TableLeftText>企業規模</TableLeftText>
                  </Grid>
                )}

              {studentInfos?.companySize && (
                <Grid item xs={10}>
                  <TableText>
                    {studentInfos.companySize
                      .map((size) => companySizeData[size - 1].value)
                      .join(", ")}
                  </TableText>
                </Grid>
              )}
              {studentInfos?.careerGoal && (
                <>
                  <Grid item xs={2}>
                    <TableLeftText>求めるキャリア</TableLeftText>
                  </Grid>
                  <Grid item xs={10}>
                    <TableText>
                      裁量権があり、若いうちから成長できる環境にあること
                    </TableText>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            書類選考の結果を入力してください。
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              my: 3,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked === "passed"}
                  onChange={handleChange}
                  name="passed"
                  sx={{
                    color: "black",
                    "&.Mui-checked": { color: "#00A62E" },
                  }}
                />
              }
              label={<Typography variant="h6">選考通過</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked === "rejected"}
                  onChange={handleChange}
                  name="rejected"
                  sx={{ color: "black", "&.Mui-checked": { color: "#00A62E" } }}
                />
              }
              label={<Typography variant="h6">不採用</Typography>}
            />
          </Box>
          {checked === "rejected" && (
            <TextField
              id="outlined-multiline-static"
              label="学生へのメッセージ"
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)} // テキストエリアの値が変更されたときに状態を更新
              variant="outlined"
              sx={{ width: "100%", mt: 2 }}
            />
          )}
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ※ 選考通過後はキャンセルできません。
          </Typography>
          <Button
            onClick={handleModalClose}
            variant="outlined"
            sx={{
              mt: 2,
              mr: 2,
              width: 120,
              px: 1,
              fontWeight: "bold",
              borderColor: "gray", // 枠線の色を黒に設定
              color: "gray", // ボタンのテキスト色も黒に設定する場合
              "&:hover": {
                borderColor: "gray", // ホバー時の枠線の色も黒に保持
                backgroundColor: "transparent", // ホバー時の背景色を透明に保持
              },
            }}
            size="large"
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={formRecruitResult}
            sx={{
              mt: 2,
              width: 120,
              px: 1,
              backgroundColor: "#00A62E",
              "&:hover": { backgroundColor: "#00A62E" },
              fontWeight: "bold",
            }}
            size="large"
          >
            確定する
          </Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
