import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompanyHeader } from "components/CompanyHeader";
import { getOfferTemplatesInfos } from "hooks/firebase/context/offer";
import { useEffect, useLayoutEffect } from "react";
import { useUser } from "hooks/firebase/context/auth";
import { offerType } from "types/page/offerType";
import {
  graduatedYearData,
  jobCategoryData,
  offerTypeData,
  prefectureData,
} from "data/StudentProfileData";

const mdTheme = createTheme();
const StyledProfileButton = styled(Button)`
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
const StyledButtonLarge = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: 20px;
  &:hover {
    background-color: rgb(75, 75, 75);
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyledRecruitButton = styled(Button)`
  display: flex;
  text-align: left;
  width: 300px;
  box-shadow: 0px 5px 10px lightgray;
  padding: 15px !important;
  background-color: white !important;
  margin: 20px !important;
`;

const StyledButtonSmall = styled.button`
  width: 50px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(75, 75, 75);
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: auto;
  &:hover {
    background-color: rgb(75, 75, 75);
  }
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;
export default function CompanyRecruitTop() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const [offerTemplates, setOfferTemplates] = React.useState<offerType[]>([]);
  const [jobCategory, setJobCategory] = React.useState<number[]>();
  const [defaultIconImage, setDefaultIconImage] = React.useState<string>();
  const [defaultProfileImage, setDefaultProfileImage] =
    React.useState<string>();
  const [companyName, setCompanyName] = React.useState<string>();
  const [prefecture, setPrefecture] = React.useState<number>();

  const location = useLocation();

  useEffect(() => {
    const jobData = location.state.jobCategory;
    const iconImage = location.state.iconImage;
    const profileImage = location.state.profileImage;
    const companyName = location.state.name;
    const prefecture = location.state.prefecture;

    setDefaultIconImage(iconImage);
    setDefaultProfileImage(profileImage);
    setJobCategory(jobData);
    setCompanyName(companyName);
    setPrefecture(prefecture);
    window.scrollTo(0, 0);
  }, [location]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  const user = useUser();
  useLayoutEffect(() => {
    if (user != null) {
      Promise.all([getOfferTemplatesInfos(user)]).then(async (values) => {
        const offerTemplates = values[0][0];
        setOfferTemplates(offerTemplates);
      });
    }
  }, [user]);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box mt={8}>
            <img
              src={defaultProfileImage}
              alt={"companyProfile"}
              loading="lazy"
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: -6,
              px: 3,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <img
                src={defaultIconImage}
                alt={"companyProfile"}
                loading="lazy"
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "10px",
                  border: "1px solid white",
                  objectFit: "cover",
                }}
              />
              <Box sx={{ ml: 4 }}>
                <Box sx={{ mt: 8 }}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    {companyName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 20, color: "gray", mr: 3 }}>
                    職種
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {jobCategory !== undefined &&
                      jobCategory
                        .map((category) => jobCategoryData[category].value)
                        .join(", ")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 20, color: "gray", mr: 3 }}>
                    所在地
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {prefecture !== undefined &&
                      prefectureData[prefecture].value}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 5 }}>
              <StyledButtonLarge
                onClick={() => navigate("/companyeditprofiledetail")}
              >
                プロフィールを編集する
              </StyledButtonLarge>
              <StyledButtonSmall
                style={{ marginTop: 50 }}
                onClick={() => navigate("/companyeditprofiledetail")}
              >
                編集
              </StyledButtonSmall>
            </Box>
          </Box>
          <Box sx={{ mx: 5, my: 5 }}>
            <Box sx={{ display: "flex" }}>
              <StyledProfileButton
                startIcon={
                  <FontAwesomeIcon icon={faBuilding} size="lg" color="black" />
                }
                onClick={() => {
                  navigate("/companyprofiledetail");
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  企業概要
                </Typography>
              </StyledProfileButton>
              <StyledProfileButton
                startIcon={
                  <FontAwesomeIcon icon={faUser} size="lg" color="black" />
                }
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  求人情報
                </Typography>
              </StyledProfileButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "lightgray",
                }}
              />
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "black",
                }}
              />
            </Box>

            <Box
              sx={{
                width: "80%",
                marginLeft: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                marginTop: 3,
              }}
            >
              {offerTemplates.length === 0 ? (
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  現在募集している求人はございません
                </Typography>
              ) : (
                offerTemplates.map((offerTemplate) => {
                  return (
                    <StyledRecruitButton
                      key={offerTemplate.id}
                      sx={{
                        borderRadius: 5,
                        mt: 3,
                        position: "relative",
                      }}
                      onClick={() =>
                        navigate("/companyrecruitdetail", {
                          state: {
                            trialPeriod: offerTemplate.trialPeriod,
                            access: offerTemplate.access,
                            appealPoint: offerTemplate.appealPoint,
                            daysToRecruit: offerTemplate.daysToRecruit,
                            employmentStatus: offerTemplate.employmentStatus,
                            jobCategory: jobCategory,
                            jobDetail: offerTemplate.jobDetail,
                            jobName: offerTemplate.jobName,
                            maxSalary: offerTemplate.maxSalary,
                            minSalary: offerTemplate.minSalary,
                            neededPersonal: offerTemplate.neededPersonal,
                            offerType: offerTemplate.offerType,
                            others: offerTemplate.others,
                            recruitCatchCopy: offerTemplate.recruitCatchCopy,
                            recruitGraduatedYear:
                              offerTemplate.recruitGraduatedYear,
                            recruitedNumber: offerTemplate.recruitedNumber,
                            salaryDetail: offerTemplate.salaryDetail,
                            salaryForm: offerTemplate.salaryForm,
                            treatment: offerTemplate.treatment,
                            workDay: offerTemplate.workDay,
                            workPlace: offerTemplate.workPlace,
                            id: offerTemplate.id,
                            iconImage: defaultIconImage,
                            profileImage: defaultProfileImage,
                            name: companyName,
                            prefecture: prefecture,
                          },
                        })
                      }
                    >
                      <Box
                        sx={{
                          width: "3px",
                          height: "50px",
                          position: "absolute",
                          top: 20,
                          left: 20,
                          backgroundColor:
                            offerTemplate.offerType === 0 ? "blue" : "red",
                          mr: 2,
                        }}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "left",
                          color: "black",
                          ml: 3,
                        }}
                      >
                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                          {offerTemplate.recruitGraduatedYear !== undefined &&
                            graduatedYearData[
                              offerTemplate.recruitGraduatedYear
                            ].value}
                          年卒向け
                        </Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                          ＜
                          {offerTemplate.offerType !== undefined &&
                            offerTypeData[offerTemplate.offerType].value}
                          ＞
                        </Typography>
                        <Typography sx={{ my: 1, wordBreak: "break-word" }}>
                          {offerTemplate.appealPoint}
                        </Typography>
                        <Box sx={{ textAlign: "right" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            開く＞
                          </Typography>
                        </Box>
                      </Box>
                    </StyledRecruitButton>
                  );
                })
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
