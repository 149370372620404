import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { graduatedYearData } from "data/StudentProfileData";
import { useEffect } from "react";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { studentInfoTypesSample } from "types/page/studentInfoTypesSample";

type StudentProfileProps = {
  StudentProfileInfos: studentInfoTypesSample;
};

export default function SampleStudentProfileCard({
  StudentProfileInfos,
}: StudentProfileProps) {
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [iconValue, setIconValue] = React.useState("");

  const navigate = useNavigate();
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };
  const handleClick = () => {
    navigate("/sample/companystudentprofiledetail", {
      state: {
        iconPhtUrl: StudentProfileInfos.iconPhtUrl,
        senderId: StudentProfileInfos.senderId,
        grade: StudentProfileInfos.grade,
        university: StudentProfileInfos.university,
        faculty: StudentProfileInfos.faculty,
        department: StudentProfileInfos.department,
        firstName: StudentProfileInfos.firstName,
        lastName: StudentProfileInfos.lastName,
        firstNameKana: StudentProfileInfos.firstNameKana,
        lastNameKana: StudentProfileInfos.lastNameKana,
        sex: StudentProfileInfos.sex,
        livingArea: StudentProfileInfos.livingArea,
        birthPlace: StudentProfileInfos.birthPlace,
        graduatedHighSchool: StudentProfileInfos.graduatedHighSchool,
        certification: StudentProfileInfos.certification,
        programmingExperience: StudentProfileInfos.programmingExperience,
        programmingExperienceDetail:
          StudentProfileInfos.programmingExperienceDetail,
        circle: StudentProfileInfos.circle,
        researchGroup: StudentProfileInfos.researchGroup,
        desiredFirstBusiness: StudentProfileInfos.desiredFirstBusiness,
        desiredSecondBusiness: StudentProfileInfos.desiredSecondBusiness,
        desiredThirdBusiness: StudentProfileInfos.desiredThirdBusiness,
        desiredFirstJob: StudentProfileInfos.desiredFirstJob,
        desiredSecondJob: StudentProfileInfos.desiredSecondJob,
        desiredThirdJob: StudentProfileInfos.desiredThirdJob,
        choiceFirstStandard: StudentProfileInfos.choiceFirstStandard,
        choiceSecondStandard: StudentProfileInfos.choiceSecondStandard,
        choiceThirdStandard: StudentProfileInfos.choiceThirdStandard,
        graduatedYear: StudentProfileInfos.graduatedYear,
        graduatedMonth: StudentProfileInfos.graduatedMonth,
        humanitiesOrScience: StudentProfileInfos.humanitiesOrScience,
        internCompName: StudentProfileInfos.internCompName,
        internJobType: StudentProfileInfos.internJobType,
        internPeriod: StudentProfileInfos.internPeriod,
        selfPRMain: StudentProfileInfos.selfPRMain,
        updatedAt: StudentProfileInfos.updatedAt,
      },
    });
  };

  return (
    <Card sx={{ width: 340, borderRadius: 5, my: 5 }}>
      <CardActions sx={{ pb: 0 }}>
        <IconButton
          aria-label="add to favorites"
          sx={{ ml: "auto" }}
          onClick={toggleFavorite}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            color={isFavorite ? "green" : "lightgray"}
            size="lg"
          />
        </IconButton>
      </CardActions>
      <CardActionArea onClick={handleClick}>
        <Box sx={{ px: 3, pb: 3, pt: 1 }}>
          <Box sx={{ display: "flex" }}>
            <CardMedia
              component="img"
              sx={{
                width: 140,
                height: 140,
                objectFit: "cover",
                borderRadius: 70,
              }}
              image={StudentProfileInfos.iconPhtUrl}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* <IconButton sx={{ ml: "auto" }} onClick={toggleFavorite}>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={isFavorite ? "green" : "lightgray"}
                  size="lg"
                />
              </IconButton> */}
              <CardContent sx={{ flex: "1 0 auto", width: "100%", pt: 0 }}>
                <Typography component="div" variant="h5" fontWeight={"bold"}>
                  {StudentProfileInfos.lastName +
                    " " +
                    StudentProfileInfos.firstName}
                </Typography>
                <Typography component="div">
                  {StudentProfileInfos.lastNameKana +
                    StudentProfileInfos.firstNameKana}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  早稲田大学
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  <Chip
                    label={
                      StudentProfileInfos.humanitiesOrScience === 1
                        ? "文系"
                        : "理系"
                    }
                    color={
                      StudentProfileInfos.humanitiesOrScience === 1
                        ? "error"
                        : "primary"
                    }
                  />
                  <Chip
                    label={
                      graduatedYearData[StudentProfileInfos.graduatedYear - 1]
                        .value
                    }
                    color="success"
                    variant="outlined"
                  />
                </Stack>
              </CardContent>
            </Box>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            mt={1}
            px={2}
            maxWidth={400}
            overflow={"auto"}
          >
            {StudentProfileInfos.programmingExperience === 1 ? (
              <Chip label={"プログラミング経験あり"} />
            ) : (
              <Box />
            )}
            {StudentProfileInfos.internCompName === "" ? (
              <Box />
            ) : (
              <Chip label={"インターン経験あり"} />
            )}
          </Stack>
          <Box my={1}>
            <Divider />
          </Box>
          <Box sx={{ maxHeight: 195, overflow: "hidden" }}>
            <Typography>{StudentProfileInfos.selfPRMain}</Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}
