import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "styled-components";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ArrowForwardIos, FileDownload } from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import noimage from "../../assets/profileIcon.jpg";
import {
  fetchUniversitiesForUser,
  getAdditionalCircleList,
  getAdditionalCompanyList,
  getAdditionalJobStudentList,
  getAdditionalStudentList,
  getCircleList,
  getCompanyList,
  getJobStudentList,
  getStudentsByEmail,
  getStudentList,
  sendNotification,
  getStudentListCSV,
  getRecruitedJobStudentList,
  getAdditionalRecruitedJobStudentList,
} from "hooks/firebase/context/oemInfo";
import { useUser } from "hooks/firebase/context/auth";
import { studentSimpleInfoTypes } from "types/page/studentSimpleInfoTypes";
import {
  EmploymentStatusData,
  SalaryFormData,
  agutimesDepartmentData,
  daysToRecruitData,
  dutsDepartmentData,
  gradeData,
  graduatedMonthData,
  graduatedYearData,
  handyDepartmentData,
  kulifeStudentFucltyData,
  recruitedNumberData,
  ritsmateDepartmentData,
  sophiaDepartmentData,
  studentDepartmentData,
  studentFacultyData,
  ticDepartmentData,
} from "data/StudentProfileData";
import { clubSimpleInfoTypes } from "types/page/clubSimpleInfoTypes";
import { companyPrivateType } from "types/page/companyAccountType";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { OemHeader } from "components/OemHeader";
import { oemUniversityData } from "data/OEMData";
import { SelectChangeEvent } from "@mui/material/Select";
import { jobStudentInfoType } from "types/page/jobStudentInfoType";
import {
  jobData,
  businessData,
  prefectureData,
  companySizeData,
} from "data/CompanyUserData";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getCpaAdCount,
  getCpaAdCount2,
  getCpaAdCount3,
  getCpaAdCount4,
  getHandyAdCount1,
  getKulifeAdCount,
} from "hooks/firebase/context/adConfig";
import { oemUniversityNumber } from "context/OEMinfo";
import { recruitedJobStudentInfoType } from "types/page/recruitedJobStudentInfoType";

type Category = {
  id: number;
  name: string;
};

const categories: Category[] = [
  { id: 1, name: "学生" },
  { id: 2, name: "企業" },
  { id: 3, name: "サークル" },
  { id: 4, name: "ES登録学生" },
  { id: 5, name: "選考中の学生" },
];
const InfoValue = styled(Typography)``;

const StudentListApp = () => {
  const user = useUser();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [selectedStudent, setSelectedStudent] =
    useState<studentSimpleInfoTypes | null>(null);
  const [selectedClub, setSelectedClub] = useState<clubSimpleInfoTypes | null>(
    null
  );
  const [selectedJobStudent, setSelectedJobStudent] =
    useState<jobStudentInfoType | null>(null);
  const [oemStudent, setOemStudent] = useState<studentSimpleInfoTypes[] | null>(
    null
  );
  const [oemClub, setOemClub] = useState<clubSimpleInfoTypes[] | null>(null);
  const [oemCompany, setOemCompany] = useState<companyPrivateType[] | null>(
    null
  );
  const [companyIconPhtImage, setCompanyIconPhtImage] = useState("");
  const [companyCoverPhtImage, setCompanyCoverPhtImage] = useState("");
  const [companyPr1PhtImage, setCompanyPr1PhtImage] = useState("");
  const [companyPr2PhtImage, setCompanyPr2PhtImage] = useState("");
  const [clubIconPhtLink, setClubIconPhtLink] = useState("");
  const [clubCoverPhtLink, setClubCoverPhtLink] = useState("");
  const [studentIconPhtLink, setStudentIconPhtLink] = useState("");
  const [universityNumber, setUniversityNumber] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedUniversityNumber, setSelectedUniversityNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [oemCompanyLastVisible, setOemCompanyLastVisible] = useState<any>(null);
  const [oemStudentLastVisible, setOemStudentLastVisible] = useState<any>(null);
  const [oemClubLastVisible, setOemClubLastVisible] = useState<any>(null);
  const [oemJobStudentLastVisible, setOemJobStudentLastVisible] =
    useState<any>(null);
  const [oemJobStudent, setOemJobStudent] = useState<jobStudentInfoType[]>([]);
  const [isEndCompany, setIsEndCompany] = useState(true);
  const [isEndStudent, setIsEndStudent] = useState(true);
  const [isEndClub, setIsEndClub] = useState(true);
  const [isEndJobStudent, setIsEndJobStudent] = useState(true);
  const [isEndRecruitedJobStudent, setIsEndRecruitedJobStudent] =
    useState(true);
  const [jobStudentIconPhtLink, setJobStudentIconPhtLink] = useState("");
  const [oemStudentTotalNum, setOemStudentTotalNum] = useState(0);
  const [oemClubTotalNum, setOemClubTotalNum] = useState(0);
  const [oemCompanyTotalNum, setOemCompanyTotalNum] = useState(0);
  const [oemJobStudentTotalNum, setOemJobStudentTotalNum] = useState(0);
  const [oemRecruitedJobStudentTotalNum, setOemRecruitedJobStudentTotalNum] =
    useState(0);
  const [oemRecruitedJobStudent, setOemRecruitedJobStudent] = useState<
    recruitedJobStudentInfoType[]
  >([]);
  const [selectedRecruitedJobStudent, setSelectedRecruitedJobStudent] =
    useState<recruitedJobStudentInfoType | null>(null);
  const [
    oemRecruitedJobStudentLastVisible,
    setOemRecruitedJobStudentLastVisible,
  ] = useState<any>(null);

  const [recruitedJobStudentIconPhtLink, setRecruitedJobStudentIconPhtLink] =
    useState("");
  const [recruitedJobCompanyIconPhtLink, setRecruitedJobCompanyIconPhtLink] =
    useState("");
  const [searchQuery, setSearchQuery] = useState(""); // 検索クエリをstateで管理
  const [cpaPopupCount, setCpaPopupCount] = useState(0);
  const [cpaPopupCount2, setCpaPopupCount2] = useState(0);
  const [cpaPopupCount3, setCpaPopupCount3] = useState(0);
  const [cpaPopupCount4, setCpaPopupCount4] = useState(0);
  const [handyPopupCount1, setHandyPopupCount1] = useState(0);
  const [kulifePopupCount, setKulifePopupCount] = useState({
    sumitomoClickedBannerApplyCount: 0,
    sumitomoClickedBannerCount: 0,
    sumitomoClickedCount: 0,
    sumitomoClickedCountWeek2: 0,
    sumitomoDisplayCount: 0,
    sumitomoDisplayCountWeek2: 0,
  });

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
  };

  const handleStudentClick = (student: studentSimpleInfoTypes) => {
    setSelectedCompany(null);
    setSelectedClub(null);
    setSelectedStudent(student);
    setSelectedJobStudent(null);
    setSelectedRecruitedJobStudent(null);
  };

  const [selectedCompany, setSelectedCompany] =
    useState<companyPrivateType | null>(null);

  const handleCompanyClick = (company: companyPrivateType) => {
    setSelectedStudent(null);
    setSelectedClub(null);
    setSelectedCompany(company);
    setSelectedJobStudent(null);
    setSelectedRecruitedJobStudent(null);
  };

  const handleClubClick = (club: clubSimpleInfoTypes) => {
    setSelectedStudent(null);
    setSelectedCompany(null);
    setSelectedClub(club);
    setSelectedJobStudent(null);
    setSelectedRecruitedJobStudent(null);
  };
  const handleJobStudentClick = (jobStudent: jobStudentInfoType) => {
    setSelectedStudent(null);
    setSelectedCompany(null);
    setSelectedClub(null);
    setSelectedJobStudent(jobStudent);
    setSelectedRecruitedJobStudent(null);
  };
  const handleRecruitedJobStudentClick = (
    recruitedJobStudent: recruitedJobStudentInfoType
  ) => {
    setSelectedStudent(null);
    setSelectedCompany(null);
    setSelectedClub(null);
    setSelectedJobStudent(null);
    setSelectedRecruitedJobStudent(recruitedJobStudent);
  };

  const fetchStudentData = async () => {
    setSearchQuery("");
    if (user) {
      const students = await getStudentList(
        user?.auth.uid,
        Number(selectedUniversityNumber)
      );
      setOemStudent(students.studentList);
    }
  };

  const getNextCompanyList = async () => {
    if (user) {
      setIsLoading(true);
      const companies = await getAdditionalCompanyList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemCompanyLastVisible
      );
      if (companies.companyList.length === 10) {
        setIsEndCompany(false);
      } else {
        setIsEndCompany(true);
      }
      setOemCompany([...(oemCompany || []), ...companies.companyList]);
      setOemCompanyLastVisible(companies.lastVisible);
      setIsLoading(false);
    }
  };
  const getNextStudentList = async () => {
    if (user) {
      setIsLoading(true);
      const students = await getAdditionalStudentList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemStudentLastVisible
      );
      if (students.companyList.length === 10) {
        setIsEndStudent(false);
      } else {
        setIsEndStudent(true);
      }
      setOemStudent([...(oemStudent || []), ...students.companyList]);
      setOemStudentLastVisible(students.lastVisible);
      setIsLoading(false);
    }
  };
  const getNextClubList = async () => {
    if (user) {
      setIsLoading(true);
      const clubs = await getAdditionalCircleList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemClubLastVisible
      );
      if (clubs.circleList.length === 10) {
        setIsEndClub(false);
      } else {
        setIsEndClub(true);
      }
      setOemClub([...(oemClub || []), ...clubs.circleList]);
      setOemClubLastVisible(clubs.lastVisible);
      setIsLoading(false);
    }
  };
  const getNextJobStudentList = async () => {
    if (user) {
      setIsLoading(true);
      const students = await getAdditionalJobStudentList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemJobStudentLastVisible
      );
      if (students.companyList.length === 10) {
        setIsEndJobStudent(false);
      } else {
        setIsEndJobStudent(true);
      }
      setOemJobStudent([...(oemJobStudent || []), ...students.companyList]);
      setOemJobStudentLastVisible(students.lastVisible);
      setIsLoading(false);
    }
  };
  const getNextRecruitedJobStudentList = async () => {
    if (user) {
      setIsLoading(true);
      const students = await getAdditionalRecruitedJobStudentList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemRecruitedJobStudentLastVisible
      );
      if (students.offerList.length === 10) {
        setIsEndRecruitedJobStudent(false);
      } else {
        setIsEndRecruitedJobStudent(true);
      }
      setOemRecruitedJobStudent([
        ...(oemRecruitedJobStudent || []),
        ...students.offerList,
      ]);
      setOemRecruitedJobStudentLastVisible(students.lastVisible);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchOemRole = async () => {
      if (user) {
        const universityData = await fetchUniversitiesForUser(user.auth.uid);
        setUniversityNumber(universityData.map(Number));
      }
    };
    const fetchCpaPopupCount = async () => {
      if (user) {
        const cpaPopupCountData = await getCpaAdCount();
        const cpaPopupCountData2 = await getCpaAdCount2();
        const cpaPopupCountData3 = await getCpaAdCount3();
        const cpaPopupCountData4 = await getCpaAdCount4();
        const handyPopupCountData1 = await getHandyAdCount1();
        const kulifePopupCountData = await getKulifeAdCount();
        setCpaPopupCount(cpaPopupCountData);
        setCpaPopupCount2(cpaPopupCountData2);
        setCpaPopupCount3(cpaPopupCountData3);
        setCpaPopupCount4(cpaPopupCountData4);
        setHandyPopupCount1(handyPopupCountData1);
        setKulifePopupCount(kulifePopupCountData);
      }
    };
    fetchOemRole();
    fetchCpaPopupCount();
  }, [user]);

  useEffect(() => {
    if (universityNumber.length > 0 && selectedUniversityNumber === "") {
      setSelectedUniversityNumber(String(universityNumber[0]));
    }
    const fetchData = async () => {
      if (user) {
        let university = selectedUniversityNumber;
        if (!university) {
          const universityData = await fetchUniversitiesForUser(user.auth.uid);
          university = universityData[0];
          setUniversityNumber(universityData.map(Number));
        }

        setIsLoading(true);
        const results = await Promise.all([
          getCircleList(user?.auth.uid, Number(university)),
          getStudentList(user?.auth.uid, Number(university)),
          getCompanyList(user?.auth.uid, Number(university)),
          getJobStudentList(user?.auth.uid, Number(university)),
          getRecruitedJobStudentList(user?.auth.uid, Number(university)),
        ]);
        const circles = results[0];
        const students = results[1];
        const companies = results[2];
        const jobStudent = results[3];
        const recruitedJobStudent = results[4];

        setOemStudent(students.studentList);
        setOemClub(circles.circleList);
        setOemCompany(companies.companyList);
        setOemStudentTotalNum(students.totalCount);
        setOemClubTotalNum(circles.totalCount);
        setOemCompanyTotalNum(companies.totalCount);
        setOemJobStudentTotalNum(jobStudent.totalCount);
        setOemRecruitedJobStudentTotalNum(recruitedJobStudent.totalCount);
        setOemJobStudent(jobStudent.circleList);
        setOemRecruitedJobStudent(recruitedJobStudent.offerList);
        setOemStudentLastVisible(students.lastVisible);
        setOemClubLastVisible(circles.lastVisible);
        setOemCompanyLastVisible(companies.lastVisible);
        setOemJobStudentLastVisible(jobStudent.lastVisible);
        setOemRecruitedJobStudentLastVisible(recruitedJobStudent.lastVisible);

        if (students.studentList.length === 10) {
          setIsEndStudent(false);
        } else {
          setIsEndStudent(true);
        }
        if (circles.circleList.length === 10) {
          setIsEndClub(false);
        } else {
          setIsEndClub(true);
        }
        if (companies.companyList.length === 10) {
          setIsEndCompany(false);
        } else {
          setIsEndCompany(true);
        }
        if (jobStudent.circleList.length === 10) {
          setIsEndJobStudent(false);
        } else {
          setIsEndJobStudent(true);
        }
        if (recruitedJobStudent.offerList.length === 10) {
          setIsEndRecruitedJobStudent(false);
        } else {
          setIsEndRecruitedJobStudent(true);
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [universityNumber, selectedUniversityNumber, user]);
  const getCompanyIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyIconPhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getCompanyCoverPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyCoverPhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getCompanyPr1PhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyPr1PhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getCompanyPr2PhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyPr2PhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getClubIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setClubIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getClubCoverPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setClubCoverPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getStudentIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setStudentIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getJobStudentIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setJobStudentIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getRecruitedJobStudentIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setRecruitedJobStudentIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getRecruitedJobCompanyIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setRecruitedJobCompanyIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getStudentCSV = async () => {
    if (user) {
      const err = await getStudentListCSV(
        user?.auth.uid,
        Number(selectedUniversityNumber)
      );
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectedCompany?.iconPhtUrl) {
      getCompanyIconPhtLink(selectedCompany.iconPhtUrl).then((url) => {
        if (url) {
          setCompanyIconPhtImage(url);
        }
      });
    } else {
      setCompanyIconPhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany?.coverPhtUrl) {
      getCompanyCoverPhtLink(selectedCompany.coverPhtUrl).then((url) => {
        if (url) {
          setCompanyCoverPhtImage(url);
        }
      });
    } else {
      setCompanyCoverPhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany?.PRPhtUrl1) {
      getCompanyPr1PhtLink(selectedCompany.PRPhtUrl1).then((url) => {
        if (url) {
          setCompanyPr1PhtImage(url);
        }
      });
    } else {
      setCompanyPr1PhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany?.PRPhtUrl2) {
      getCompanyPr2PhtLink(selectedCompany.PRPhtUrl2).then((url) => {
        if (url) {
          setCompanyPr2PhtImage(url);
        }
      });
    } else {
      setCompanyPr2PhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedClub?.iconPhtUrl) {
      getClubIconPhtLink(selectedClub.iconPhtUrl).then((url) => {
        if (url) {
          setClubIconPhtLink(url);
        }
      });
    } else {
      setClubIconPhtLink("");
    }
  }, [selectedClub]);

  useEffect(() => {
    if (selectedClub?.coverPhtUrl) {
      getClubCoverPhtLink(selectedClub.coverPhtUrl).then((url) => {
        if (url) {
          setClubCoverPhtLink(url);
        }
      });
    } else {
      setClubCoverPhtLink("");
    }
  }, [selectedClub]);

  useEffect(() => {
    if (selectedStudent?.iconPhtUrl) {
      getStudentIconPhtLink(selectedStudent?.iconPhtUrl).then((url) => {
        if (url) {
          setStudentIconPhtLink(url);
        }
      });
    } else {
      setStudentIconPhtLink("");
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (selectedJobStudent?.iconPhtUrl) {
      getJobStudentIconPhtLink(selectedJobStudent?.iconPhtUrl).then((url) => {
        if (url) {
          setJobStudentIconPhtLink(url);
        }
      });
    } else {
      setJobStudentIconPhtLink("");
    }
  }, [selectedJobStudent]);

  useEffect(() => {
    if (selectedRecruitedJobStudent?.student.iconPhtUrl) {
      getRecruitedJobStudentIconPhtLink(
        selectedRecruitedJobStudent?.student.iconPhtUrl
      ).then((url) => {
        if (url) {
          setRecruitedJobStudentIconPhtLink(url);
        }
      });
    } else {
      setRecruitedJobStudentIconPhtLink("");
    }
  }, [selectedRecruitedJobStudent]);

  useEffect(() => {
    if (selectedRecruitedJobStudent?.company.iconPhtUrl) {
      getRecruitedJobCompanyIconPhtLink(
        selectedRecruitedJobStudent?.company.iconPhtUrl
      ).then((url) => {
        if (url) {
          setRecruitedJobCompanyIconPhtLink(url);
        }
      });
    } else {
      setRecruitedJobCompanyIconPhtLink("");
    }
  }, [selectedRecruitedJobStudent]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  const getFacultyData = (faculty: number) => {
    switch (selectedUniversityNumber) {
      case "1":
        return studentFacultyData[faculty - 1].value;
      case "2":
        return agutimesDepartmentData[faculty - 1].value;
      case "6":
        return sophiaDepartmentData[faculty - 1].value;
      case "7":
        return kulifeStudentFucltyData[faculty - 1].value;
      case "8":
        return handyDepartmentData[faculty - 1].value;
      case "9":
        return ritsmateDepartmentData[faculty - 1].value;
      case "10":
        return dutsDepartmentData[faculty - 1].value;
      case "14":
        return ticDepartmentData[faculty - 1].value;
      default:
        return "Unknown Faculty";
    }
  };

  const searchForMail = async (searchQuery: string) => {
    if (user) {
      const data = await getStudentsByEmail(
        user.auth.uid,
        Number(selectedUniversityNumber),
        searchQuery
      );
      setOemStudent(data.studentData);
      if (data.studentData.length === 10) {
        setIsEndStudent(false);
      } else {
        setIsEndStudent(true);
      }
      console.log(data.errMsg);
    }
  };

  return (
    <Box>
      <OemHeader open={open} toggleDrawer={toggleDrawer} />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Typography fontSize={"1.8rem"} fontWeight={"bold"} mt={12} ml={"10%"}>
          ユーザーデータベース
        </Typography>

        <Box
          sx={{
            width: "95%",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Select
            value={selectedUniversityNumber}
            onChange={(event: SelectChangeEvent<string>) => {
              setSelectedUniversityNumber(String(event.target.value));
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {oemUniversityData
              .filter((university) =>
                universityNumber.includes(Number(university.key))
              )
              .map((filteredUniversity) => (
                <MenuItem
                  key={filteredUniversity.key}
                  value={filteredUniversity.key}
                >
                  {filteredUniversity.value}
                </MenuItem>
              ))}
          </Select>
        </Box>
        <Box
          sx={{
            width: "95%",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
            marginTop: 1,
          }}
        >
          <Button
            onClick={getStudentCSV}
            variant="outlined"
            size="small"
            startIcon={<FileDownload />}
          >
            学生データをCSVに出力
          </Button>
        </Box>
        <StyledBox>
          <StyledInnerBox>
            <StyledList
              subheader={
                <StyledListSubheader
                  sx={{
                    borderRadius: "30px 0 0 0",
                  }}
                >
                  カテゴリー
                </StyledListSubheader>
              }
            >
              {categories.map((category) => (
                <ListItem
                  button
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  sx={{ pr: 0 }}
                >
                  <ListItemText primary={category.name} />
                  <ListItemIcon sx={{ marginRight: -3 }}>
                    <ArrowForwardIos fontSize={"small"} />
                  </ListItemIcon>
                </ListItem>
              ))}
            </StyledList>
            <Divider orientation="vertical" flexItem />
            <StyledList
              subheader={<StyledListSubheader>ユーザー名</StyledListSubheader>}
            >
              {selectedCategory && selectedCategory.name === "学生" && (
                <Box sx={{ width: "90%", mt: 1, ml: "5%" }}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchForMail(searchQuery);
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="メールアドレスで検索"
                      inputProps={{ "aria-label": "search" }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {searchQuery && (
                      <IconButton
                        onClick={fetchStudentData}
                        sx={{ p: "10px" }}
                        aria-label="clear"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}

                    <IconButton
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Box>
              )}
              <ListItem sx={{ pl: 0 }}>
                <ListItemText
                  primary={
                    selectedCategory && selectedCategory.name === "学生"
                      ? `学生数: ${oemStudentTotalNum}`
                      : selectedCategory && selectedCategory.name === "企業"
                      ? `企業数: ${oemCompanyTotalNum}`
                      : selectedCategory && selectedCategory.name === "サークル"
                      ? `サークル数: ${oemClubTotalNum}`
                      : selectedCategory &&
                        selectedCategory.name === "ES登録学生"
                      ? `ES登録学生数: ${oemJobStudentTotalNum}`
                      : selectedCategory &&
                        selectedCategory.name === "選考中の学生"
                      ? `選考中の学生数: ${oemRecruitedJobStudentTotalNum}`
                      : ""
                  }
                  sx={{ textAlign: "right" }}
                />
              </ListItem>
              {selectedCategory &&
                selectedCategory.name === "学生" &&
                oemStudent &&
                oemStudent.map((student, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleStudentClick(student)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText primary={student.name} />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "学生" &&
                !isEndStudent && (
                  <ListItem
                    button
                    onClick={() => getNextStudentList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              {selectedCategory &&
                selectedCategory.name === "企業" &&
                oemCompany &&
                oemCompany.map((company, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleCompanyClick(company)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText primary={company.name} />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "企業" &&
                !isEndCompany && (
                  <ListItem
                    button
                    onClick={() => getNextCompanyList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />

                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              {selectedCategory &&
                selectedCategory.name === "サークル" &&
                oemClub &&
                oemClub.map((club, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleClubClick(club)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText primary={club.name} />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "サークル" &&
                !isEndClub && (
                  <ListItem
                    button
                    onClick={() => getNextClubList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />

                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              {selectedCategory &&
                selectedCategory.name === "ES登録学生" &&
                oemJobStudent &&
                oemJobStudent.map((student, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleJobStudentClick(student)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      primary={student.lastName + student.firstName}
                    />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "ES登録学生" &&
                !isEndJobStudent && (
                  <ListItem
                    button
                    onClick={() => getNextJobStudentList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />

                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              {selectedCategory &&
                selectedCategory.name === "選考中の学生" &&
                oemRecruitedJobStudent &&
                oemRecruitedJobStudent.map((student, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleRecruitedJobStudentClick(student)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      primary={
                        student.student.lastName + student.student.firstName
                      }
                    />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "選考中の学生" &&
                !isEndRecruitedJobStudent && (
                  <ListItem
                    button
                    onClick={() => getNextRecruitedJobStudentList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />
                  </ListItem>
                )}
            </StyledList>
            <Divider orientation="vertical" flexItem />
            <StyledDetailsBox>
              <StyledHeaderBox>
                <StyledHeaderText>ユーザー情報</StyledHeaderText>
              </StyledHeaderBox>
              {selectedStudent &&
              !selectedClub &&
              !selectedJobStudent &&
              !selectedRecruitedJobStudent &&
              !selectedCompany ? (
                <Box p={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: 427,
                      overflow: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        padding: "0 32px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          marginBottom: 5,
                        }}
                      >
                        <img
                          src={
                            studentIconPhtLink ? studentIconPhtLink : noimage
                          }
                          alt={"studentIcon"}
                          loading="lazy"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                            objectFit: "cover",
                            marginBottom: "20px",
                          }}
                        />
                        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                          {selectedStudent.name}
                        </Typography>
                      </Box>
                      <InfoBox>
                        <InfoLabel sx={{ minWidth: 120 }}>
                          メールアドレス
                        </InfoLabel>
                        <InfoValue>{selectedStudent.address}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学部</InfoLabel>
                        <InfoValue>
                          {getFacultyData(selectedStudent.faculty)}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学年</InfoLabel>
                        <InfoValue>
                          {gradeData[selectedStudent.grade - 1].value}
                        </InfoValue>
                      </InfoBox>
                      {/* <InfoBox>
                        <InfoLabel>選考中の企業</InfoLabel>
                        <InfoValue>
                          0
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>受信オファー数</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox> */}
                    </Box>
                    {/* <Box
                      sx={{ flex: 1, textAlign: { md: "start", lg: "center" } }}
                    >
                      <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography>
                          {selectedStudent.updatedAt.toDate().toLocaleString()}
                        </Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: 220,
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {companyChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
              ) : !selectedStudent &&
                !selectedClub &&
                !selectedRecruitedJobStudent &&
                !selectedJobStudent &&
                selectedCompany ? (
                <Box p={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "428px",
                      overflow: "scroll",
                    }}
                  >
                    <Box sx={{ flex: 2 }}>
                      <Typography mb={2} fontWeight={"bold"}>
                        アイコン画像
                      </Typography>
                      <img
                        src={
                          companyIconPhtImage ? companyIconPhtImage : noimage
                        }
                        alt={"companyProfile"}
                        loading="lazy"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                      <Typography my={2} fontWeight={"bold"}>
                        カバー画像
                      </Typography>
                      <img
                        src={
                          companyCoverPhtImage ? companyCoverPhtImage : noimage
                        }
                        alt={"companyProfile"}
                        loading="lazy"
                        style={{
                          width: "240px",
                          height: "120px",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                      <Box mb={2}>
                        <Typography my={2} fontWeight={"bold"}>
                          PR画像1
                        </Typography>
                        <img
                          src={
                            companyPr1PhtImage ? companyPr1PhtImage : noimage
                          }
                          alt={"companyPr1"}
                          loading="lazy"
                          style={{
                            width: "240px",
                            height: "120px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                        <Typography my={2} fontWeight={"bold"}>
                          PR画像2
                        </Typography>
                        <img
                          src={
                            companyPr2PhtImage ? companyPr2PhtImage : noimage
                          }
                          alt={"companyPr2"}
                          loading="lazy"
                          style={{
                            width: "240px",
                            height: "120px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <InfoBox>
                        <InfoLabel>名前</InfoLabel>
                        <InfoValue>{selectedCompany.name}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>メールアドレス</InfoLabel>
                        <InfoValue>{selectedCompany.email}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>電話番号</InfoLabel>
                        <InfoValue>{selectedCompany.phone}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>担当者名</InfoLabel>
                        <InfoValue>{selectedCompany.pic}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>登録日</InfoLabel>
                        <InfoValue>
                          {/* {selectedCompany.createdAt.toDate().toLocaleString()} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>選考中の学生</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.recrutingStudentNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>協賛中の団体</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.SponsorshipClubNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>作成した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>掲載した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                    </Box>
                    {/* <Box
                      sx={{ flex: 1, textAlign: { sm: "start", lg: "center" } }}
                    >
                      <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography>
                        </Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: 220,
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {studentChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
              ) : !selectedStudent &&
                !selectedCompany &&
                !selectedJobStudent &&
                !selectedRecruitedJobStudent &&
                selectedClub ? (
                <Box p={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "428px",
                      overflow: "scroll",
                    }}
                  >
                    <Box sx={{ flex: 2 }}>
                      {/* <img
                      src={
                        companyIconPhtImage
                          ? companyIconPhtImage
                          : defaultProfileImage
                      }
                      alt={"companyProfile"}
                      loading="lazy"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                    <img
                      src={
                        companyCoverPhtImage
                          ? companyCoverPhtImage
                          : defaultProfileImage
                      }
                      alt={"companyProfile"}
                      loading="lazy"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        objectFit: "cover",
                        marginLeft: "20px",
                      }}
                    /> */}

                      {selectedClub.name && (
                        <InfoBox>
                          <InfoLabel>名前</InfoLabel>
                          <InfoValue>{selectedClub.name}</InfoValue>
                        </InfoBox>
                      )}
                      <Typography mb={2} fontWeight={"bold"}>
                        アイコン画像
                      </Typography>
                      <img
                        src={clubIconPhtLink ? clubIconPhtLink : noimage}
                        alt={"clubIcon"}
                        loading="lazy"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50px",
                          objectFit: "cover",
                        }}
                      />
                      <Typography my={2} fontWeight={"bold"}>
                        カバー画像
                      </Typography>
                      <img
                        src={clubCoverPhtLink ? clubCoverPhtLink : noimage}
                        alt={"clubProfile"}
                        loading="lazy"
                        style={{
                          width: "300px",
                          height: "100px",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />

                      <InfoBox>
                        <InfoLabel>メールアドレス</InfoLabel>
                        <InfoValue>{selectedClub.address}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>登録日</InfoLabel>
                        <InfoValue>
                          {selectedClub.createdAt.toDate().toLocaleString()}
                        </InfoValue>
                      </InfoBox>
                      {selectedClub.owner && (
                        <InfoBox>
                          <InfoLabel>所有者</InfoLabel>
                          <InfoValue>{selectedClub.owner}</InfoValue>
                        </InfoBox>
                      )}

                      {selectedClub.activity && (
                        <InfoBox>
                          <InfoLabel>活動内容</InfoLabel>
                          <InfoValue>{selectedClub.activity}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.people && (
                        <InfoBox>
                          <InfoLabel>人数</InfoLabel>
                          <InfoValue>{selectedClub.people}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.place && (
                        <InfoBox>
                          <InfoLabel>場所</InfoLabel>
                          <InfoValue>{selectedClub.place}</InfoValue>
                        </InfoBox>
                      )}

                      {selectedClub.twitter && (
                        <InfoBox>
                          <InfoLabel>X</InfoLabel>
                          <InfoValue>{selectedClub.twitter}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.insta && (
                        <InfoBox>
                          <InfoLabel>Instagram</InfoLabel>
                          <InfoValue>{selectedClub.insta}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.lastStatement && (
                        <InfoBox>
                          <InfoLabel>最後に一言</InfoLabel>
                          <InfoValue>{selectedClub.lastStatement}</InfoValue>
                        </InfoBox>
                      )}

                      <InfoBox>
                        <InfoLabel>選考中の学生</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.recrutingStudentNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>協賛中の団体</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.SponsorshipClubNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>作成した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>掲載した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                    </Box>
                    {/* <Box
                      sx={{ flex: 1, textAlign: { sm: "start", lg: "center" } }}
                    >
                      <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography></Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: 220,
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {studentChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
              ) : !selectedStudent &&
                !selectedCompany &&
                !selectedClub &&
                !selectedRecruitedJobStudent &&
                selectedJobStudent ? (
                <Box px={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: 427,
                      overflow: "scroll",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box textAlign={"center"}>
                        <img
                          src={
                            jobStudentIconPhtLink
                              ? jobStudentIconPhtLink
                              : noimage
                          }
                          alt={"jobStudentIcon"}
                          loading="lazy"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                            objectFit: "cover",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                        />
                        <Typography mb={2} fontSize={20} fontWeight={"bold"}>
                          {selectedJobStudent.lastName +
                            " " +
                            selectedJobStudent.firstName +
                            " (" +
                            selectedJobStudent.lastNameKana +
                            " " +
                            selectedJobStudent.firstNameKana +
                            ")"}
                        </Typography>
                      </Box>
                      <InfoBox>
                        <InfoLabel>メールアドレス</InfoLabel>
                        <InfoValue>{selectedJobStudent.mail}</InfoValue>
                      </InfoBox>

                      <InfoBox>
                        <InfoLabel>性別</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.sex === 0 ? "男性" : "女性"}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>居住地域</InfoLabel>
                        <InfoValue>
                          {
                            prefectureData[selectedJobStudent.livingArea - 1]
                              .value
                          }
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>出身地</InfoLabel>
                        <InfoValue>
                          {
                            prefectureData[selectedJobStudent.birthPlace - 1]
                              .value
                          }
                        </InfoValue>
                      </InfoBox>
                      {selectedJobStudent.phoneNumber && (
                        <InfoBox>
                          <InfoLabel>電話番号</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.phoneNumber}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.graduatedHighSchool && (
                        <InfoBox>
                          <InfoLabel>出身高校</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.graduatedHighSchool}
                          </InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>文理選択</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.humanitiesOrScience === 1
                            ? "文系"
                            : "理系"}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学年</InfoLabel>
                        <InfoValue>{selectedJobStudent.grade}年</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学部</InfoLabel>
                        <InfoValue>
                          {
                            studentFacultyData[selectedJobStudent.faculty - 1]
                              .value
                          }
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学科</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.department !== 0
                            ? studentDepartmentData[
                                selectedJobStudent.department - 1
                              ].value
                            : "未選択"}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>卒業予定年</InfoLabel>
                        <InfoValue>
                          {graduatedYearData[
                            selectedJobStudent.graduatedYear - 1
                          ].value +
                            "年" +
                            graduatedMonthData[
                              selectedJobStudent.graduatedMonth - 1
                            ].value +
                            "月"}
                        </InfoValue>
                      </InfoBox>
                      {selectedJobStudent.certification && (
                        <InfoBox>
                          <InfoLabel>資格</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.certification}
                          </InfoValue>
                        </InfoBox>
                      )}

                      <InfoBox>
                        <InfoLabel>プログラミング経験の有無</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.programmingExperience === 1
                            ? "有り"
                            : "無し"}
                        </InfoValue>
                      </InfoBox>
                      {selectedJobStudent.programmingExperience === 1 && (
                        <InfoBox>
                          <InfoLabel>プログラミング経験詳細</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.programmingExperienceDetail}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.circle && (
                        <InfoBox>
                          <InfoLabel>部活・サークル</InfoLabel>
                          <InfoValue>{selectedJobStudent.circle}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.researchGroup && (
                        <InfoBox>
                          <InfoLabel>ゼミ・研究室</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.researchGroup}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredFirstBusiness !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望業界(第一志望)</InfoLabel>
                          <InfoValue>
                            {
                              businessData[
                                selectedJobStudent.desiredFirstBusiness - 1
                              ].value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredSecondBusiness !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望業界(第二志望)</InfoLabel>
                          <InfoValue>
                            {
                              businessData[
                                selectedJobStudent.desiredSecondBusiness - 1
                              ].value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredThirdBusiness !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望業界(第三志望)</InfoLabel>
                          <InfoValue>
                            {
                              businessData[
                                selectedJobStudent.desiredThirdBusiness - 1
                              ].value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredFirstJob !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望職種(第一志望)</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.desiredFirstJob - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredSecondJob !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望職種(第二志望)</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.desiredSecondJob - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredThirdJob !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望職種(第三志望)</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.desiredThirdJob - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.internCompName && (
                        <InfoBox>
                          <InfoLabel>インターン先企業名</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.internCompName}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.internCompName && (
                        <InfoBox>
                          <InfoLabel>インターン先企業職種</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.internJobType - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.internPeriod && (
                        <InfoBox>
                          <InfoLabel>インターン期間</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.internPeriod}
                          </InfoValue>
                        </InfoBox>
                      )}

                      {selectedJobStudent.companySize.length !== 0 && (
                        <InfoBox>
                          <InfoLabel>企業規模</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.companySize
                              .map((size) => companySizeData[size - 1].value)
                              .join(", ")}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.careerGoal && (
                        <InfoBox>
                          <InfoLabel>キャリアに求めること</InfoLabel>
                          <InfoValue>{selectedJobStudent.careerGoal}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.selfPRGakuchika && (
                        <InfoBox>
                          <InfoLabel>学生時代に頑張ったこと</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.selfPRGakuchika}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.selfPRStrength && (
                        <InfoBox>
                          <InfoLabel>強み</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.selfPRStrength}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.selfPRMain && (
                        <InfoBox>
                          <InfoLabel>自己PR</InfoLabel>
                          <InfoValue>{selectedJobStudent.selfPRMain}</InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>選考中の企業</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>受信オファー数</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      {/* <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography>
                          {selectedJobStudent.updatedAt
                            .toDate()
                            .toLocaleString()}
                        </Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: "90%",
                          marginLeft: "5%",
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {companyChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box> */}
                    </Box>
                  </Box>
                </Box>
              ) : !selectedStudent &&
                !selectedCompany &&
                !selectedClub &&
                selectedRecruitedJobStudent ? (
                <Box p={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: 427,
                      overflow: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        padding: "0 32px",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginBottom: 2,
                        }}
                      >
                        選考状況
                      </Typography>
                      <InfoValue sx={{ textAlign: "center", mb: 5 }}>
                        {(() => {
                          switch (selectedRecruitedJobStudent.status) {
                            case "created":
                              return "求人に応募しました";
                            case "progress":
                              return "書類選考を通過しました";
                            case "expired":
                              return "オファーの期限が切れました";
                            case "rejected":
                              return "オファーを辞退しました";
                            case "documentRejected":
                              return "書類選考で不合格になりました";
                            case "hired":
                              return "採用されました";
                            case "notHired":
                              return "不採用となりました";
                            case "withdrawWhileProgress":
                              return "選考を辞退しました";
                            case "withdrawAfterProgress":
                              return "選考結果が採用から辞退に変更されました。";
                            default:
                              return "求人に応募しました";
                          }
                        })()}
                      </InfoValue>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginBottom: 2,
                        }}
                      >
                        学生情報
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          marginBottom: 5,
                        }}
                      >
                        <img
                          src={
                            recruitedJobStudentIconPhtLink
                              ? recruitedJobStudentIconPhtLink
                              : noimage
                          }
                          alt={"studentIcon"}
                          loading="lazy"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                            objectFit: "cover",
                            marginBottom: "20px",
                          }}
                        />
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedRecruitedJobStudent.student.lastName}
                          {selectedRecruitedJobStudent.student.firstName}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginBottom: 2,
                        }}
                      >
                        選考中の企業
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          marginBottom: 5,
                        }}
                      >
                        <img
                          src={
                            recruitedJobCompanyIconPhtLink
                              ? recruitedJobCompanyIconPhtLink
                              : noimage
                          }
                          alt={"companyIcon"}
                          loading="lazy"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                            objectFit: "cover",
                            marginBottom: "20px",
                          }}
                        />
                        <Typography sx={{ fontSize: 18 }}>
                          {selectedRecruitedJobStudent.company.name}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginBottom: 2,
                        }}
                      >
                        求人内容
                      </Typography>
                      <InfoBox>
                        <InfoLabel>職種名</InfoLabel>
                        <InfoValue>
                          ＜
                          {
                            graduatedYearData[
                              selectedRecruitedJobStudent.recruitGraduatedYear
                            ].value
                          }
                          卒向け＞
                          {selectedRecruitedJobStudent.offerType === 1
                            ? "新卒採用"
                            : "長期インターン"}{" "}
                          {selectedRecruitedJobStudent.jobName}
                        </InfoValue>
                      </InfoBox>

                      <InfoBox>
                        <InfoLabel>アピールポイント</InfoLabel>
                        <InfoValue>
                          {selectedRecruitedJobStudent.appealPoint
                            .split("\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>給料</InfoLabel>
                        <InfoValue>
                          {selectedRecruitedJobStudent.salaryForm !==
                            undefined &&
                            SalaryFormData[
                              selectedRecruitedJobStudent.salaryForm
                            ].value}
                          {selectedRecruitedJobStudent.minSalary}~
                          {selectedRecruitedJobStudent.maxSalary}円
                        </InfoValue>
                      </InfoBox>

                      {selectedRecruitedJobStudent.salaryDetail && (
                        <InfoBox>
                          <InfoLabel>給与詳細</InfoLabel>
                          <InfoValue>
                            {selectedRecruitedJobStudent.salaryDetail
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>雇用形態</InfoLabel>
                        <InfoValue>
                          {selectedRecruitedJobStudent.employmentStatus !==
                            undefined &&
                            EmploymentStatusData[
                              selectedRecruitedJobStudent.employmentStatus
                            ].value}
                        </InfoValue>
                      </InfoBox>
                      {selectedRecruitedJobStudent.trialPeriod && (
                        <InfoBox>
                          <InfoLabel>試用期間</InfoLabel>
                          <InfoValue>
                            {selectedRecruitedJobStudent.trialPeriod}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedRecruitedJobStudent.jobDetail && (
                        <InfoBox>
                          <InfoLabel>仕事内容</InfoLabel>
                          <InfoValue>
                            {selectedRecruitedJobStudent.jobDetail
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>採用予定人数</InfoLabel>
                        <InfoValue>
                          {selectedRecruitedJobStudent.recruitedNumber !==
                            undefined &&
                            recruitedNumberData[
                              selectedRecruitedJobStudent.recruitedNumber
                            ].value}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>採用までの希望日数</InfoLabel>
                        <InfoValue>
                          {selectedRecruitedJobStudent.daysToRecruit !==
                            undefined &&
                            daysToRecruitData[
                              selectedRecruitedJobStudent.daysToRecruit
                            ].value}
                        </InfoValue>
                      </InfoBox>
                      {selectedRecruitedJobStudent.workDay && (
                        <InfoBox>
                          <InfoLabel>勤務時間・曜日</InfoLabel>
                          <InfoValue>
                            {selectedRecruitedJobStudent.workDay
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>勤務地</InfoLabel>
                        <InfoValue>
                          {
                            prefectureData[
                              selectedRecruitedJobStudent.workPlace
                            ].value
                          }
                        </InfoValue>
                      </InfoBox>
                      {selectedRecruitedJobStudent.treatment && (
                        <InfoBox>
                          <InfoLabel>福利厚生</InfoLabel>
                          <InfoValue>
                            {selectedRecruitedJobStudent.treatment
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedRecruitedJobStudent.access && (
                        <InfoBox>
                          <InfoLabel>アクセス</InfoLabel>
                          <InfoValue>
                            {selectedRecruitedJobStudent.access
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedRecruitedJobStudent.others && (
                        <InfoBox>
                          <InfoLabel>その他</InfoLabel>
                          <InfoValue>
                            {selectedRecruitedJobStudent.others
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                          </InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>作成日時</InfoLabel>
                        <InfoValue>
                          {selectedRecruitedJobStudent.createdAt
                            .toDate()
                            .toLocaleString()}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>更新日時</InfoLabel>
                        <InfoValue>
                          {selectedRecruitedJobStudent.updatedAt
                            .toDate()
                            .toLocaleString()}
                        </InfoValue>
                      </InfoBox>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </StyledDetailsBox>
          </StyledInnerBox>
        </StyledBox>
        {selectedUniversityNumber === "7" && (
          <Box
            sx={{
              mx: 10,
              mt: 3,
              mb: 10,
              border: "1px solid black",
              p: 3,
              borderRadius: 8,
              backgroundColor: "#e9e9e9",
            }}
          >
            <Typography
              sx={{
                marginRight: 6,
                fontSize: 22,
                fontWeight: "bold",
                marginBottom: 1,
                textAlign: "center",
              }}
            >
              広告表示回数
            </Typography>
            <Typography
              sx={{
                marginRight: 6,
                fontSize: 18,
                fontWeight: "bold",
                marginBottom: 1,
              }}
            >
              CPA
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              ポップアップ広告表示回数
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              1週目: {cpaPopupCount}回, 2週目: {cpaPopupCount2}回,{" "}
              2週目(新画像): {cpaPopupCount3}回, 3週目: {cpaPopupCount4}回
            </Typography>
            <Typography
              sx={{
                marginRight: 6,
                fontSize: 18,
                fontWeight: "bold",
                marginBottom: 1,
                marginTop: 3,
              }}
            >
              住友工業
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              ポップアップ表示回数
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              1週目: {kulifePopupCount.sumitomoDisplayCount}回, 2週目:{" "}
              {kulifePopupCount.sumitomoDisplayCountWeek2}回
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              ポップアップクリック回数
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              1週目: {kulifePopupCount.sumitomoClickedCount}回, 2週目:{" "}
              {kulifePopupCount.sumitomoClickedCountWeek2}回
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              バナー表示回数
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              {kulifePopupCount.sumitomoClickedBannerCount}回
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              申し込みボタンクリック回数
            </Typography>
            <Typography
              sx={{
                marginRight: 6,
                fontSize: 16,
                marginBottom: 1,
              }}
            >
              {kulifePopupCount.sumitomoClickedBannerApplyCount}回
            </Typography>
          </Box>
        )}
        {selectedUniversityNumber === "8" && (
          <Box
            sx={{
              mx: 10,
              mt: 3,
              mb: 10,
              border: "1px solid black",
              p: 3,
              borderRadius: 8,
              backgroundColor: "#e9e9e9",
            }}
          >
            <Typography
              sx={{
                marginRight: 6,
                fontSize: 22,
                fontWeight: "bold",
                marginBottom: 1,
                textAlign: "center",
              }}
            >
              表示回数
            </Typography>
            <Typography
              sx={{
                marginRight: 6,
                fontSize: 18,
                fontWeight: "bold",
                marginBottom: 1,
              }}
            >
              空き教室
            </Typography>
            <Typography sx={{ marginRight: 6, fontSize: 16, marginBottom: 1 }}>
              ポップアップ表示回数: {handyPopupCount1}回
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const StyledInnerBox = styled(Box)`
  display: flex;
  height: 500px;
  width: 90%;
  margin-top: 1rem;
  border: 1px solid black;
  border-radius: 30px;
  background-color: #e9e9e9;
`;

const StyledList = styled(List)`
  width: 20%;
  max-height: 100%;
  overflow-y: scroll;
`;

const StyledDetailsBox = styled(Box)`
  width: 60%;
`;
const StyledListSubheader = styled(ListSubheader)`
  background-color: #525252 !important;
  color: white !important;
  text-align: center !important;
  font-size: 1.1rem !important;
`;
const StyledHeaderBox = styled.div`
  height: 48px;
  background-color: #525252;
  color: white;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 30px 0 0 !important;
`;

const StyledHeaderText = styled(Typography)`
  font-size: 1.1rem !important;
  color: white !important;
`;

const InfoBox = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

const InfoLabel = styled(Typography)`
  margin-right: 16px !important;
  font-weight: bold !important;
`;

export default StudentListApp;
