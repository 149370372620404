import * as React from "react";
import Box from "@mui/material/Box";
import {
  Avatar,
  Backdrop,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  graduatedYearChipData,
  studentDepartmentData,
  studentFacultyData,
} from "data/StudentProfileData";
import { useEffect } from "react";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import {
  registerOfferHired,
  registerOfferNotHired,
} from "hooks/firebase/context/offer";
import { DocumentData } from "firebase/firestore";
import {
  registerHired,
  registerNotHired,
} from "hooks/firebase/context/recruit";

type StudentProfileProps = {
  studentData: DocumentData;
  selectionData: DocumentData;
  status?: string;
};

export default function OfferedStudentProfileCardForChat({
  studentData,
  selectionData,
  status,
}: StudentProfileProps) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [iconValue, setIconValue] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [checked, setChecked] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const steps = ["オファー送信", "オファー承認", "採用"];

  const navigate = useNavigate();
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.name);
  };
  const formRecruitResult = async () => {
    if (checked === "passed" && selectionData.selectionId) {
      setIsLoading(true);
      const errMsg = await registerHired(selectionData.selectionId, comment);
      setIsLoading(false);
      console.log(errMsg);
      setModalOpen(false);
    } else {
      setIsLoading(true);
      const errMsg = await registerNotHired(selectionData.selectionId, comment);
      console.log(errMsg);
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  useEffect(() => {
    // switch (status) {
    //   case "created":
    //     messages.message = isOfferChat
    //       ? "オファーが送信されました"
    //       : "学生が求人に応募しました";
    //     break;
    //   case "progress":
    //     messages.message = isOfferChat
    //       ? "オファーが承認されました"
    //       : "学生が書類選考を通過しました";
    //     break;
    //   case "expired":
    //     messages.message = "オファーの期限が切れました";
    //     break;
    //   case "rejected":
    //     messages.message = "学生がオファーを辞退しました";
    //     break;
    //   case "documentRejected":
    //     messages.message = "書類審査での採用が見送られました";
    //     break;
    //   case "hired":
    //     messages.message = "『選考結果：採用』を登録しました";
    //     break;
    //   case "notHired":
    //     messages.message = "『選考結果：不採用』を登録しました";
    //     break;
    //   case "withdrawWhileProgress":
    //     messages.message = "『選考中の辞退』を登録しました";
    //     break;
    //   case "withdrawAfterHired":
    //     messages.message = "選考結果が採用から辞退に変更されました";
    //     break;
    //   default:
    //     break;
    // }
    switch (status) {
      case "unread":
        setActiveStep(0);
        break;
      case "read":
        setActiveStep(0);
        break;
      case "progress":
        setActiveStep(1);
        break;
      case "hired":
        setActiveStep(2);
        break;
      case "notHired":
        setActiveStep(2);
        break;
      case "documentRejected":
        setActiveStep(1);
        break;
      default:
        setActiveStep(1); // または適切なデフォルト値を設定
    }
  }, [status]);

  useEffect(() => {
    (async () => {
      const icon = await getDownLoadUrlFromFullPath(studentData.iconPhtUrl);
      if (icon) {
        setIconValue(icon);
      }
    })();
  }, [studentData.iconPhtUrl]);

  const fetchStudentInfo = () => {
    navigate("/companystudentprofiledetail", {
      state: {
        iconPhtUrl: iconValue,
        senderId: studentData.senderId,
        grade: studentData.grade,
        university: studentData.university,
        faculty: studentData.faculty,
        department: studentData.department,
        firstName: studentData.firstName,
        lastName: studentData.lastName,
        firstNameKana: studentData.firstNameKana,
        lastNameKana: studentData.lastNameKana,
        sex: studentData.sex,
        livingArea: studentData.livingArea,
        birthPlace: studentData.birthPlace,
        graduatedHighSchool: studentData.graduatedHighSchool,
        certification: studentData.certification,
        programmingExperience: studentData.programmingExperience,
        programmingExperienceDetail: studentData.programmingExperienceDetail,
        circle: studentData.circle,
        researchGroup: studentData.researchGroup,
        desiredFirstBusiness: studentData.desiredFirstBusiness,
        desiredSecondBusiness: studentData.desiredSecondBusiness,
        desiredThirdBusiness: studentData.desiredThirdBusiness,
        desiredFirstJob: studentData.desiredFirstJob,
        desiredSecondJob: studentData.desiredSecondJob,
        desiredThirdJob: studentData.desiredThirdJob,
        choiceFirstStandard: studentData.choiceFirstStandard,
        choiceSecondStandard: studentData.choiceSecondStandard,
        choiceThirdStandard: studentData.choiceThirdStandard,
        graduatedYear: studentData.graduatedYear,
        graduatedMonth: studentData.graduatedMonth,
        humanitiesOrScience: studentData.humanitiesOrScience,
        internCompName: studentData.internCompName,
        internJobType: studentData.internJobType,
        internPeriod: studentData.internPeriod,
        selfPRMain: studentData.selfPRMain,
        updatedAt: studentData.updatedAt,
      },
    });
  };

  return (
    <Card
      sx={{
        marginTop: 1,
        width: "100%",
        px: 3,
        borderRadius: 2,
        "& .MuiCardContent-root": {
          py: "12px",
        },
      }}
      elevation={0}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Avatar
            src={iconValue}
            sx={{
              width: 80,
              height: 80,
              marginRight: 1,
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent
              sx={{
                px: 1,
                pb: 0,
                maxWidth: { sm: 120, md: 200 },
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                noWrap
                component="div"
                fontSize={18}
                fontWeight={"bold"}
              >
                {studentData?.lastName} {studentData?.firstName}
              </Typography>
              <Typography
                noWrap
                variant="subtitle1"
                component="div"
                fontSize={14}
              >
                早稲田大学
              </Typography>
              <Typography
                noWrap
                variant="subtitle2"
                component="div"
                fontSize={12}
              >
                {studentData?.faculty &&
                  studentData?.faculty !== 0 &&
                  studentFacultyData[studentData?.faculty - 1].value + " "}

                {studentData?.department &&
                  studentData?.department !== 0 &&
                  studentDepartmentData[studentData?.department - 1].value}
              </Typography>
              <Box sx={{ mt: 1, display: "flex" }}>
                {studentData?.humanitiesOrScience &&
                studentData.humanitiesOrScience === 1 ? (
                  <Chip
                    label={"文系"}
                    color={"error"}
                    size="small"
                    sx={{ mr: 1, fontSize: 10 }}
                  />
                ) : (
                  <Chip
                    label={"理系"}
                    color={"primary"}
                    size="small"
                    sx={{ mr: 1, fontSize: 10 }}
                  />
                )}
                <Chip
                  label={
                    studentData?.graduatedYear !== undefined &&
                    graduatedYearChipData[studentData?.graduatedYear - 1].value
                  }
                  color={"primary"}
                  size="small"
                  variant="outlined"
                  sx={{ fontSize: 10 }}
                />
              </Box>
            </CardContent>
          </Box>
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              width: "100%",
              "& .MuiStepIcon-root.Mui-active": { color: "#00A62E" },
              "& .MuiStepIcon-root.Mui-completed": { color: "#00A62E" },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>
                  <p style={{ fontSize: 12, fontWeight: "bold" }}>{label}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          mb: 1,
        }}
      >
        <Button
          onClick={fetchStudentInfo}
          variant="contained"
          color="primary"
          size="small"
          sx={{
            backgroundColor: "rgb(75, 75, 75)",
            height: 30,
            width: 100,
            "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
          }}
        >
          学生詳細
        </Button>
        <Button
          onClick={() =>
            navigate("/recruit-detail-content", {
              state: {
                trialPeriod: selectionData.trialPeriod,
                access: selectionData.access,
                appealPoint: selectionData.appealPoint,
                daysToRecruit: selectionData.daysToRecruit,
                employmentStatus: selectionData.employmentStatus,
                jobDetail: selectionData.jobDetail,
                jobName: selectionData.jobName,
                maxSalary: selectionData.maxSalary,
                minSalary: selectionData.minSalary,
                neededPersonal: selectionData.neededPersonal,
                offerType: selectionData.offerType,
                others: selectionData.others,
                recruitCatchCopy: "",
                recruitGraduatedYear: selectionData.recruitGraduatedYear,
                recruitedNumber: selectionData.recruitedNumber,
                salaryDetail: selectionData.salaryDetail,
                salaryForm: selectionData.salaryForm,
                treatment: selectionData.treatment,
                workDay: selectionData.workDay,
                workPlace: selectionData.workPlace,
                id: selectionData.templateRef.id,
              },
            })
          }
          variant="contained"
          color="primary"
          size="small"
          sx={{
            backgroundColor: "rgb(75, 75, 75)",
            height: 30,
            width: 100,
            "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
          }}
        >
          求人詳細
        </Button>
        <Button
          onClick={handleModalOpen}
          variant="contained"
          color="primary"
          size="small"
          disabled={activeStep !== 1}
          sx={{
            backgroundColor: "rgb(75, 75, 75)",
            height: 30,
            width: 100,
            "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
          }}
        >
          採用報告
        </Button>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 6,
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            最終的な選考結果を学生に通知します
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              height: 85,
              mt: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold", width: 100, py: 1 }}>
              選考結果
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: 98,
                height: 85,
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                sx={{ m: 0 }}
                control={
                  <Checkbox
                    checked={checked === "passed"}
                    onChange={handleChange}
                    name="passed"
                    sx={{
                      color: "black",
                      "&.Mui-checked": { color: "#00A62E" },
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: 18 }}>採用</Typography>}
              />
              <FormControlLabel
                sx={{ m: 0 }}
                control={
                  <Checkbox
                    checked={checked === "rejected"}
                    onChange={handleChange}
                    name="rejected"
                    sx={{
                      color: "black",
                      "&.Mui-checked": { color: "#00A62E" },
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: 18 }}>不採用</Typography>}
              />
            </Box>
          </Box>

          <Typography
            sx={{ fontWeight: "bold", ml: 2, textAlign: "left", mt: 3 }}
          >
            学生へのメッセージ
          </Typography>
          <Typography sx={{ ml: 2, textAlign: "center", mt: 1 }}>
            学生にはメッセージの内容によってのみ結果を通知します。
          </Typography>
          <Typography sx={{ ml: 2, textAlign: "center" }}>
            必ずメッセージを送信してください。
          </Typography>
          <Typography sx={{ ml: 2, textAlign: "left", mt: 2, fontSize: 14 }}>
            ※既に別途送信されている場合は入力の必要はありません。
          </Typography>
          <Typography sx={{ ml: 2, textAlign: "left", fontSize: 14 }}>
            ※選考結果の伝達、今後の動きについて学生にメッセージを送信してください。
          </Typography>
          <TextField
            id="outlined-multiline-static"
            label="学生へのメッセージ"
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)} // テキストエリアの値が変更されたときに状態を更新
            variant="outlined"
            sx={{ width: "100%", mt: 2 }}
          />
          <Button
            onClick={handleModalClose}
            variant="outlined"
            sx={{
              mt: 2,
              mr: 2,
              width: 120,
              px: 1,
              fontWeight: "bold",
              borderColor: "gray", // 枠線の色を黒に設定
              color: "gray", // ボタンのテキスト色も黒に設定する場合
              "&:hover": {
                borderColor: "gray", // ホバー時の枠線の色も黒に保持
                backgroundColor: "transparent", // ホバー時の背景色を透明に保持
              },
            }}
            size="large"
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={formRecruitResult}
            disabled={checked === ""}
            sx={{
              mt: 2,
              width: 120,
              px: 1,
              backgroundColor: checked ? "#00A62E" : "gray",
              "&:hover": { backgroundColor: "#00A62E" },
              fontWeight: "bold",
            }}
            size="large"
          >
            送信
          </Button>
        </Box>
      </Modal>
    </Card>
  );
}
