import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import { useState } from "react";
import { Typography } from "@mui/material";
import { styled } from "styled-components";
import { useUser } from "hooks/firebase/context/auth";
import { getMyApplication } from "hooks/firebase/context/offer";
import { MyCompanyOfferType } from "types/page/myCompanyOfferType";
import ApplicateRecruitBox from "components/ApplicateRecruitBox";

const mdTheme = createTheme();
export default function ApplicantRecruitStudent() {
  const navigate = useNavigate();
  const user = useUser();

  const [open, setOpen] = useState(true);

  const [myCompanyOffers, setMyCompanyOffers] = useState<MyCompanyOfferType[]>(
    []
  );
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  React.useEffect(() => {
    if (user) {
      const fetchOffer = async () => {
        const response = await getMyApplication(user.auth.uid);
        setMyCompanyOffers(response as MyCompanyOfferType[]);
      };
      fetchOffer();
    }
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showrecruitModalProps={true}
      />
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box mt={14}>
          <Typography
            fontSize={"1.8rem"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            応募した学生
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 3,
          }}
        >
          {myCompanyOffers.map((myOffer) => (
            <ApplicateRecruitBox key={myOffer.id} myOffer={myOffer} />
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
