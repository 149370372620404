import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import serviceLogo from "assets/serviceLogo.png";
import styled from "styled-components";
import { useUser } from "hooks/firebase/context/auth";
import { updateOfferTemplateInfo } from "hooks/firebase/context/offer";
import {
  EmploymentStatusData,
  SalaryFormData,
  daysToRecruitData,
  recruitGraduatedYearData,
  offerTypeData,
  prefectureData,
  recruitedNumberData,
} from "data/StudentProfileData";
import { CompanyHeader } from "components/CompanyHeader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { offerType } from "types/page/offerType";
import DefaultValueTextField from "components/DefaultValueTextField";
import SelectFromDefaultValueList from "components/SelectFromDefaultValueList";

const mdTheme = createTheme();

const LogoImg = styled.img`
  width: 250px;
`;
const StyledButton = styled(Button)`
  color: white !important;
  background-color: #4d4d4d !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 10px 20px !important;
  &:hover {
    background-color: gray !important;
  }
`;

export default function CompanyEditRecruit() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [open, setOpen] = React.useState(true);
  const [offerTypeState, setOfferTypeState] = useState("");
  // const [jobCategoryState, setJobCategoryState] = useState("");
  const [salaryFormState, setSalaryFormState] = useState("");
  const [employmentStatusState, setEmploymentStatusState] = useState("");
  const [graduatedYearState, setGraduatedYearState] = useState("");
  const [recruitedNumberState, setRecruitedNumberState] = useState("");
  const [daysToRecruitState, setDaysToRecruitState] = useState("");
  const [workLocationState, setWorkLocationState] = useState("");

  const [offerTemplate, setOfferTemplate] = React.useState<offerType>();
  const [trialPeriod, setTrialPeriod] = React.useState("");
  const [jobName, setJobName] = React.useState("");
  const [minSalary, setMinSalary] = React.useState("");
  const [maxSalary, setMaxSalary] = React.useState("");
  const [salaryDetail, setSalaryDetail] = React.useState("");
  const [jobDetail, setJobDetail] = React.useState("");
  const [appealPoint, setAppealPoint] = React.useState("");
  const [neededPersonal, setNeededPersonal] = React.useState("");
  const [workDay, setWorkDay] = React.useState("");
  const [access, setAccess] = React.useState("");
  const [treatment, setTreatment] = React.useState("");
  const [others, setOthers] = React.useState("");

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const user = useUser();
  const location = useLocation();
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    if (user && offerTemplate) {
      if (salaryFormState === "") {
        setErrMsg("給与体系を選択してください。");
        setIsLoading(false);
        if (scrollBottomRef && scrollBottomRef.current) {
          scrollBottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
        return;
      }
      if (
        graduatedYearState === "" ||
        employmentStatusState === "" ||
        recruitedNumberState === "" ||
        daysToRecruitState === "" ||
        workLocationState === "" ||
        offerTypeState === ""
      ) {
        setErrMsg("未入力の項目があります。");
        setIsLoading(false);
        if (scrollBottomRef && scrollBottomRef.current) {
          scrollBottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
        return;
      }
      let submitErrMsg: string = "";
      setIsLoading(true);
      submitErrMsg = await updateOfferTemplateInfo(
        Number(offerTypeState),
        formRef("jobName"),
        Number(graduatedYearState),
        Number(employmentStatusState),
        formRef("minSalary"),
        formRef("maxSalary"),
        Number(salaryFormState),
        formRef("salaryDetail"),
        formRef("trialPeriod"),
        Number(recruitedNumberState),
        Number(daysToRecruitState),
        formRef("jobDetail"),
        formRef("appealPoint"),
        formRef("neededPersonal"),
        formRef("workDay"),
        Number(workLocationState),
        formRef("access"),
        formRef("treatment"),
        formRef("others"),
        user,
        offerTemplate?.id
      );
      setIsLoading(false);
      setErrMsg(submitErrMsg);
      if (submitErrMsg === "") {
        navigate("/public-created-recruit");
      }
    }
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  useEffect(() => {
    if (user) {
      setOfferTemplate(location.state);
      setOfferTypeState(location.state.offerType);
      setGraduatedYearState(location.state.recruitGraduatedYear);
      setEmploymentStatusState(location.state.employmentStatus);
      setRecruitedNumberState(location.state.recruitedNumber);
      setDaysToRecruitState(location.state.daysToRecruit);
      setSalaryFormState(location.state.salaryForm);
      setWorkLocationState(location.state.workPlace);
      setJobName(location.state.jobName);
      setMinSalary(location.state.minSalary);
      setMaxSalary(location.state.maxSalary);
      setSalaryDetail(location.state.salaryDetail);
      setTrialPeriod(location.state.trialPeriod);
      setJobDetail(location.state.jobDetail);
      setAppealPoint(location.state.appealPoint);
      setWorkDay(location.state.workDay);
      setNeededPersonal(location.state.neededPersonal);
      setAccess(location.state.access);
      setTreatment(location.state.treatment);
      setOthers(location.state.others);

      window.scrollTo(0, 0);
    }
  }, [location, user]);

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
      <Container
        component="main"
        sx={{ ...mainStyles, backgroundColor: "whitesmoke" }}
      >
        <div ref={scrollBottomRef} />
        <Box sx={{ textAlign: "center", mt: 10 }}>
          <LogoImg src={serviceLogo} />
          <Typography
            mt={1}
            mb={2}
            sx={{ fontSize: "26px", fontWeight: "bold" }}
          >
            求人情報編集
          </Typography>
        </Box>
        <Box mt={1} textAlign={"center"}>
          <Typography color="red">{errMsg}</Typography>
        </Box>

        <Box sx={{ width: "100%", textAlign: "center" }}>
          {isLoading ? <CircularProgress size={30} /> : <Box />}
        </Box>
        <Box component="form" onSubmit={handleSubmit} sx={{ pb: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              mt: 4,
              px: 8,
              py: 5,
              border: "1px solid black",
            }}
          >
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  職種名<span style={{ color: "red" }}>*</span>
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  具体的な仕事内容を入力してください。
                </Typography>
                <DefaultValueTextField
                  name={"jobName"}
                  placeholder={""}
                  required={true}
                  value={jobName}
                  onChange={(e) => setJobName(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  求人カテゴリー<span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectFromDefaultValueList
                  label={"求人カテゴリー"}
                  data={offerTypeData}
                  onChange={setOfferTypeState}
                  defaultValue={offerTypeData[Number(offerTypeState)].value}
                />
              </Box>
              {/* <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  職種カテゴリー
                </Typography>
                <SelectFromList
                  label={"職種カテゴリー"}
                  data={jobCategoryData}
                  onChange={setJobCategoryState}
                />
              </Box> */}
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  採用卒業年度<span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectFromDefaultValueList
                  label={"採用卒業年度"}
                  data={recruitGraduatedYearData}
                  onChange={setGraduatedYearState}
                  defaultValue={
                    recruitGraduatedYearData[Number(graduatedYearState)].value
                  }
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  雇用形態<span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectFromDefaultValueList
                  label={"雇用形態"}
                  data={EmploymentStatusData}
                  onChange={setEmploymentStatusState}
                  defaultValue={
                    EmploymentStatusData[Number(employmentStatusState)].value
                  }
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  給与<span style={{ color: "red" }}>*</span>
                </Typography>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Box sx={{ width: "45%" }}>
                    <Typography>最低額</Typography>
                  </Box>
                  <Box sx={{ width: "40%" }}>
                    <Typography>最高額</Typography>
                  </Box>
                  <Box>
                    <Typography>給与体系</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ width: "35%", display: "flex", alignItems: "center" }}
                  >
                    <TextField
                      inputProps={{ style: { padding: "0px" } }}
                      fullWidth
                      multiline
                      name={"minSalary"}
                      placeholder={""}
                      rows={1}
                      required={true}
                      value={minSalary}
                      onChange={(e) => setMinSalary(e.target.value)}
                    />
                    <Typography sx={{ marginTop: 1, marginLeft: 1 }}>
                      円
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%", textAlign: "center", mt: 1 }}>
                    <Typography fontSize={24}>〜</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "35%",
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      inputProps={{ style: { padding: "0px" } }}
                      fullWidth
                      multiline
                      name={"maxSalary"}
                      placeholder={""}
                      rows={1}
                      required={true}
                      value={maxSalary}
                      onChange={(e) => setMaxSalary(e.target.value)}
                    />
                    <Typography sx={{ marginTop: 1, marginLeft: 1 }}>
                      円
                    </Typography>
                  </Box>
                  <Box sx={{ width: "15%", ml: "5%" }}>
                    <SelectFromDefaultValueList
                      label={"給与"}
                      data={SalaryFormData}
                      onChange={setSalaryFormState}
                      defaultValue={
                        SalaryFormData[Number(salaryFormState)].value
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  給与詳細
                </Typography>

                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"salaryDetail"}
                  placeholder={
                    "例：&#13;&#10;【基本給】 ・月給 300,000 円〜400,000 円（経験・スキルを考慮の上、当社規定により優遇します）&#13;&#10;【年収例】 ・年収 4,500,000円 - 6,500,000円（月給、賞与、残業手当等を含む..."
                  }
                  rows={7}
                  value={salaryDetail}
                  onChange={(e) => setSalaryDetail(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  試用期間
                </Typography>

                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"trialPeriod"}
                  placeholder={"例：3ヶ月"}
                  rows={7}
                  value={trialPeriod}
                  onChange={(e) => setTrialPeriod(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  採用予定人数<span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectFromDefaultValueList
                  label={"採用予定人数"}
                  data={recruitedNumberData}
                  onChange={setRecruitedNumberState}
                  defaultValue={
                    recruitedNumberData[Number(recruitedNumberState)].value
                  }
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  採用までの希望日数<span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectFromDefaultValueList
                  label={"希望日数"}
                  data={daysToRecruitData}
                  onChange={setDaysToRecruitState}
                  defaultValue={
                    daysToRecruitData[Number(daysToRecruitState)].value
                  }
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  仕事内容<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"jobDetail"}
                  placeholder={trialPeriod}
                  rows={7}
                  required={true}
                  value={jobDetail}
                  onChange={(e) => setJobDetail(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  求人キャッチコピー(50字程度)
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  業務の内容や目的を簡潔に記載してください。(学生に見出しとして表示されます)
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"appealPoint"}
                  placeholder="例：&#13;&#10;
                  オンライン勤務も可能な、明るくアットホームな職場で一緒に働きませんか？"
                  rows={7}
                  value={appealPoint}
                  onChange={(e) => setAppealPoint(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  求める人材
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  求めるスキルや経験、資格などを特定できます。
                </Typography>

                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"neededPersonal"}
                  placeholder="例：&#13;&#10;
                  【必須条件】&#13;&#10;
                  ・Wordの文字入力&#13;&#10;
                  【歓迎するスキル】&#13;&#10;
                  ・TOEIC800点&#13;&#10;
                  ・ITパスポート&#13;&#10;
                  "
                  rows={7}
                  value={neededPersonal}
                  onChange={(e) => setNeededPersonal(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  勤務時間・曜日
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  勤務可能な曜日・実働時間・休憩時間・時間外勤務など。
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"workDay"}
                  placeholder="例：&#13;&#10;
                  （正社員・契約社員・派遣社員などの場合）&#13;&#10;
                  ・9:00~17:00（12:00~13:00 休憩、実働7時間）&#13;&#10;
                  ・残業なし&#13;&#10;
                  （アルバイト・パートなど）&#13;&#10;
                  ・シフト制（５時〜10時、10時〜17時、15時〜20時）
                  "
                  rows={7}
                  value={workDay}
                  onChange={(e) => setWorkDay(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  勤務地<span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectFromDefaultValueList
                  label={"勤務地"}
                  data={prefectureData}
                  onChange={setWorkLocationState}
                  defaultValue={prefectureData[Number(workLocationState)].value}
                />
                {/* <Typography sx={{ fontSize: "18px" }}>
                  出張・転勤などについてはここに記載ください。
                </Typography>
                <DefaultMultilineTextField
                  name={"workPlace"}
                  placeholder="例：&#13;&#10;
                  ・年3回ほどの国内出張あり。（別途手当支給）"
                  rows={7}
                /> */}
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  アクセス
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  勤務地の最寄駅などの情報を記載してください。
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"access"}
                  placeholder="例：&#13;&#10;
                  最寄駅：「渋谷駅」東口より徒歩8分。&#13;&#10;
                  その他アクセス方法：「表参道駅」A2出口より徒歩10分。「宮益坂下」バス停から徒歩3分。"
                  rows={7}
                  value={access}
                  onChange={(e) => setAccess(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  待遇・福利厚生
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  契約期間・賞与・試用期間・各種手当などを記載してください。
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"treatment"}
                  placeholder="例：&#13;&#10;
                  ・契約期間の定めなし、定年65歳
                  ・年2回賞与
                  ・交通費支給"
                  rows={7}
                  value={treatment}
                  onChange={(e) => setTreatment(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
                  その他
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  その他記載したい情報があればこちらにご記載ください。
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "0px" } }}
                  fullWidth
                  multiline
                  name={"others"}
                  placeholder="例：&#13;&#10;
                  ・面接日指定可能
                  ・リモートでの面談可能"
                  rows={7}
                  value={others}
                  onChange={(e) => setOthers(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "right", mt: 3 }}>
            <StyledButton type="submit">保存して次へ</StyledButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
